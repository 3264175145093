import { getOperatingMode } from "../../Common/Functions/getOperatingMode";
import { RouterRoles } from "../../Common/Interfaces/Navigation/RouterRoles";
import { ServerParamsDoc } from "../../Common/Interfaces/ServerParamsDoc";


export const fakeServerParamsDoc = (): ServerParamsDoc => ({
  operatingModeDoc: getOperatingMode(),
  adminCode: "no code",
  trace: [],
  routerRole: RouterRoles.generic,
});
