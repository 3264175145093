import { RNViewStyle, styleSheetCreate } from "../../../../Mapping/styleSheetCreate";


const styleObj: {
  container: RNViewStyle;
} = {
  container: {height: 30},
};

const styles = styleSheetCreate(styleObj);
export const useStyles = () => styles;
