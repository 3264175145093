import {FDescriptorDoc} from "../Common/Interfaces/BaseDocs/FDescriptorDoc";
import {isFDescriptorDoc} from "../Common/is/isFDescriptorDoc";

export const arrayContains = (
  array: FDescriptorDoc[],
  target: FDescriptorDoc | null,
): number => {
  if (Array.isArray(array)) {
    if (array.length > 0 && isFDescriptorDoc(target)) {
      const {fuid} = target;
      const index = array.findIndex(entry => {
        if (isFDescriptorDoc(entry)) {
          return entry.fuid === fuid;
        }
        return false;
      });
      if (index >= 0 && index < array.length) {
        return index;
      }
      return -1;
    }
    return -1;
  }
  return -1;
};
