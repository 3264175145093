import { FC } from 'react';
import { Text as DefaultText, View as DefaultView } from 'react-native';
import { ThemeProps } from "./ThemeProps";
import { useThemeColor } from "./useThemeColor";

export type TextProps = ThemeProps & DefaultText['props'];

export const ThemedText: FC<TextProps> = (props) => {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');

  return <DefaultText style={[{ color }, style]} {...otherProps} />;
}