import { collection } from "firebase/firestore";
import {createFBSlashPath} from "../Common/Functions/PathGeneration/arrayToFBSlashPath";
import {firestore} from "../firebase/firestore";
import {FBCollectionReference} from "../Mapping/firebaseMapping";

export const createCollectionRef = (
  collectionPath: string[],
): FBCollectionReference => {
  return collection(firestore, createFBSlashPath(collectionPath));
};
