export enum ServerMethods {
  addOrRemoveBusinessPerson = "addorremovebusinessperson",
  businessPersonAtDomain = "businessPersonAtDomain",
  businessPersonAtBusiness = "businessPersonAtBusiness",
  cancelOrder = "cancelOrder",
  createDocument = "createDocument",
  createPhoneNumber = "createPhoneNumber",
  deleteDocument = "deleteDocument",
  getOrCreateNewBusiness = "getOrCreateNewBusiness",
  getDocument = "getDocument",
  getListOfPhoneNumbers = "getListOfPhoneNumbers",
  getOrCreateBusinessRoom = "getorcreatebusinessroom",
  getOrCreateFollow = "getorcreatefollow",
  getOrCreateMyProfile = "getOrCreateMyProfile",
  getOrCreatePrivateRoom = "getorcreateprivateroom",
  getOrCreateProductByBusiness = "getOrCreateProductByBusiness",
  getOrCreateUnsubmittedOrder = "getOrCreateUnsubmittedOrder",
  initializeServer = "initialize",
  lookupBusinessByDomain = "lookupBusinessByDomain",
  lookupDisplayName = "lookupdisplayname",
  lookupEmail = "lookupEmail",
  lookupSearchCenter = "lookupsearchcenter",
  lookupUsersBusinessByRoleForABusiness = "lookupUsersBusinessByRoleForABusiness",

  lookupUsersBusinessesByRole = "lookupUsersBusinessesByRole",
  lookupUserProfileViaUID = "lookupUserProfileViaUID",
  publishProduct = "publishProduct",
  publishOrUnpublishBusiness = "publishOrUnpublishBusiness",
  registerUser = "registerUser",
  removeOrderProductFromOrder = "removeOrderProductFromOrder",
  sendMessage = "sendMessage",
  submitOrder = "submitOrder",
  updateBusinessDomain = "updatebusinessdomain",
  updateDisplayName = "updatedisplayname",
  updateLocation = "updateLocation",
  updateLocationName = "updateLocationName",
  updateModel = "updateModel",
  updateOrderLocation = "updateOrderLocation",
  updateOrderProduct = "updateOrderProduct",
  updateOrderProvider = "updateOrderProvider",
  updateOrderStatus = "updateorderstatus",
  updateOrderTime = "updateOrderTime",
  updatePhoneNumber = "updatePhoneNumber",
  changeListOrder = "changeListOrder",
  getOrCreateRating = "getOrCreateRating",
  rate = "rate",
  listRatings = "listRatings",
  getOrCreateAlbum = "getorcreatealbum",
  getUsersBusinessesByRole = "getusersbusinessesbyrole",
  updateSeries = "updateseries",
  addOrderProductToOrder = "addaproducttoorder",
  createStripePaymentIntent = "createstripepaymentintent",
  createStripeBusinessAccount = "createstripebusinessaccount",
  updateStripePaymentIntent = "updatestripepaymentintent",
  updateOrderInvoice = "updateorderinvoice",
  updateInvoiceTip = "updateinvoicetip",
  refreshStripeBusinessAccount = "refreshstripebusinessaccount",
  testStripeChargeEmail = "teststripechargeemail",
  updateBusinessName = "updatebusinessname",
  lookupDefaultBusiness = "lookupdefaultbusiness",
  updatePhoneNumberTextEnabled = "updatetextenabled",
  updateBusinessDescription = "updatebusinessdescription",
  getListOfProducts = "getproducts",
  updateProductFields = "updateproductfields",
  updateProfileName = "updateprofilename",
  updateProductDescription = "updateproductdescription",
  updateProfileDescription = "updateprofiledescription",
  updateDescription = "updatedescription",
  createNewProduct = "createnewproduct",
  getOrCreatePrivateBusinessInfo = "getorcreateprivatebusinessinfo",
  updatePrivateBusinessInfo = "updateprivatebusinessinfo",
  updateBusinessPaymentOptions = "updatebusinesspaymentoptions",
  updateSelectedBusinessPerson = "updateselectedbusinessperson",
  getSelectedBusinessPerson = "getselectedbusinessperson",
  clearSelectedBusinessPerson = "clearselectedbusinessperson",
  getContentDoc = "getcontentdoc",
  saveContent = "savecontent",
  lookupPhoneNumber = "lookupphonenumber",
  updateProfilePaymentOptions = "updateprofilepaymentoptions",
  updateProfileVerified = "updateprofileverified",
  saveDriversLicense = "savedriverslicense",
  lookupDriversLicense = "lookupdriverslicense",
  createOrUpdateMyRating = "createOrUpdateMyRating",
  getMyRating = "getMyRating",
  getRatingTally = "getRatingTally",
  lookupProviderInfo = "lookupproviderinfo",
  updateProviderInfo = "updateProviderInfo",
  lookupTaxForm = "lookuptaxform",
  saveTaxForm = "savetaxform",
  createProviderDistribution = "createproviderdistribution",
  sendVenmoRequest = "sendVenmoRequest",
  markAsFullfilled = "markAsFullfilled",
  createIndexReport = "createIndexReport",
  createAxiosReport = "createAxiosReport",
  createLoginReport = "createLoginReport",
  updateProfileAvatar = "updateprofileavatar",
  updateBusinessLogo = "updatebusinesslogo",
  reportClientAppError = "reportClientAppError",
  reportClientStripeError = "reportClientStripeError",
  updateOrderRating = "updateOrderRating",
  reportClientLinkError = "reportClientLinkError",
  createStripeCustomer = "createStripeCustomer",
  googlePlaces = "googlePlaces",
  asterik = "*",
}
