import {AuthDoc} from "../Common/Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import {UpdateLocationNameRequestDoc} from "../Common/Interfaces/ServerDocs/Location/UpdateLocationNameRequestDoc";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import {fbClientActions} from "../FBClientActions/fbClientActions";
import {FBCancelTokenSource} from "../Mapping/axiosMapping";

export const updateLocationName = async (
  authDoc: AuthDoc | null,
  requestDoc: UpdateLocationNameRequestDoc,
  serverParamsDoc: ServerParamsDoc,
  cancelTokenSource: FBCancelTokenSource,
): Promise<FBaseModelDoc[]> => {
  return fbClientActions.updateLocationName(
    authDoc,
    requestDoc,
    serverParamsDoc,
    cancelTokenSource,
  );
};
