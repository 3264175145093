import React, {
  FC,
  type PropsWithChildren,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useAtom } from "jotai";
import { EmailInput } from "../EmailInput";
import { defaultEmailState } from "../EmailInput/defaultEmailState";
import { EmailState } from "../EmailInput/EmailState";
import { ErrorArea } from "../ErrorArea";
import { LoginOrRegisterButton } from "../LoginOrRegisterButton";
import { PasswordInput } from "../PasswordInput";
import { PasswordState } from "../PasswordInput/PasswordState";
import { RegisterResetEmailButtons } from "../RegisterResetEmailButtons";
import { defaultPasswordState } from "../PasswordInput/defaultPasswordState";
import { showLogin } from "../../../JotaiStore";
import { useLoginWithEmailAndPassword } from "../../../Use/useLoginWithEmailAndPassword";
import { logger } from "../../../logger";
import { useNavigateBack } from "../../../useNavigate/useNavigateBack";
import { isAnyProperty } from "../../../../Common/is/isAnyProperty";
import { isString } from "../../../../Common/is/isString";

interface LoginSectionProps extends PropsWithChildren {
  registerMode: boolean;
  setRegisterMode: (mode: boolean) => void;
}
export const LoginSection: FC<LoginSectionProps> = props => {
  const { registerMode, setRegisterMode } = props;
  const goBack = useNavigateBack();
  const [emailState, setEmailState] = useState<EmailState>({
    ...defaultEmailState,
  });
  const [passwordState, setPasswordState] = useState<PasswordState>({
    ...defaultPasswordState,
  });

  const {
    method: loginMethod,
    error,
    success,
    resetState,
    loading,
  } = useLoginWithEmailAndPassword();
  error && console.log("Error loggin in", error);
  const [, setShowLogin] = useAtom(showLogin);
  useEffect(() => {
    if (success) {
      setShowLogin(false);
      resetState();
      setEmailState({ ...defaultEmailState });
      setPasswordState({ ...defaultPasswordState });
      logger.log("Successfully Logged In or Register");
      goBack();
    }
  }, [resetState, setShowLogin, success, goBack]);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (isAnyProperty(error)) {
      const { code, message } = error;
      if (isString(message)) {
        if (message.toLowerCase().includes("the password is invalid")) {
          setLoginErrorMessage("Incorrect Password");
        } else if (
          message
            .toLowerCase()
            .includes("access to this account has been temporarily disabled")
        ) {
          setLoginErrorMessage(
            "Attempted Login too many times. Wait or reset password",
          );
        } else if (message.toLowerCase().includes("auth/wrong-password")) {
          setLoginErrorMessage("Wrong password");
        } else {
          setLoginErrorMessage(message);
        }
      } else if (isString(code)) {
        setLoginErrorMessage(code);
      } else {
        setLoginErrorMessage("Error logging in");
      }
    } else {
      setLoginErrorMessage(null);
    }
  }, [error]);
  const [loginOrRegisterButtonEnabled, setLoginOrRegisterButtonEnabled] =
    useState<boolean>(false);

  useEffect(() => {
    setLoginOrRegisterButtonEnabled(
      (passwordState.passwordValid && emailState.emailExists) ||
        (registerMode && passwordState.passwordValid && emailState.emailValid),
    );
  }, [passwordState, emailState, registerMode]);
  const innerSetEmailState = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (emailState: EmailState) => {
      setEmailState(emailState);
      resetState();
    },
    [resetState],
  );
  const innerSetPasswordState = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (passwordState: PasswordState) => {
      setPasswordState(passwordState);
      resetState();
    },
    [resetState],
  );
  const loginOrRegister = useCallback(() => {
    if (!registerMode) {
      loginMethod(emailState.email, passwordState.password);
    }
  }, [registerMode, loginMethod, emailState.email, passwordState.password]);
  const [, setResetText] = useState<string | null>(null);
  return (
    <>
      <EmailInput
        registerMode={registerMode}
        setEmailState={innerSetEmailState}
        emailState={emailState}
      />
      <PasswordInput
        passwordState={passwordState}
        setPasswordState={innerSetPasswordState}
      />
      <ErrorArea message={loginErrorMessage} />
      <LoginOrRegisterButton
        enabled={loginOrRegisterButtonEnabled}
        loginOnServer={loginOrRegister}
        registerMode={registerMode}
        loading={loading}
      />
      <RegisterResetEmailButtons
        emailState={emailState}
        setResetText={setResetText}
        loginModePressed={() => setRegisterMode(false)}
        registerModePressed={() => setRegisterMode(true)}
        registerMode={registerMode}
      />
    </>
  );
};
