import React, {
  FC,
  JSXElementConstructor,
  ReactElement,
  useCallback,
  type PropsWithChildren,
} from "react";
import { Button } from "@rneui/themed";
import { GestureResponderEvent } from "react-native";
import { CustomRow } from "../CustomRow";
import { RNTextStyle, RNViewStyle } from "../../../Mapping/styleSheetCreate";
import { LoadingScreen } from "../../Pages/LoadingPage/LoadingScreen";

interface CustomLoadingButtonProps {
  width?: number;
  onPress: () => void;
  disabled?: boolean;
  color?: "primary" | "secondary" | "warning" | "error";
  buttonStyle?: RNViewStyle;
  containerStyle?: RNViewStyle;
  loading?: boolean;
  loadingMessage?: string | null;
  loadingProps?: {
    size: "small" | "large";
    color?: string;
  };
  iconRight?: boolean;
  linearGradientProps?: {
    colors: string[];
    start: number[];
    end: number[];
  };
  title?: string | ReactElement<{}, string | JSXElementConstructor<any>>;
  titleProps?: RNTextStyle;
  titleStyle?: RNTextStyle;
  iconContainerStyle?: RNViewStyle;
  raised?: boolean;
  type?: "outline" | "clear";
  icon?: {
    name: string;
    type?: "font-awesome";
    size: number;
    color: string;
  };
}
const defaultWidth = 80;
export const CustomLoadingButton: FC<
  PropsWithChildren<CustomLoadingButtonProps>
> = props => {
  const {
    children,
    onPress,
    loading = false,
    loadingMessage,
    width,
    containerStyle,
  } = props;
  const pressed = useCallback(
    (event: GestureResponderEvent) => {
      event.stopPropagation();
      event.preventDefault();
      onPress();
    },
    [onPress],
  );
  return (
    <CustomRow justifyContent={"center"}>
      {children && !loading && (
        <Button
          {...props}
          onPress={pressed}
          containerStyle={
            containerStyle
              ? [containerStyle, { width: width || defaultWidth }]
              : { width: width || defaultWidth }
          }
        />
      )}
      {!children && !loading && (
        <Button
          {...props}
          onPress={pressed}
          containerStyle={
            containerStyle
              ? [containerStyle, { width: width || defaultWidth }]
              : { width: width || defaultWidth }
          }>
          {children}
        </Button>
      )}
      {loading && (
        <LoadingScreen title={loadingMessage || ""} showHomeButton={false} />
      )}
    </CustomRow>
  );
};
