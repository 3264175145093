import { useCallback } from "react";
import { NavigationProp, useNavigation } from "@react-navigation/native"
import { RootStackParamList } from "../AppMain/AppNavigationContainer/RootStackParamList";

export const useNavigateBack = () => {
    const navigation = useNavigation<NavigationProp<RootStackParamList>>();
    const navigateBack = useCallback(() => {
        navigation.goBack && navigation.goBack();
    }, []);
    return navigateBack
}