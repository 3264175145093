import FError from "../Common/ErrorHandling/FError";
import {ResponseCode} from "../Common/ErrorHandling/ResponseCode";
import {AuthDoc} from "../Common/Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import {
  UnblockPhoneNumberRequestDoc,
  // eslint-disable-next-line max-len
} from "../Common/Interfaces/ServerDocs/General/PhoneNumber/UnblockPhoneNumberRequestDoc";
import {ServerErrorCodes} from "../Common/Interfaces/ServerDocs/ServerErrorCodes";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";

const method = "fbActions.unblockPhoneNumber";
export const unblockPhoneNumber = async (
  _authDoc: AuthDoc | null,
  _requestDoc: UnblockPhoneNumberRequestDoc,
  _serverParamsDoc: ServerParamsDoc,
): Promise<FBaseModelDoc[]> => {
  const message = `Do not use ${method} on server`;
  throw new FError({
    message,
    method,
    responseCode: ResponseCode.InternalServerError,
    code: ServerErrorCodes.InvalidServerCall,
  });
};
