import {getOperatingMode} from "../Functions/getOperatingMode";
import {RouterRoles} from "../Interfaces/Navigation/RouterRoles";
import {ServerParamsDoc} from "../Interfaces/ServerParamsDoc";

export const fakeServerParamsDoc = (): ServerParamsDoc => ({
  operatingModeDoc: getOperatingMode(),
  routerRole: RouterRoles.generic,
  adminCode: "no code",
  trace: [],
});
