import { useCallback, useEffect, useRef, useState } from "react";
import CancelError from "../../Common/ErrorHandling/CancelError";
import { getCancelTokenSource } from "../../FAxios/getCancelTokenSource";
import { passwordReset } from "../../firebase/authentication";
import { FBCancelTokenSource } from "../../Mapping/axiosMapping";
import { logger } from "../logger";

type StateType = {
  error: Error | null;
  loading: boolean;
  success: boolean;
  report: string;
};
const defaultState: StateType = {
  error: null,
  loading: false,
  success: false,
  report: "",
};

export const useSendPasswordResetEmail = () => {
  const tokenRef = useRef<FBCancelTokenSource | null>(null);
  const cancelRequest = useCallback(() => {
    const token = tokenRef.current;
    token && token.cancel();
    tokenRef.current = null;
  }, []);
  useEffect(() => {
    return () => {
      cancelRequest();
    };
  }, [cancelRequest]);
  const [{ error, loading, success, report }, setState] = useState<StateType>({
    ...defaultState,
  });
  const resetState = useCallback(() => {
    setState({ ...defaultState });
  }, []);
  const method = useCallback(
    async (email: string) => {
      cancelRequest();
      const cancelTokenSource = getCancelTokenSource();
      tokenRef.current = cancelTokenSource;
      setState(previous => ({
        ...previous,
        loading: false,
        success: false,
      }));
      try {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const report = await passwordReset(email);
        logger.log("In reseteEamil, passed sent", report);
        const ref = tokenRef.current;
        if (ref) {
          tokenRef.current = null;
          setState(previous => ({
            ...previous,
            loading: false,
            success: true,
            report,
          }));
        }
      } catch (incomingError) {
        logger.log("In resetEmail, error", incomingError);
        const ref = tokenRef.current;
        if (ref) {
          logger.log("In resetEmail passedRef");
          tokenRef.current = null;
          if (!(incomingError instanceof CancelError)) {
            setState(previous => ({
              ...previous,
              loading: false,
              success: false,
              report: "",
              error: incomingError as Error,
            }));
          }
        }
      }
    },
    [cancelRequest],
  );
  return {
    method,
    loading,
    error,
    success,
    resetState,
    report,
  };
};
