import { RNViewStyle, styleSheetCreate } from "../../../Mapping/styleSheetCreate";

const styleObj: {
  horizontalLine: RNViewStyle;
  centergap: RNViewStyle;
  textArea: RNViewStyle;
} = {
  horizontalLine: {
    borderTopColor: "blue",
    borderWidth: 1,
    borderStyle: "dashed",
    width: 400,
    marginTop: 4,
  },
  centergap: {width: 50, height: 20},
  textArea: {width: 180, justifyContent: "center"},
}
const styles = styleSheetCreate(styleObj);
export const useStyles = () => styles;
  