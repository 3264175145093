import {useAtom} from "jotai";
import {useCallback} from "react";
import {
  QueryFunction,
  QueryFunctionContext,
  QueryKey,
  useQuery,
  useQueryClient,
} from "react-query";
import TimeoutError from "../../ErrorHandling/TimeoutError";
import {FBaseModelDoc} from "../../Interfaces/BaseDocs/FBaseModelDoc";
import {ModelTypes} from "../../Interfaces/Models/ModelTypes";
import {showConsole} from "../../../config/showConsole";
import {getCurrentUserUID} from "../../../firebase/authentication";
import {serverParams} from "../../../JotaiStore";
import {staleTime, cacheTime} from "../../../RQ/RQConstants";
import { queryFnForMyProfile } from "./queryFn/queryFnForMyProfile";

const method = "useGetMyProfile3";
const verbose = false;
export const myProfileKey = "MyProfile";
const queryKey = [ModelTypes.PublicProfile, myProfileKey];
export const useGetMyProfile3 = () => {
  const [{authDoc, serverParamsDoc}] = useAtom(serverParams);
  const defaultFetch: QueryFunction<FBaseModelDoc | null> = useCallback(
    async (
      context: QueryFunctionContext<QueryKey, FBaseModelDoc | null>,
    ): Promise<FBaseModelDoc | null> => {
      if (getCurrentUserUID()) {
        return queryFnForMyProfile<FBaseModelDoc | null>(
          authDoc,
          serverParamsDoc,
          context,
        ).catch(error => {
          if (error instanceof TimeoutError) {
            return queryFnForMyProfile<FBaseModelDoc | null>(
              authDoc,
              serverParamsDoc,
              context,
            );
          } else {
            throw Error;
          }
        });
      }
      return Promise.resolve(null);
    },
    [authDoc, serverParamsDoc],
  );
  const queryClient = useQueryClient();
  const cancelQuery = useCallback(() => {
    queryClient.cancelQueries(queryKey);
  }, [queryClient]);
  const {data, error, isLoading, refetch, isSuccess} = useQuery<
    FBaseModelDoc | null,
    Error,
    FBaseModelDoc | null,
    QueryKey
  >({
    queryKey,
    queryFn: defaultFetch,
    enabled: true,
    staleTime,
    cacheTime,
    onSuccess: (successData: FBaseModelDoc | null) => {
      showConsole &&
        verbose &&
        console.log(
          `${method} Successful retrieval of my Profile. Data is: [`,
          successData,
          "]",
        );
    },
    onError: (queryError: any) => {
      console.log(`${method} error:`, queryError);
    },
  });
  return {
    cancelQuery,
    refetch,
    data: data || null,
    isLoading,
    error,
    isSuccess,
  };
};
