import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import {ModelFields} from "../Common/Interfaces/Models/ModelFields";
import {isAnyProperty} from "../Common/is/isAnyProperty";
import {isFBaseModelDoc} from "../Common/is/isFBaseModelDoc";
import {FBDocumentSnapshot} from "../Mapping/firebaseMapping";

export const handleDocSnapshot = (
  snapshot: FBDocumentSnapshot,
): FBaseModelDoc[] => {
  if (snapshot.exists) {
    const data = snapshot.data();
    if (isFBaseModelDoc(data)) {
      const {metadata} = snapshot;
      if (isAnyProperty(metadata)) {
        const {fromCache, hasPendingWrites} = metadata;
        if (fromCache) {
          data[ModelFields.fromCache] = fromCache;
        }
        if (hasPendingWrites) {
          data[ModelFields.pendingWrites] = hasPendingWrites;
        }
      }
      return [data];
    } else if (Array.isArray(data)) {
      console.log("In handleDocSnapshot, unexpectedly got array", data);
      return data;
    } else {
      console.log(`Data is not FBaseModelDoc data: [${JSON.stringify(data)}].`);
    }
  } else {
    console.log("In handleDocSnapshot, snapshot does not exists. Descriptor:");
  }
  return [];
};
