import {ClientMode} from "../Interfaces/OperatingModes/ClientMode";
import {DBMode} from "../Interfaces/OperatingModes/DBMode";
import {OperatingModeDoc} from "../Interfaces/OperatingModes/OperatingModeDoc";
import {ServerMode} from "../Interfaces/OperatingModes/ServerMode";

const mode = "production";
export const getOperatingMode = (): OperatingModeDoc => {
  if (mode === "production") {
    return {
      clientMode: ClientMode.production,
      serverMode: ServerMode.production,
      dbMode: DBMode.production,
    };
  }
  return {
    clientMode: ClientMode.development,
    serverMode: ServerMode.development,
    dbMode: DBMode.development,
  };
};
