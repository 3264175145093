import { RNViewStyle, styleSheetCreate } from "../../../../Mapping/styleSheetCreate";


const styleObj: {
  button: RNViewStyle;
  buttonWidth: RNViewStyle;
} = {
  button: {
    borderRadius: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
  },
  buttonWidth: {width: 200},
};
const styles = styleSheetCreate(styleObj);
export const useStyles = () => styles;
