import {isString} from "../is/isString";

export const validatePassword = (
  candidate: unknown,
): {scrubbed: string; valid: boolean} => {
  if (isString(candidate)) {
    return {scrubbed: candidate, valid: candidate.length > 6};
  }
  return {scrubbed: "", valid: false};
};
