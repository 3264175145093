import {FDescriptorDoc} from "../Common/Interfaces/BaseDocs/FDescriptorDoc";
import {isFDescriptorDoc} from "../Common/is/isFDescriptorDoc";
import {arrayContains} from "./arrayContains";
import {
  isSearchTermInterface,
  isSearchTermObj,
  searchTermInterface,
} from "./searchTermInterface";

export const addSearchTerm = (
  descriptorKey: string,
  currentSearchTerms: searchTermInterface,
  params: {[property: string]: FDescriptorDoc | null},
): searchTermInterface => {
  const keys = Object.keys(params);
  if (keys.length > 0) {
    const key = keys[0];
    const obj = params[key];
    if (isSearchTermInterface(currentSearchTerms)) {
      const existingTerm = currentSearchTerms[key];
      // if (isFDescriptorDoc(obj)) {
      if (isSearchTermObj(existingTerm)) {
        let existingArray: FDescriptorDoc[] = [];
        if (descriptorKey === "customers") {
          existingArray = existingTerm.customers || [];
        } else if (descriptorKey === "businesses") {
          existingArray = existingTerm.businesses || [];
        } else if (descriptorKey === "providers") {
          existingArray = existingTerm.providers || [];
        } else if (descriptorKey === "orders") {
          existingArray = existingTerm.orders || [];
        } else {
          console.log("Unhandled Key", descriptorKey);
        }
        let scrubbed: FDescriptorDoc[] = [];
        if (Array.isArray(existingArray)) {
          if (isFDescriptorDoc(obj)) {
            const index = arrayContains(existingArray as FDescriptorDoc[], obj);
            if (index < 0) {
              (existingArray as FDescriptorDoc[]).push(obj);
              scrubbed = [...existingArray];
            } else {
              scrubbed = [...existingArray];
            }
          } else {
            scrubbed = [];
          }
        } else {
          if (isFDescriptorDoc(obj)) {
            scrubbed = [obj];
          } else {
            scrubbed = [];
          }
        }
        const newTerm = {...existingTerm, [descriptorKey]: scrubbed};
        currentSearchTerms[key] = newTerm;
        return {...currentSearchTerms};
      } else {
        if (isFDescriptorDoc(obj)) {
          currentSearchTerms[key] = {[descriptorKey]: [obj]};
        } else {
          currentSearchTerms[key] = {};
        }
        return {...currentSearchTerms};
      }
      // } else {
      //  return currentSearchTerms;
      // }
    } else if (isFDescriptorDoc(obj)) {
      return {[key]: {[descriptorKey]: [obj]}};
    } else {
      return {};
    }
  }
  return currentSearchTerms || {};
};
