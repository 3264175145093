export const logger = {
    info: (...params) => {
        console.info(params)
    },
    error: (...params) => {
        console.error(params);
    },
    log: (...params) => {
        console.log(params);
    }
}