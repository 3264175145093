export enum ScreenNames {
    NotFound = "NotFound",
    Root = "Root",
    LoginModal = "LoginModal",
    Profile = "Profile",
    Invoice = "Invoice",
    PaymentResult = "PaymentResult",
    PaymentOptions = "PaymentOptions",
    SimplePayment = "SimplePayment",
    WebhookPayment = "WebhookPayment",
    OrderMessages = "OrderMessages",
    BusinessMessages = "BusinessMessages",
    PrivateMessages = "PrivateMessages",
    Order = "Order",
    GiftedChat = "GiftedChat",
    Businesses = "Businesses",
    Business = "Business",
    People = "People",
    Orders = "Orders",
    Home = "home",
}