import { Linking, TouchableOpacity } from 'react-native';
import { RootStackScreenProps } from '../../AppMain/AppNavigationContainer/RootStackScreenProps';
import { ThemedText } from '../../ThemedComponents/ThemedText';
import { ThemedView } from '../../ThemedComponents/ThemedView';
import { ScreenNames } from '../../AppMain/AppNavigationContainer/ScreenNames';
import { useStyles } from './useStyles';
import { useNavigateToRootPage } from '../../useNavigate/useNavigateToRootPage';
import { useCallback, useEffect } from 'react';

const homeURL = "https://www.pvrtimer.com";
export default function NotFoundScreen({ navigation }: RootStackScreenProps<ScreenNames.NotFound>) {
  const { container, title, linkButton, linkText } = useStyles();
  const navigateToRootScreen = useNavigateToRootPage()
  const linkTo = useCallback(() => {
    Linking.openURL(homeURL)
  }, [])
  useEffect(() => {
    linkTo();
  }, []);
  return (
    <ThemedView style={container}>
      <ThemedText style={title}>This screen doesn't exist.</ThemedText>
      <TouchableOpacity onPress={linkTo} style={linkButton}>
        <ThemedText style={linkText}>Go to home screen!</ThemedText>
      </TouchableOpacity>
    </ThemedView>
  );
}
