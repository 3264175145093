import {FGeolocationDocObj} from "../Interfaces/Location/FGeolocationDocObj";
import {isAnyProperty} from "./isAnyProperty";

export const isFGeoLocationDocObj =
    (candidate: unknown): candidate is FGeolocationDocObj => {
      if (isAnyProperty(candidate)) {
        return true;
      }
      return false;
    };
