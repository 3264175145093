import React, {FC} from "react";
import { CustomRNEButton } from "../../../Client/Custom/CustomRNEButton";

interface CountButtonProps {
    amount: number;
    title: string;
    setAndStart: boolean;
    onPress: (amount: number, setAndStart: boolean) => void;
}
export const CountButton: FC<CountButtonProps> = props => {
    const {setAndStart, amount, onPress, title} = props;
    return <CustomRNEButton buttonStyle={{width: 180, marginTop: 10 }} onPress={() => {
        onPress(amount, setAndStart);
    }} title={title} />
}