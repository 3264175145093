import React, {
  FC,
  JSXElementConstructor,
  ReactElement,
  useCallback,
  type PropsWithChildren,
} from "react";
import { Button } from "@rneui/themed";
import { GestureResponderEvent } from "react-native";
import { RNTextStyle, RNViewStyle } from "../../../Mapping/styleSheetCreate";

interface CustomRNEButtonProps {
  onPress: () => void;
  disabled?: boolean;
  color?: "primary" | "secondary" | "warning" | "error";
  buttonStyle?: RNViewStyle;
  containerStyle?: RNViewStyle;
  loading?: boolean;
  loadingProps?: {
    size: "small" | "large";
    color?: string;
  };
  iconRight?: boolean;
  linearGradientProps?: {
    colors: string[];
    start: number[];
    end: number[];
  };
  title?: string | ReactElement<{}, string | JSXElementConstructor<any>>;
  titleProps?: RNTextStyle;
  titleStyle?: RNTextStyle;
  iconContainerStyle?: RNViewStyle;
  raised?: boolean;
  type?: "outline" | "clear";
  icon?: {
    name: string;
    type?: "font-awesome";
    size: number;
    color: string;
  };
}
export const CustomRNEButton: FC<PropsWithChildren<CustomRNEButtonProps>> = props => {
  const { children, onPress } = props;
  const pressed = useCallback(
    (event: GestureResponderEvent) => {
      event.stopPropagation();
      event.preventDefault();
      onPress();
    },
    [onPress],
  );
  return children ? (
    <Button {...props} onPress={pressed} />
  ) : (
    <Button {...props} onPress={pressed}>
      {children}
    </Button>
  );
};
