import React, {FC, type PropsWithChildren} from "react";
import {useCommonStyles} from "../../../CommonStyles/useCommonStyles";
import {CustomRow} from "../../../Custom/CustomRow";
import {CustomText} from "../../../Custom/CustomText";
import {useStyles} from "./useStyles";

interface ErrorAreaProps extends PropsWithChildren {
  message?: string | null;
}
export const ErrorArea: FC<ErrorAreaProps> = props => {
  const {message} = props;
  const {error} = useCommonStyles();
  const {container} = useStyles();
  return (
    <CustomRow justifyContent={"center"} style={container}>
      <CustomText type="regular" style={error}>
        {message || ""}
      </CustomText>
    </CustomRow>
  );
};
