import {fbActions} from "../../FBActions/fbActions";
import {FBCancelTokenSource} from "../../Mapping/axiosMapping";
import {FBQueryDocumentSnapshot} from "../../Mapping/firebaseMapping";
import {AnyProperty} from "../Interfaces/AnyProperty";
import {AuthDoc} from "../Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Interfaces/BaseDocs/FBaseModelDoc";
import {FDescriptorDoc} from "../Interfaces/BaseDocs/FDescriptorDoc";
import {GetOrCreateAlbumRequestDoc} from "../Interfaces/ServerDocs/Album/GetOrCreateAlbumRequestDoc";
import {emailLookupRequestDoc} from "../Interfaces/ServerDocs/Auth/emailLookupRequestDoc";
import {emailLookupResponseDoc} from "../Interfaces/ServerDocs/Auth/emailLookupResponseDoc";
import {LookupUserProfileViaUIDRequestDoc} from "../Interfaces/ServerDocs/Auth/LookupUserProfileViaUIDRequestDoc";
import {registerUserRequestDoc} from "../Interfaces/ServerDocs/Auth/registerUserRequestDoc";
import {BusinessPersonAtBusinessRequestDoc} from "../Interfaces/ServerDocs/Business/BusinessPersonAtBusinessRequestDoc";
import {BusinessPersonAtDomainRequestDoc} from "../Interfaces/ServerDocs/Business/BusinessPersonAtDomainRequestDoc";
import {businessPersonRequestDoc} from "../Interfaces/ServerDocs/Business/businessPersonLookupRequestDoc";
import {
  LookupUsersBusinessByRoleRequestDoc,
  // eslint-disable-next-line max-len
} from "../Interfaces/ServerDocs/Business/LookupUsersBusinessesByRoleRequestDoc";
import {LookupUsersBusinessByRoleForABusinessRequestDoc} from "../Interfaces/ServerDocs/Business/LookupUsersBusinessRoleForABusiness";
import {publishOrUnpublishBusinessRequestDoc} from "../Interfaces/ServerDocs/Business/publishOrUnpublishBusinessRequestDoc";
import {UpdateDescriptionRequestDoc} from "../Interfaces/ServerDocs/Business/UpdateDescriptionRequestDoc";
import {UpdateBusinessNameRequestDoc} from "../Interfaces/ServerDocs/Business/UpdateBusinessNameRequestDoc";
import {CreateDocumentRequestDoc} from "../Interfaces/ServerDocs/CreateDocumentRequestDoc";
import {
  UpdateBusinessDomainRequestDoc,
  // eslint-disable-next-line max-len
} from "../Interfaces/ServerDocs/General/BusinessDomain/UpdateBusinessDomainRequestDoc";
import {
  LookupDisplayNameRequestDoc,
  // eslint-disable-next-line max-len
} from "../Interfaces/ServerDocs/General/DisplayName/LookupDisplayNameRequestDoc";
import {
  UpdateDisplayNameRequestDoc,
  // eslint-disable-next-line max-len
} from "../Interfaces/ServerDocs/General/DisplayName/UpdateDisplayNameRequestDoc";
import {GetOrCreateFollowRequestDoc} from "../Interfaces/ServerDocs/General/Follow/GetOrCreateFollowRequestDoc";
import {
  UnblockPhoneNumberRequestDoc,
  // eslint-disable-next-line max-len
} from "../Interfaces/ServerDocs/General/PhoneNumber/UnblockPhoneNumberRequestDoc";
import {
  GetOrCreateBusinessRoomRequestDoc,
  // eslint-disable-next-line max-len
} from "../Interfaces/ServerDocs/General/Room/GetOrCreateBusinessRoomRequestDoc";
import {GetOrCreatePrivateRoomRequestDoc} from "../Interfaces/ServerDocs/General/Room/GetOrCreatePrivateRoomRequestDoc";
import {SendMessageRequestDoc} from "../Interfaces/ServerDocs/General/SendMessageRequestDoc";
import {UpdateLocationRequestDoc} from "../Interfaces/ServerDocs/General/UpdateLocationRequestDoc";
import {GetDocumentRequestDoc} from "../Interfaces/ServerDocs/GetDocumentRequestDoc";
import {ListQueryRequestDoc} from "../Interfaces/ServerDocs/ListQueryRequestDoc";
import {LookupSearchCenterRequestDoc} from "../Interfaces/ServerDocs/Location/LookupSearchCenterRequestDoc";
import {UpdateLocationNameRequestDoc} from "../Interfaces/ServerDocs/Location/UpdateLocationNameRequestDoc";
import {lookupBusinessByDomainRequestDoc} from "../Interfaces/ServerDocs/lookupBusinessByDomainRequestDoc";
import {
  CreateNewOrderFromExistingOrderRequestDoc,
  // eslint-disable-next-line max-len
} from "../Interfaces/ServerDocs/Order/CreateNewOrderFromExistingOrderRequestDoc";
import {GetOrCreateUnsubmittedOrderRequestDoc} from "../Interfaces/ServerDocs/Order/GetOrCreateUnsubmittedOrderRequestDoc";
import {UpdateOrderSeriesRequestDoc} from "../Interfaces/ServerDocs/Order/UpdateOrderSeriesRequestDoc";
import {CreatePhoneNumberRequestDoc} from "../Interfaces/ServerDocs/PhoneNumber/CreatePhoneNumberRequestDoc";
import {ListPhoneNumbersRequestDoc} from "../Interfaces/ServerDocs/PhoneNumber/ListPhoneNumbersRequestDoc";
import {UpdatePhoneNumberRequestDoc} from "../Interfaces/ServerDocs/PhoneNumber/UpdatePhoneNumberRequestDoc";
import {
  UpdatePhoneNumberTextEnabledRequestDoc,
  // eslint-disable-next-line max-len
} from "../Interfaces/ServerDocs/PhoneNumber/UpdatePhoneNumberTextEnabledRequestDoc";
import {ChangeListOrderRequestDoc} from "../Interfaces/ServerDocs/Product/ChangeListOrderRequestDoc";
import {
  GetOrCreateProductByBusinessRequestDoc,
  // eslint-disable-next-line max-len
} from "../Interfaces/ServerDocs/Product/GetOrCreateProductByBusinessRequestDoc";
import {GetProductsRequestDoc} from "../Interfaces/ServerDocs/Product/GetProductsRequestDoc";
import {PublishProductRequestDoc} from "../Interfaces/ServerDocs/Product/PublishProductRequestDoc";
import {UpdateProductDescriptionRequestDoc} from "../Interfaces/ServerDocs/Product/UpdateProductDescriptionRequestDoc";
import {UpdateProductFieldsRequestDoc} from "../Interfaces/ServerDocs/Product/UpdateProductFieldsRequestDoc";
import {UpdateProfileDescriptionRequestDoc} from "../Interfaces/ServerDocs/Profile/UpdateProfileDescriptionRequestDoc";
import {UpdateProfileNameRequestDoc} from "../Interfaces/ServerDocs/Profile/UpdateProfileNameRequestDoc";
import {ListRatingsRequestDoc} from "../Interfaces/ServerDocs/Rating/ListRatingsRequestDoc";
import {RateRequestDoc} from "../Interfaces/ServerDocs/Rating/RateRequestDoc";
import {RemoveDocRequestDoc} from "../Interfaces/ServerDocs/RemoveDocRequestDoc";
import {CreateStripeBusinessAccountRequestDoc} from "../Interfaces/ServerDocs/Stripe/CreateStripeBusinessAccountRequestDoc";
import {UpdateInvoiceTipRequestDoc} from "../Interfaces/ServerDocs/Stripe/UpdateInvoiceTipRequestDoc";
import {UpdateOrderInvoiceRequestDoc} from "../Interfaces/ServerDocs/Stripe/UpdateOrderInvoiceRequestDoc";
import {UpdateDocumentRequestDoc} from "../Interfaces/ServerDocs/UpdateDocumentRequestDoc";
import {ServerParamsDoc} from "../Interfaces/ServerParamsDoc";
import {unsubscribeFunction} from "../../FBActions/innerGetDocs2";
import {CreateNewProductRequestDoc} from "../Interfaces/ServerDocs/Product/CreateNewProductRequestDoc";
import {UpdatePrivateBusinessInfoRequestDoc} from "../Interfaces/ServerDocs/Business/UpdatePrivateBusinessInfoRequestDoc";
import {UpdateBusinessPaymentOptionsRequestDoc} from "../Interfaces/ServerDocs/Business/UpdateBusinessPaymentOptionsRequestDoc";
import {updateSelectedBusinessPersonRequestDoc} from "../Interfaces/ServerDocs/SelectedBusinessPerson/updateSelectedBusinessPersonRequestDoc";
import {saveContentRequestDoc} from "../Interfaces/ServerDocs/Content/saveContentRequestDoc";
import {GetContentRequestDoc} from "../Interfaces/ServerDocs/Content/GetContentRequestDoc";
import {AuthLookupPhoneNumberRequestDoc} from "../Interfaces/ServerDocs/Auth/AuthLookupPhoneNumberRequestDoc";
import {AuthLookupPhoneNumberResponseDoc} from "../Interfaces/ServerDocs/Auth/AuthLookupPhoneNumberResponseDoc";
import {RegisterUserResponseDocWithError} from "../Interfaces/ServerDocs/Auth/RegisterUserResponseDocWithError";
import {SaveDriversRequestDoc} from "../Interfaces/ServerDocs/Profile/SaveDriversLicenseRequestDoc";
import {UpdateProfilePaymentOptionsRequestDoc} from "../Interfaces/ServerDocs/Profile/UpdateProfilePaymentOptionsRequestDoc";
import {GetDriversRequestDoc} from "../Interfaces/ServerDocs/Profile/GetDriversLicenseRequestDoc";
import {CreateOrUpdateMyRatingRequestDoc} from "../Interfaces/ServerDocs/Rating/CreateOrUpdateMyRatingRequestDoc";
import {GetMyRatingRequestDoc} from "../Interfaces/ServerDocs/Rating/GetMyRatingRequestDoc";
import {GetOrCreateRatingTallyRequestDoc} from "../Interfaces/ServerDocs/RatingTally/GetOrCreateRatingTally";
import {LookupProviderInfoRequestDoc} from "../Interfaces/ServerDocs/Provider/LookupProviderInfoRequestDoc";
import {UpdateProviderInfoRequestDoc} from "../Interfaces/ServerDocs/Provider/UpdateProviderInfoRequestDoc";
import {LookupTaxFormRequestDoc} from "../Interfaces/ServerDocs/Profile/LookupTaxFormRequestDoc";
import {SaveTaxFormRequestDoc} from "../Interfaces/ServerDocs/Profile/SaveTaxFormRequestDoc";
import {CreateProviderDistributionRequestDoc} from "../Interfaces/ServerDocs/ProviderDistribution/CreateProviderDistributionRequestDoc";
import {SendVenmoRequestRequestDoc} from "../Interfaces/ServerDocs/Order/SendVenmoRequestRequestDoc";
import {MarkAsFullfilledRequestDoc} from "../Interfaces/ServerDocs/VenmoRequest/MarkAsFullfilledRequestDoc";
import {CreateIndexReportRequestDoc} from "../Interfaces/ServerDocs/Admin/CreateIndexReportRequestDoc";
import {CreateAxiosReportRequestDoc} from "../Interfaces/ServerDocs/Admin/CreateAxiosReportRequestDoc";
import {CreateLoginReportRequestDoc} from "../Interfaces/ServerDocs/Admin/CreateLoginReportRequestDoc";
import {UpdateAvatarRequestDoc} from "../Interfaces/ServerDocs/Profile/UpdateAvatarRequestDoc";
import {UpdateBusinessLogoRequestDoc} from "../Interfaces/ServerDocs/Business/UpdateBusinessLogoRequestDoc";
import {ClientAppErrorReportRequestDoc} from "../Interfaces/ServerDocs/Error/ClientAppErrorReportRequestDoc";
import {ClientStripeErrorReportRequestDoc} from "../Interfaces/ServerDocs/Error/ClientStripeErrorReportRequestDoc";
import {CreateOrUpdateOrderRatingRequestDoc} from "../Interfaces/ServerDocs/Rating/CreateOrUpdateOrderRatingRequestDoc";
import {StripeAccountLink, StripeResponse} from "../../Mapping/StripeMapping";
import {ClientLinkErrorReportRequestDoc} from "../Interfaces/ServerDocs/Error/ClientLinkErrorReportRequestDoc";

export const dbActions = {
  createStripePaymentIntent: async (
    authDoc: AuthDoc | null,
    order: FDescriptorDoc,
    customer: FDescriptorDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<AnyProperty[]> => {
    return fbActions.createStripePaymentIntent(
      authDoc,
      {order, customer},
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  createStripeBusinessAccount: async (
    authDoc: AuthDoc | null,
    requestDoc: CreateStripeBusinessAccountRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<StripeResponse<StripeAccountLink>[]> => {
    return fbActions.createStripeBusinessAccount(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateStripePaymentIntent: async (
    authDoc: AuthDoc | null,
    order: FDescriptorDoc,
    customer: FDescriptorDoc,
    paymentIntent: AnyProperty,
    serverParamsDoc: ServerParamsDoc,
  ): Promise<AnyProperty[]> => {
    return fbActions.updateStripePaymentIntent(
      authDoc,
      {order, customer, paymentIntent},
      serverParamsDoc,
    );
  },
  getOrCreateFollow: async (
    authDoc: AuthDoc | null,
    requestDoc: GetOrCreateFollowRequestDoc,
    serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getOrCreateFollow(authDoc, requestDoc, serverParamsDoc);
  },
  lookupUserProfileViaUID: async (
    authDoc: AuthDoc | null,
    requestDoc: LookupUserProfileViaUIDRequestDoc,
    serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.lookupUserProfileViaUID(
      authDoc,
      requestDoc,
      serverParamsDoc,
    );
  },
  getOrCreateBusinessRoom: async (
    authDoc: AuthDoc | null,
    requestDoc: GetOrCreateBusinessRoomRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getOrCreateBusinessRoom(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  getOrCreatePrivateRoom: async (
    authDoc: AuthDoc | null,
    requestDoc: GetOrCreatePrivateRoomRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getOrCreatePrivateRoom(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  getOrCreateUnsubmittedOrder: async (
    authDoc: AuthDoc | null,
    requestDoc: GetOrCreateUnsubmittedOrderRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getOrCreateUnsubmittedOrder(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  getListOfSnapshots: async (
    authDoc: AuthDoc | null,
    requestDoc: ListQueryRequestDoc,
    serverParams: ServerParamsDoc,
  ): Promise<FBQueryDocumentSnapshot[]> => {
    return fbActions.getListOfSnapshots(authDoc, requestDoc, serverParams);
  },
  getListOfSnapshots2: (
    authDoc: AuthDoc | null,
    requestDoc: ListQueryRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    next: (snapshot: FBQueryDocumentSnapshot[]) => void,
    errorHandler: (error: Error) => void,
    complete: () => void,
  ): unsubscribeFunction => {
    return fbActions.getListOfSnapshots2(
      authDoc,
      requestDoc,
      serverParamsDoc,
      next,
      errorHandler,
      complete,
    );
  },
  getList: async (
    authDoc: AuthDoc | null,
    requestDoc: ListQueryRequestDoc,
    serverParams: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getList(authDoc, requestDoc, serverParams);
  },
  getDocument: async (
    authDoc: AuthDoc | null,
    requestDoc: GetDocumentRequestDoc,
    serverParams: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getDocument(
      authDoc,
      requestDoc,
      serverParams,
      cancelTokenSource,
    );
  },
  createDocument: async (
    authDoc: AuthDoc | null,
    requestDoc: CreateDocumentRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.createDocument(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateDocument: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateDocumentRequestDoc,
    serverParams: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    console.log("IN dbActions.updateDOcument");
    return fbActions.updateDocument(
      authDoc,
      requestDoc,
      serverParams,
      cancelTokenSource,
    );
  },
  removeDocument: async (
    authDoc: AuthDoc | null,
    requestDoc: RemoveDocRequestDoc,
    serverParams: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.removeDocument(
      authDoc,
      requestDoc,
      serverParams,
      cancelTokenSource,
    );
  },
  lookupEmail: async (
    authDoc: AuthDoc | null,
    requestDoc: emailLookupRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelSource?: FBCancelTokenSource,
  ): Promise<emailLookupResponseDoc[]> => {
    return fbActions.lookupEmail(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelSource,
    );
  },
  registerUser: async (
    authDoc: AuthDoc | null,
    requestDoc: registerUserRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<RegisterUserResponseDocWithError[]> => {
    return fbActions.registerUser(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  lookupBusinessByDomain: async (
    authDoc: AuthDoc | null,
    requestDoc: lookupBusinessByDomainRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.lookupBusinessByDomain(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  lookupBusinessPersonAtBusiness: async (
    authDoc: AuthDoc | null,
    requestDoc: BusinessPersonAtBusinessRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.lookupBusinessPersonAtBusiness(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateBusinessDomain: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateBusinessDomainRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateBusinessDomain(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  lookupDisplayName: async (
    authDoc: AuthDoc | null,
    requestDoc: LookupDisplayNameRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.lookupDisplayName(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateDisplayName: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateDisplayNameRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateDisplayName(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  sendMessage: async (
    authDoc: AuthDoc | null,
    requestDoc: SendMessageRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.sendMessage(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  addOrRemoveBusinessPerson: async (
    authDoc: AuthDoc | null,
    requestDoc: businessPersonRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.addOrRemoveBusinessPerson(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  getOrCreateMyProfile: async (
    authDoc: AuthDoc | null,
    serverParams: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getOrCreateMyProfile(
      authDoc,
      serverParams,
      cancelTokenSource,
    );
  },
  updateModel: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateDocumentRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateModel(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateLocation: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateLocationRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateLocation(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  unblockPhoneNumber: async (
    authDoc: AuthDoc | null,
    requestDoc: UnblockPhoneNumberRequestDoc,
    serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    console.log(authDoc, requestDoc, serverParamsDoc);
    console.log("In unblockPhoneNumber. Neil need to fix");
    return Promise.resolve([]);
  },
  lookupUsersBusinessesByRole: async (
    authDoc: AuthDoc | null,
    requestDoc: LookupUsersBusinessByRoleRequestDoc,
    serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.lookupUsersBusinessesByRole(
      authDoc,
      requestDoc,
      serverParamsDoc,
    );
  },
  getUsersBusinessesByRole: async (
    authDoc: AuthDoc | null,
    requestDoc: LookupUsersBusinessByRoleRequestDoc,
    serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getUsersBusinessesByRole(
      authDoc,
      requestDoc,
      serverParamsDoc,
    );
  },
  lookupSearchCenter: async (
    authDoc: AuthDoc | null,
    requestDoc: LookupSearchCenterRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.lookupSearchCenter(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateLocationName: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateLocationNameRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateLocationName(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  createPhoneNumber: async (
    authDoc: AuthDoc | null,
    requestDoc: CreatePhoneNumberRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.createPhoneNumber(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updatePhoneNumber: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdatePhoneNumberRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updatePhoneNumber(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updatePhoneNumberTextEnabled: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdatePhoneNumberTextEnabledRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updatePhoneNumberTextEnabled(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  getOrCreateProductByBusiness: async (
    authDoc: AuthDoc | null,
    requestDoc: GetOrCreateProductByBusinessRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getOrCreateProductByBusiness(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  createNewProduct: async (
    authDoc: AuthDoc | null,
    requestDoc: CreateNewProductRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.createNewProduct(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  lookupUsersBusinessByRoleForABusiness: async (
    authDoc: AuthDoc | null,
    requestDoc: LookupUsersBusinessByRoleForABusinessRequestDoc,
    serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.lookupUsersBusinessByRoleForABusiness(
      authDoc,
      requestDoc,
      serverParamsDoc,
    );
  },
  publishProduct: async (
    authDoc: AuthDoc | null,
    requestDoc: PublishProductRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.publishProduct(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  getOrCreateNewBusiness: async (
    authDoc: AuthDoc | null,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getOrCreateNewBusiness(
      authDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  publishOrUnpublishBusiness: async (
    authDoc: AuthDoc | null,
    requestDoc: publishOrUnpublishBusinessRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.publishOrUnpublishBusiness(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateBusinessPaymentOptions: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateBusinessPaymentOptionsRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateBusinessPaymentOptions(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  createNewOrderFromExistingOrder: async (
    authDoc: AuthDoc | null,
    requestDoc: CreateNewOrderFromExistingOrderRequestDoc,
    serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.createNewOrderFromExistingOrder(
      authDoc,
      requestDoc,
      serverParamsDoc,
    );
  },
  lookupBusinessPersonAtDomain: async (
    authDoc: AuthDoc | null,
    requestDoc: BusinessPersonAtDomainRequestDoc,
    serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.lookupBusinessPersonAtDomain(
      authDoc,
      requestDoc,
      serverParamsDoc,
    );
  },
  getListOfPhoneNumbers: async (
    authDoc: AuthDoc | null,
    requestDoc: ListPhoneNumbersRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    source: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getListOfPhoneNumbers(
      authDoc,
      requestDoc,
      serverParamsDoc,
      source,
    );
  },
  changeListOrder: async (
    authDoc: AuthDoc | null,
    requestDoc: ChangeListOrderRequestDoc,
    serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.changeListOrder(authDoc, requestDoc, serverParamsDoc);
  },
  rate: async (
    authDoc: AuthDoc | null,
    requestDoc: RateRequestDoc,
    serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.rate(authDoc, requestDoc, serverParamsDoc);
  },
  createOrUpdateMyRating: async (
    authDoc: AuthDoc | null,
    requestDoc: CreateOrUpdateMyRatingRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.createOrUpdateMyRating(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  getMyRating: async (
    authDoc: AuthDoc | null,
    requestDoc: GetMyRatingRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getMyRating(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  getRatingTally: async (
    authDoc: AuthDoc | null,
    requestDoc: GetOrCreateRatingTallyRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getRatingTally(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  listRatings: async (
    authDoc: AuthDoc | null,
    requestDoc: ListRatingsRequestDoc,
    serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.listRatings(authDoc, requestDoc, serverParamsDoc);
  },
  getOrCreateAlbum: async (
    authDoc: AuthDoc | null,
    requestDoc: GetOrCreateAlbumRequestDoc,
    serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getOrCreateAlbum(authDoc, requestDoc, serverParamsDoc);
  },
  updateOrderSeries: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateOrderSeriesRequestDoc,
    serverParamsDoc: ServerParamsDoc,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateOrderSeries(authDoc, requestDoc, serverParamsDoc);
  },
  updateOrderInvoice: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateOrderInvoiceRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateOrderInvoice(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateInvoiceTip: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateInvoiceTipRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateInvoiceTip(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateBusinessName: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateBusinessNameRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateBusinessName(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateDescription: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateDescriptionRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateDescription(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateBusinessDescription: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateDescriptionRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateBusinessDescription(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  lookupDefaultBusiness: async (
    authDoc: AuthDoc | null,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.lookupDefaultBusiness(
      authDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  getListOfProducts: async (
    authDoc: AuthDoc | null,
    requestDoc: GetProductsRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getListOfProducts(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateProductFields: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateProductFieldsRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateProductFields(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateProfileName: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateProfileNameRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateProfileName(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateProductDescription: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateProductDescriptionRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateProductDescription(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateProfileDescription: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateProfileDescriptionRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateProfileDescription(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  getOrCreatePrivateBusinessInfo: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdatePrivateBusinessInfoRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getOrCreatePrivateBusinessInfo(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updatePrivateBusinessInfo: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdatePrivateBusinessInfoRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updatePrivateBusinessInfo(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  getMySelectedBusinessPerson: async (
    authDoc: AuthDoc | null,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getMySelectedBusinessPerson(
      authDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateMySelectedBusinessPerson: async (
    authDoc: AuthDoc | null,
    requestDoc: updateSelectedBusinessPersonRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateMySelectedBusinessPerson(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  clearMySelectedBusinessPerson: async (
    authDoc: AuthDoc | null,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.clearMySelectedBusinessPerson(
      authDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  saveContent: async (
    authDoc: AuthDoc | null,
    requestDoc: saveContentRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.saveContent(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  getContentDoc: async (
    authDoc: AuthDoc | null,
    requestDoc: GetContentRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.getContentDoc(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  lookupUserPhoneNumber: async (
    authDoc: AuthDoc | null,
    requestDoc: AuthLookupPhoneNumberRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<AuthLookupPhoneNumberResponseDoc[]> => {
    return fbActions.lookupUserPhoneNumber(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  saveDriversLicense: async (
    authDoc: AuthDoc | null,
    requestDoc: SaveDriversRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.saveDriversLicense(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateProfilePaymentsOption: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateProfilePaymentOptionsRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateProfilePaymentOptions(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  lookupDriversLicense: async (
    authDoc: AuthDoc | null,
    requestDoc: GetDriversRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.lookupDriversLicense(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  lookupProviderInfo: async (
    authDoc: AuthDoc | null,
    requestDoc: LookupProviderInfoRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.lookupProviderInfo(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateProviderInfo: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateProviderInfoRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateProviderInfo(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  lookupTaxForm: async (
    authDoc: AuthDoc | null,
    requestDoc: LookupTaxFormRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.lookupTaxForm(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  saveTaxForm: async (
    authDoc: AuthDoc | null,
    requestDoc: SaveTaxFormRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.saveTaxForm(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  createProviderDistribution: async (
    authDoc: AuthDoc | null,
    requestDoc: CreateProviderDistributionRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.createProviderDistribution(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  sendVenmoRequest: async (
    authDoc: AuthDoc | null,
    requestDoc: SendVenmoRequestRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.sendVenmoRequest(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  markAsFullfilled: async (
    authDoc: AuthDoc | null,
    requestDoc: MarkAsFullfilledRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.markAsFullfilled(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  createIndexReport: async (
    authDoc: AuthDoc | null,
    requestDoc: CreateIndexReportRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.createIndexReport(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  createAxiosReport: async (
    authDoc: AuthDoc | null,
    requestDoc: CreateAxiosReportRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.createAxiosReport(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  createLoginReport: async (
    authDoc: AuthDoc | null,
    requestDoc: CreateLoginReportRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.createLoginReport(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateProfileAvatar: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateAvatarRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateProfileAvatar(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateBusinessLogo: async (
    authDoc: AuthDoc | null,
    requestDoc: UpdateBusinessLogoRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.updateBusinessLogo(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  reportClientAppError: async (
    authDoc: AuthDoc | null,
    requestDoc: ClientAppErrorReportRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.reportClientAppError(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  reportClientStripeError: async (
    authDoc: AuthDoc | null,
    requestDoc: ClientStripeErrorReportRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.reportClientStripeError(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  createOrUpdateOrderRating: async (
    authDoc: AuthDoc | null,
    requestDoc: CreateOrUpdateOrderRatingRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.createOrUpdateOrderRating(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  reportClientLinkError: async (
    authDoc: AuthDoc | null,
    requestDoc: ClientLinkErrorReportRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return fbActions.reportClientLinkError(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
};
