import {AnyProperty} from "../Common/Interfaces/AnyProperty";
import {CreateIndexReportRequestDoc} from "../Common/Interfaces/ServerDocs/Admin/CreateIndexReportRequestDoc";
import {ListQueryRequestDoc} from "../Common/Interfaces/ServerDocs/ListQueryRequestDoc";
import {isAnyProperty} from "../Common/is/isAnyProperty";
import {isString} from "../Common/is/isString";
import {reportIndexService} from "../components/AdminServiceListener/reportIndexService";

export const preconditionCode = "firestore/failed-precondition";
export const indexMessage =
  "The query requires an index. You can create it here: ";
export const processFBListError = (
  error: unknown,
  requestDoc: ListQueryRequestDoc,
) => {
  if (isAnyProperty(error)) {
    const {code, message} = error;
    if (isString(code) && code === preconditionCode) {
      if (isString(message)) {
        if (message.includes(indexMessage)) {
          const stringArray = message.split(indexMessage);
          if (Array.isArray(stringArray) && stringArray.length > 1) {
            const indexURL = stringArray[stringArray.length - 1];
            const rDoc: CreateIndexReportRequestDoc = {
              requestDoc: requestDoc as unknown as AnyProperty,
              indexURL,
            };
            reportIndexService.next(rDoc);
          }
        }
      }
    }
  }
};
