/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { RootStackParamList } from './RootStackParamList';
import { ScreenNames } from './ScreenNames';
 
 const linking: LinkingOptions<RootStackParamList> = {
   prefixes: [Linking.createURL('/'), Linking.createURL("shyne://"), Linking.createURL("localhost:3000")],
   config: {
     screens: {
       Root: {
         screens: {
           TabOne: {
             screens: {
               TabOneScreen: 'one',
             },
           },
           TabTwo: {
             screens: {
               TabTwoScreen: 'two',
             },
           },
         },
       },
       LoginModal: ScreenNames.LoginModal,
       NotFound: '*',
     },
   },
 };
 
 export default linking;
 