import { useCallback, useEffect, useRef, useState } from "react";
import CancelError from "../../Common/ErrorHandling/CancelError";
import { getCancelTokenSource } from "../../FAxios/getCancelTokenSource";
import { loginViaEmailAndPassword } from "../../firebase/authentication";
import { FBCancelTokenSource } from "../../Mapping/axiosMapping";

type StateType = {
  error: Error | null;
  loading: boolean;
  success: boolean;
};
const defaultState: StateType = {
  error: null,
  loading: false,
  success: false,
};

export const useLoginWithEmailAndPassword = () => {
  const tokenRef = useRef<FBCancelTokenSource | null>(null);
  const cancelRequest = useCallback(() => {
    const token = tokenRef.current;
    token && token.cancel();
    tokenRef.current = null;
  }, []);
  useEffect(() => {
    return () => {
      cancelRequest();
    };
  }, [cancelRequest]);
  const [{ error, loading, success }, setState] = useState<StateType>({
    ...defaultState,
  });
  const resetState = useCallback(() => {
    setState({ ...defaultState });
  }, []);
  const method = useCallback(
    async (email: string, password: string) => {
      cancelRequest();
      const cancelTokenSource = getCancelTokenSource();
      tokenRef.current = cancelTokenSource;
      setState(previous => ({
        ...previous,
        loading: true,
        success: false,
      }));
      try {
        await loginViaEmailAndPassword(email, password);
        const ref = tokenRef.current;
        if (ref) {
          tokenRef.current = null;
          setState(previous => ({
            ...previous,
            loading: false,
            success: true,
          }));
        }
      } catch (incomingError) {
        const ref = tokenRef.current;
        if (ref) {
          tokenRef.current = null;
          if (!(incomingError instanceof CancelError)) {
            setState(previous => ({
              ...previous,
              loading: false,
              success: false,
              error: incomingError as Error,
            }));
          }
        }
      }
    },
    [cancelRequest],
  );
  return {
    method,
    loading,
    error,
    success,
    resetState,
  };
};
