import { Input } from "@rneui/themed";
import React, {
  FC,
  useRef,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { TextInput } from "react-native";
import { debounceTime, Observable, Subject, Subscription } from "rxjs";
import CancelError from "../../../../Common/ErrorHandling/CancelError";
import { isString } from "../../../../Common/is/isString";
import { validateEmail } from "../../../../Common/Validations/validateEmail";
import { useCommonStyles } from "../../../CommonStyles/useCommonStyles";
import { useEmailLookup } from "../../../Use/useEmailLookup";

import { EmailState } from "./EmailState";

const label = "Email";
const placeholder = "your@email.com";
interface EmailInputProps extends PropsWithChildren {
  disabled?: boolean;
  emailState: EmailState;
  setEmailState: (state: EmailState) => void;
  registerMode: boolean;
}
const debounceTimeConstant = 500;
export const EmailInput: FC<EmailInputProps> = props => {
  const {
    registerMode,
    disabled = false,
    setEmailState,
    emailState: { email, emailMessage },
  } = props;
  const ref = useRef<TextInput | null>(null);
  const {
    error: errorStyle,
    input,
    inputContainer,
    inputLabel,
  } = useCommonStyles();
  const subject = useRef<Subject<string>>(new Subject<string>());
  const stream = useRef<Observable<string>>(
    subject.current.pipe(debounceTime(debounceTimeConstant)),
  );
  const subscription = useRef<Subscription | null>(null);
  useEffect(() => {
    return () => {
      subscription.current && subscription.current.unsubscribe();
    };
  }, []);
  const latestEmail = useRef<string>("");
  const emailCache = useRef<{ [property: string]: boolean }>({});
  const processEmail = useCallback(
    (textValue: string, valid: boolean) => {
      const existing = emailCache.current[textValue];
      const exists = existing !== undefined ? !!existing : false;
      let message = valid
        ? `${textValue} is an email`
        : `${textValue} not an email`;
      if (exists) {
        message = `${textValue} already exists`;
      }
      setEmailState({
        email: textValue,
        emailExists: exists,
        emailValid: valid,
        emailMessage: message,
      });
    },
    [setEmailState],
  );
  const { method, response, error } = useEmailLookup();
  useEffect(() => {
    const scrubbed = scrubbedEmail.current;
    if (error && !(error instanceof CancelError)) {
      latestEmail.current === scrubbed && processEmail(scrubbed, true);
    } else if (response.length > 0) {
      emailCache.current[response[0].email] = response[0].exists;
      latestEmail.current === scrubbed && processEmail(scrubbed, true);
    } else {
      emailCache.current[scrubbed] = false;
      latestEmail.current === scrubbed && processEmail(scrubbed, false);
    }
  }, [error, processEmail, response]);
  const scrubbedEmail = useRef<string>("");
  const lookupEmailOnServer = useCallback(
    async (newEmail: string) => {
      const scrubbed = newEmail.toLowerCase();
      const entry = emailCache.current[scrubbed];
      if (entry !== undefined) {
        return;
      }
      scrubbedEmail.current = scrubbed;
      method(scrubbed);
    },
    [method],
  );
  useEffect(() => {
    subscription.current && subscription.current.unsubscribe();
    subscription.current = stream.current.subscribe(() =>
      lookupEmailOnServer(latestEmail.current),
    );
  }, [lookupEmailOnServer]);
  useEffect(() => {
    subscription.current && subscription.current.unsubscribe();
    subscription.current = stream.current.subscribe(() =>
      lookupEmailOnServer(latestEmail.current),
    );
  }, [lookupEmailOnServer]);
  const onChangeText = useCallback(
    (newValue: string) => {
      const { scrubbed, valid } = validateEmail(newValue);
      const lc = scrubbed.toLowerCase();
      latestEmail.current = lc;
      processEmail(lc, valid);
      valid && subject.current && subject.current.next(lc);
    },
    [processEmail],
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  useEffect(() => {
    if (isString(emailMessage)) {
      if (registerMode) {
        if (!emailMessage.includes("is an email")) {
          setErrorMessage(emailMessage);
        } else {
          setErrorMessage(undefined);
        }
      } else if (
        !emailMessage.includes("already exists") &&
        !emailMessage.includes("is an email")
      ) {
        setErrorMessage(emailMessage);
      } else {
        setErrorMessage(undefined);
      }
    } else {
      setErrorMessage(undefined);
    }
  }, [emailMessage, registerMode]);
  return (
    <Input
      errorStyle={errorStyle}
      inputStyle={input}
      keyboardType={"email-address"}
      labelStyle={inputLabel}
      value={email}
      label={label}
      disabled={disabled}
      autoCapitalize={"none"}
      autoFocus={true}
      errorMessage={errorMessage}
      placeholder={placeholder}
      onChangeText={onChangeText}
      inputContainerStyle={inputContainer}
      ref={ref}
    />
  );
};
