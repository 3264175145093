import { useCallback } from "react";
import { NavigationProp, NavigatorScreenParams, ParamListBase, useLinkTo, useNavigation } from "@react-navigation/native"
import { RootStackParamList, RootTabParamList } from "../AppMain/AppNavigationContainer/RootStackParamList";
import { isWeb } from "../../isWeb";
import { useReactDom } from "../../useReactDom";
import { navigateViaReactDom } from "./navigateViaReactDom";
import { ScreenNames } from "../AppMain/AppNavigationContainer/ScreenNames";
// import { NavigationParams } from "../../AppMain/AppNavigationContainer/NavigationParams";

const screen = ScreenNames.Root;
export const useNavigateToRootPage = () => {
    const navigation = useNavigation<NavigationProp<RootStackParamList>>();
    const linkTo = useLinkTo<RootStackParamList>();
    const navigateToRootScreen = useCallback(() => {
        const params: NavigatorScreenParams<RootTabParamList> | undefined = undefined;
        if (isWeb()) {
          linkTo({ screen, params });
        } else if (!useReactDom()) {
          navigation.navigate(screen, params);
        } else {
            navigateViaReactDom(navigation, screen, params);
        }
      }, [navigation, linkTo]);
      return navigateToRootScreen;
}
/*
    const navigateToRootScreen = useCallback(() => {
        const params: NavigationParams = {};
        if (isWeb()) {
          linkTo({ screen, params });
        } else if (!useReactDom()) {
          navigation.navigate(screen, params);
        } else {
            navigateViaReactDom(navigation, screen, params);
        }
      }, [navigation, linkTo]);
*/