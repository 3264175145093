import FSearchCenter from "../../Classes/Models/FSearchCenter";
import {AuthDoc} from "../../Interfaces/AuthDoc";
import {FSearchCenterInterface} from "../../Interfaces/Models/FSearchCenterInterface";
import {ModelFields} from "../../Interfaces/Models/ModelFields";
import {ModelTypes} from "../../Interfaces/Models/ModelTypes";
import {OrderStatus} from "../../Interfaces/Models/OrderStatus";
import {FBQueryDoc} from "../../Interfaces/Query/FBQueryDoc";
import {FBWhere} from "../../Interfaces/Query/FBWhere";
import {FBWhereOperators} from "../../Interfaces/Query/FBWhereOperators";
import {QueryParamsDoc} from "../../Interfaces/Query/QueryParamsDoc";
import {ServerParamsDoc} from "../../Interfaces/ServerParamsDoc";
import {isFBaseModelDoc} from "../../is/isFBaseModelDoc";
import {isNumber} from "../../is/isNumber";
import {isString} from "../../is/isString";
import {createAlgoliaParamsDoc} from "./Algolia/createAlgoliaParamsDoc";
import WhereClass from "./WhereClass";

export const queryParamsToQueryDoc = (
  _authDoc: AuthDoc | null,
  queryParamsDoc: QueryParamsDoc,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _serverParamsDoc: ServerParamsDoc,
): FBQueryDoc => {
  // console.log("QueryParamsDoc", queryParamsDoc);
  const {
    business,
    customer,
    owner,
    modelType,
    queryText,
    submitted,
    visible,
    limit,
    sortObjects,
    page,
    cursor,
    limitToLast,
    parent,
    algoliaIndexBase,
    searchCenterDoc,
    searchCenter,
    locationName,
    sender,
    room,
    target,
    follower,
    following,
    order,
    cancelled,
    recipient,
    orderStatus,
    areaCode,
    prefix,
    suffix,
    editing,
    creating,
    algoliaSortObject,
    providers,
    assignmentMode,
    searchableStartTime,
    published,
    imageType,
    album,
    superParent,
    series,
    provider,
    distributionTime,
    createdAt,
    paid,
    requestTime,
    invoice,
    primary,
  } = queryParamsDoc;
  const whereClass = new WhereClass()
    .mapDescriptorToWheres([ModelFields.series, ModelFields.fuid], series)
    .mapDescriptorToWheres([ModelFields.provider, ModelFields.fuid], provider)
    .mapDescriptorToWheres([ModelFields.business, ModelFields.fuid], business)
    .mapDescriptorToWheres([ModelFields.customer, ModelFields.fuid], customer)
    .mapDescriptorToWheres([ModelFields.invoice, ModelFields.fuid], invoice)
    .mapDescriptorToWheres([ModelFields.owner, ModelFields.fuid], owner)
    .mapDescriptorToWheres([ModelFields.order, ModelFields.fuid], order)
    .mapDescriptorToWheres([ModelFields.sender, ModelFields.fuid], sender)
    .mapDescriptorToWheres([ModelFields.room, ModelFields.fuid], room)
    .mapDescriptorToWheres([ModelFields.follower, ModelFields.fuid], follower)
    .mapDescriptorToWheres([ModelFields.target, ModelFields.fuid], target)
    .mapDescriptorToWheres([ModelFields.following, ModelFields.fuid], following)
    .mapDescriptorToWheres([ModelFields.recipient, ModelFields.fuid], recipient)
    .mapDescriptorToWheres([ModelFields.parent, ModelFields.fuid], parent)
    .mapDescriptorToWheres(
      [ModelFields.superParent, ModelFields.fuid],
      superParent,
    )
    .addToWheres([ModelFields.paid], FBWhereOperators.eq, paid)
    .addToWheres([ModelFields.album], FBWhereOperators.eq, album)
    .addToWheres([ModelFields.areaCode], FBWhereOperators.eq, areaCode)
    .addToWheres([ModelFields.prefix], FBWhereOperators.eq, prefix)
    .addToWheres([ModelFields.suffix], FBWhereOperators.eq, suffix)
    .addToWheres([ModelFields.imageType], FBWhereOperators.eq, imageType)
    .addToWheres([ModelFields.submitted], FBWhereOperators.eq, submitted)
    .addToWheres([ModelFields.visible], FBWhereOperators.eq, visible)
    .addToWheres([ModelFields.editing], FBWhereOperators.eq, editing)
    .addToWheres([ModelFields.creating], FBWhereOperators.eq, creating)
    .addToWheres([ModelFields.primary], FBWhereOperators.eq, primary)
    .addToWheres(
      [ModelFields.searchableStartTime],
      FBWhereOperators.lte,
      searchableStartTime,
    )
    .addToWheres([ModelFields.requestTime], FBWhereOperators.eq, requestTime)
    .addToWheres([ModelFields.createdAt], FBWhereOperators.lte, createdAt)
    .addArrayToWheres([ModelFields.providers, ModelFields.fuids], providers)
    .addToWheres([ModelFields.published], FBWhereOperators.eq, published)
    .handleAssignmentMode(assignmentMode)
    .handleAlgoliaSortObject(algoliaSortObject);
  if (
    isString(orderStatus) &&
    orderStatus === OrderStatus.cancelled &&
    modelType === ModelTypes.Order
  ) {
    whereClass
      .addToWheres([ModelFields.orderStatus], FBWhereOperators.eq, orderStatus)
      .addToWheres([ModelFields.cancelled], FBWhereOperators.eq, true);
  } else {
    whereClass
      .addToWheres([ModelFields.orderStatus], FBWhereOperators.eq, orderStatus)
      .addToWheres([ModelFields.cancelled], FBWhereOperators.eq, cancelled);
  }
  const wheres: FBWhere[] = whereClass.wheres;
  if (isNumber(distributionTime)) {
    wheres.push({
      path: [ModelFields.distributionTime],
      value: distributionTime,
      operator: FBWhereOperators.lte,
    });
  }
  let scrubbedText = "";
  if (isString(queryText)) {
    scrubbedText = queryText;
  } else if (Array.isArray(queryText) && queryText.length > 0) {
    scrubbedText = queryText[0] || "";
  }
  const queryDoc: FBQueryDoc = {
    wheres,
    sortObjects,
    limit,
    modelType,
    queryText: scrubbedText,
    page,
    cursor: cursor === null ? undefined : cursor,
    limitToLast,
    algoliaIndexBase,
    locationName,
  };
  const scrubbedSearchCenter: FSearchCenterInterface | null = isFBaseModelDoc(
    searchCenterDoc,
  )
    ? new FSearchCenter(searchCenterDoc)
    : searchCenter;
  if (scrubbedSearchCenter && scrubbedSearchCenter instanceof FSearchCenter) {
    const {latitude, longitude, searchRadiusInMiles} = scrubbedSearchCenter;
    if (
      isNumber(latitude) &&
      isNumber(longitude) &&
      isNumber(searchRadiusInMiles)
    ) {
      queryDoc.latitude = latitude;
      queryDoc.longitude = longitude;
      queryDoc.radiusInMiles = searchRadiusInMiles;
    }
  }
  queryDoc.algoliaSortObject = algoliaSortObject;
  queryDoc.algoliaParamsDoc = createAlgoliaParamsDoc(queryDoc);
  // console.log('AlgoliaParams', queryDoc.algoliaParamsDoc)
  return queryDoc;
};
