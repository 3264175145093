import FError from "../Common/ErrorHandling/FError";
import {ResponseCode} from "../Common/ErrorHandling/ResponseCode";
import {AuthDoc} from "../Common/Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import {ListQueryRequestDoc} from "../Common/Interfaces/ServerDocs/ListQueryRequestDoc";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import {isFBaseModelDoc} from "../Common/is/isFBaseModelDoc";
import {
  FBQuerySnapshot,
  FBQueryDocumentSnapshot,
} from "../Mapping/firebaseMapping";
import {innerGetDocs} from "./innerGetDocs2";
import { processFBListError } from "./processFBListError";

export const getList = async (
  _authDoc: AuthDoc | null,
  requestDoc: ListQueryRequestDoc,
  serverParams: ServerParamsDoc,
): Promise<FBaseModelDoc[]> => {
  const {
    subDescriptor: {modelPath},
    queryDoc,
  } = requestDoc;
  return innerGetDocs(modelPath, queryDoc, serverParams)
    .then((snapshot: FBQuerySnapshot) => {
      const scrubbed: FBaseModelDoc[] = [];
      if (!snapshot.empty) {
        snapshot.forEach((fbDocument: FBQueryDocumentSnapshot) => {
          if (fbDocument.exists) {
            const doc = fbDocument.data();
            if (isFBaseModelDoc(doc)) {
              scrubbed.push(doc);
            } else {
              console.log(`Document isnt BaseModelDoc ${JSON.stringify(doc)}`);
            }
          } else {
            console.log("document doesnt exists");
          }
        });
      }
      return scrubbed;
    })
    .catch(error => {
      processFBListError(error, requestDoc);
      console.log("Error in innerGetDocs with requestDoc", requestDoc);
      throw FError.fbErrortoFError(
        error,
        "FBActions.getList",
        ResponseCode.InternalServerError,
      );
    });
};
