import {AxiosMethodTypes} from "./AxiosMethodTypes";
import {ServerMethods} from "../ServerMethods";
import {ServerMethodObj} from "./ServerMethodObj";
import {ServerPrefix} from "./ServerPrefix";

export const ServerMethodsObj: {[property: string]: ServerMethodObj} = {
  [ServerMethods.addOrRemoveBusinessPerson]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: "/addorremovebusinessperson",
  },
  [ServerMethods.businessPersonAtDomain]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: "/businesspersonatdomain",
  },
  [ServerMethods.businessPersonAtBusiness]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.businessPersonAtBusiness}`,
  },
  [ServerMethods.cancelOrder]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.order,
    suffix: "/cancelorder",
  },
  [ServerMethods.createDocument]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.none,
    suffix: "",
  },
  [ServerMethods.createPhoneNumber]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.phoneNunber,
    suffix: "/create",
  },
  [ServerMethods.updatePhoneNumberTextEnabled]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.phoneNunber,
    suffix: `/${ServerMethods.updatePhoneNumberTextEnabled}`,
  },
  [ServerMethods.deleteDocument]: {
    verb: AxiosMethodTypes.put,
    prefix: ServerPrefix.none,
    suffix: "/delete",
  },
  [ServerMethods.getDocument]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.none,
    suffix: "/getdocument",
  },
  [ServerMethods.getListOfPhoneNumbers]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.phoneNunber,
    suffix: "/getlistofphonenumbers",
  },
  [ServerMethods.getOrCreateBusinessRoom]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.none,
    suffix: "/getorcreatebusinessroom",
  },
  [ServerMethods.updateProfileName]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.profile,
    suffix: `/${ServerMethods.updateProfileName}`,
  },
  [ServerMethods.getOrCreateFollow]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.none,
    suffix: "/getorcreatefollow",
  },
  [ServerMethods.getOrCreateMyProfile]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.profile,
    suffix: "/getorcreatemyprofile",
  },
  [ServerMethods.updateProfileName]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.profile,
    suffix: `/${ServerMethods.updateProfileName}`,
  },
  [ServerMethods.getOrCreateNewBusiness]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: "/getorcreatenewbusiness",
  },
  [ServerMethods.getOrCreatePrivateRoom]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.none,
    suffix: "/getorcreateprivateroom",
  },
  [ServerMethods.getOrCreateProductByBusiness]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.product,
    suffix: "/getorcreateproductbeingedited",
  },
  [ServerMethods.getOrCreateUnsubmittedOrder]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.order,
    suffix: "/getorcreateunsubmittedorder",
  },
  [ServerMethods.initializeServer]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.admin,
    suffix: "/initialize",
  },
  [ServerMethods.lookupBusinessByDomain]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: "/lookupbydomain",
  },
  [ServerMethods.lookupDisplayName]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.none,
    suffix: "/lookupdisplayname",
  },
  [ServerMethods.lookupEmail]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.auth,
    suffix: "/lookupemail",
  },
  [ServerMethods.lookupSearchCenter]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.location,
    suffix: "/lookupsearchcenter",
  },
  [ServerMethods.lookupUserProfileViaUID]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.auth,
    suffix: "/lookupuserprofileviauid",
  },
  [ServerMethods.lookupUsersBusinessByRoleForABusiness]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: "/lookupUsersBusinessByRoleForABusiness",
  },
  [ServerMethods.lookupUsersBusinessesByRole]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.none,
    suffix: "/lookupusersbusinessesbyrole",
  },
  [ServerMethods.getUsersBusinessesByRole]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.none,
    suffix: "/getusersbusinessesbyrole",
  },
  [ServerMethods.publishOrUnpublishBusiness]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: "/publishorunpublishbusiness",
  },
  [ServerMethods.publishProduct]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.product,
    suffix: "/publishproduct",
  },
  [ServerMethods.registerUser]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.auth,
    suffix: "/registeruser",
  },
  [ServerMethods.removeOrderProductFromOrder]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.order,
    suffix: "/removeorderproduct",
  },
  [ServerMethods.sendMessage]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.none,
    suffix: "/sendmessage",
  },
  [ServerMethods.submitOrder]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.order,
    suffix: "/submit",
  },
  [ServerMethods.updateBusinessDomain]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.none,
    suffix: "/updatebusinessdomain",
  },
  [ServerMethods.updateDisplayName]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.none,
    suffix: "/updatedisplayname",
  },
  [ServerMethods.updateLocation]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.location,
    suffix: "/update",
  },
  [ServerMethods.updateLocationName]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.location,
    suffix: "/updatename",
  },
  [ServerMethods.updateModel]: {
    verb: AxiosMethodTypes.put,
    prefix: ServerPrefix.none,
    suffix: "",
  },
  [ServerMethods.updateOrderLocation]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.order,
    suffix: "/updateorderlocation",
  },
  [ServerMethods.updateOrderProduct]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.order,
    suffix: "/updateorderproduct",
  },
  [ServerMethods.updateOrderProvider]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.order,
    suffix: "/updateorderprovider",
  },
  [ServerMethods.updateOrderStatus]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.order,
    suffix: "/updateorderstatus",
  },
  [ServerMethods.updateOrderTime]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.order,
    suffix: "/updateordertime",
  },
  [ServerMethods.updatePhoneNumber]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.phoneNunber,
    suffix: "/update",
  },
  [ServerMethods.rate]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.rating,
    suffix: `/${ServerMethods.rate}`,
  },
  [ServerMethods.getOrCreateRating]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.rating,
    suffix: `/${ServerMethods.getOrCreateRating}`,
  },
  [ServerMethods.listRatings]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.rating,
    suffix: `/${ServerMethods.listRatings}`,
  },
  [ServerMethods.changeListOrder]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.product,
    suffix: "/changelistorder",
  },
  [ServerMethods.getOrCreateAlbum]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.album,
    suffix: `/${ServerMethods.getOrCreateAlbum}`,
  },
  [ServerMethods.updateSeries]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.order,
    suffix: `/${ServerMethods.updateSeries}`,
  },
  [ServerMethods.addOrderProductToOrder]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.order,
    suffix: `/${ServerMethods.addOrderProductToOrder}`,
  },
  [ServerMethods.createStripePaymentIntent]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.stripe,
    suffix: `/${ServerMethods.createStripePaymentIntent}`,
  },
  [ServerMethods.createStripeBusinessAccount]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.stripe,
    suffix: `/${ServerMethods.createStripeBusinessAccount}`,
  },
  [ServerMethods.updateStripePaymentIntent]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.stripe,
    suffix: `/${ServerMethods.updateStripePaymentIntent}`,
  },
  [ServerMethods.updateOrderInvoice]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.stripe,
    suffix: `/${ServerMethods.updateOrderInvoice}`,
  },
  [ServerMethods.updateInvoiceTip]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.stripe,
    suffix: `/${ServerMethods.updateInvoiceTip}`,
  },
  [ServerMethods.updateBusinessName]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.updateBusinessName}`,
  },
  [ServerMethods.lookupDefaultBusiness]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.lookupDefaultBusiness}`,
  },
  [ServerMethods.updateBusinessDescription]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.updateBusinessDescription}`,
  },
  [ServerMethods.getListOfProducts]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.product,
    suffix: `/${ServerMethods.getListOfProducts}`,
  },
  [ServerMethods.updateProductFields]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.product,
    suffix: `/${ServerMethods.updateProductFields}`,
  },
  [ServerMethods.updateProductDescription]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.product,
    suffix: `/${ServerMethods.updateProductDescription}`,
  },
  [ServerMethods.updateProfileDescription]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.product,
    suffix: `/${ServerMethods.updateProfileDescription}`,
  },
  [ServerMethods.updateProfileDescription]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.product,
    suffix: `/${ServerMethods.updateProfileDescription}`,
  },
  [ServerMethods.updateDescription]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.updateDescription}`,
  },
  [ServerMethods.createNewProduct]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.product,
    suffix: `/${ServerMethods.createNewProduct}`,
  },
  [ServerMethods.updateProfileDescription]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.profile,
    suffix: `/${ServerMethods.updateProfileDescription}`,
  },
  [ServerMethods.getOrCreatePrivateBusinessInfo]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.getOrCreatePrivateBusinessInfo}`,
  },
  [ServerMethods.updatePrivateBusinessInfo]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.updatePrivateBusinessInfo}`,
  },
  [ServerMethods.updateBusinessPaymentOptions]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.updateBusinessDescription}`,
  },
  [ServerMethods.updateSelectedBusinessPerson]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.updateSelectedBusinessPerson}`,
  },
  [ServerMethods.getSelectedBusinessPerson]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.getSelectedBusinessPerson}`,
  },
  [ServerMethods.clearSelectedBusinessPerson]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.clearSelectedBusinessPerson}`,
  },
  [ServerMethods.getContentDoc]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.getContentDoc}`,
  },
  [ServerMethods.saveContent]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.saveContent}`,
  },
  [ServerMethods.saveContent]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.saveContent}`,
  },
  [ServerMethods.lookupPhoneNumber]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.admin,
    suffix: `/${ServerMethods.lookupPhoneNumber}`,
  },
  [ServerMethods.updateProfilePaymentOptions]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.profile,
    suffix: `/${ServerMethods.updateProfilePaymentOptions}`,
  },
  [ServerMethods.updateProfileVerified]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.profile,
    suffix: `/${ServerMethods.updateProfileVerified}`,
  },
  [ServerMethods.saveDriversLicense]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.profile,
    suffix: `/${ServerMethods.saveDriversLicense}`,
  },
  [ServerMethods.lookupDriversLicense]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.profile,
    suffix: `/${ServerMethods.lookupDriversLicense}`,
  },
  [ServerMethods.createOrUpdateMyRating]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.rating,
    suffix: `/${ServerMethods.createOrUpdateMyRating}`,
  },
  [ServerMethods.getMyRating]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.rating,
    suffix: `/${ServerMethods.getMyRating}`,
  },
  [ServerMethods.getRatingTally]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.rating,
    suffix: `/${ServerMethods.getRatingTally}`,
  },
  [ServerMethods.lookupProviderInfo]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.lookupProviderInfo}`,
  },
  [ServerMethods.updateProviderInfo]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.updateProviderInfo}`,
  },
  [ServerMethods.saveTaxForm]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.profile,
    suffix: `/${ServerMethods.saveTaxForm}`,
  },
  [ServerMethods.lookupTaxForm]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.profile,
    suffix: `/${ServerMethods.lookupTaxForm}`,
  },
  [ServerMethods.createProviderDistribution]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.order,
    suffix: `/${ServerMethods.createProviderDistribution}`,
  },
  [ServerMethods.sendVenmoRequest]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.order,
    suffix: `/${ServerMethods.sendVenmoRequest}`,
  },
  [ServerMethods.markAsFullfilled]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.order,
    suffix: `/${ServerMethods.markAsFullfilled}`,
  },
  [ServerMethods.createIndexReport]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.admin,
    suffix: `/${ServerMethods.createIndexReport}`,
  },
  [ServerMethods.createAxiosReport]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.admin,
    suffix: `/${ServerMethods.createAxiosReport}`,
  },
  [ServerMethods.createLoginReport]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.admin,
    suffix: `/${ServerMethods.createLoginReport}`,
  },
  [ServerMethods.updateProfileAvatar]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.profile,
    suffix: `/${ServerMethods.updateProfileAvatar}`,
  },
  [ServerMethods.updateBusinessLogo]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.updateBusinessLogo}`,
  },
  [ServerMethods.reportClientAppError]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.reportClientAppError}`,
  },
  [ServerMethods.reportClientStripeError]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.business,
    suffix: `/${ServerMethods.reportClientStripeError}`,
  },
  [ServerMethods.updateOrderRating]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.order,
    suffix: `/${ServerMethods.updateOrderRating}`,
  },
  [ServerMethods.createStripeCustomer]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.stripe,
    suffix: `/${ServerMethods.createStripeCustomer}`,
  },
  [ServerMethods.reportClientLinkError]: {
    verb: AxiosMethodTypes.post,
    prefix: ServerPrefix.error,
    suffix: `/${ServerMethods.reportClientLinkError}`,
  },
};
