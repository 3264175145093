import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import {isAnyProperty} from "../Common/is/isAnyProperty";
import {FBQueryDocumentSnapshot} from "../Mapping/firebaseMapping";

export const fbDocSnapshotToFBaseModelDoc = (
  fbDocument: FBQueryDocumentSnapshot,
): FBaseModelDoc | null => {
  if (fbDocument.exists) {
    const doc = fbDocument.data();
    if (isAnyProperty(doc)) {
      return doc as FBaseModelDoc;
    }
  }
  return null;
};
