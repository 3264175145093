import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import {ServerMethods} from "../Common/ServerMethods";
import {ServerMethodsObj} from "../Common/ServerMethodsObj";

export const getPath = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  serverMethod: ServerMethods,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _serverParamsDoc: ServerParamsDoc,
): string | null => {
  const obj = ServerMethodsObj[serverMethod];
  if (obj) {
    const {prefix, suffix} = obj;
    return `${prefix}${suffix}`;
  }
  return null;
};
