import {AnyProperty} from "../Common/Interfaces/AnyProperty";
import {isAnyProperty} from "../Common/is/isAnyProperty";
import {isFDescriptorDoc} from "../Common/is/isFDescriptorDoc";
import {AxiosRequestInterface} from "./AxiosRequestInterface";

export const extractGetDocumentParams = (
  path: string,
  request: AxiosRequestInterface,
): string => {
  let result = "";
  if (path === "/getdocument") {
    const {postPayload} = request;
    const p = postPayload as unknown as AnyProperty;
    const {payload} = p;
    if (isAnyProperty(payload)) {
      const {requestDoc} = payload;
      if (isAnyProperty(requestDoc)) {
        const {descriptorDoc} = requestDoc;
        if (isFDescriptorDoc(descriptorDoc)) {
          const {modelType, fuid} = descriptorDoc;
          result = `${modelType} ${fuid}`;
        } else {
          console.log("Dont have descriptorDoc ", payload);
        }
      } else {
        console.log("Request Doc not anyproperty", requestDoc);
      }
    } else {
      console.log("Payload not anyproperty", p);
    }
  }
  return result;
};
