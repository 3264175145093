import { ModelFields } from "../../Common/Interfaces/Models/ModelFields";
import {ModelTypes} from "../../Common/Interfaces/Models/ModelTypes";
import { FBSortOrder } from "../../Common/Interfaces/Query/FBSortOrder";
import {
  QueryParamsDoc,
  defaultQueryParamsDoc,
  QueryDB,
} from "../../Common/Interfaces/Query/QueryParamsDoc";

export const defaultSearchCenterQueryParamsDoc = (): QueryParamsDoc => {
  const queryParamsDoc: QueryParamsDoc = defaultQueryParamsDoc();
  queryParamsDoc.modelType = ModelTypes.SearchCenter;
  queryParamsDoc.algoliaIndexBase = null;
  queryParamsDoc.queryDB = QueryDB.firebase;
  queryParamsDoc.sortObjects = [
    {
      path: [ModelFields.createdAt],
      order: FBSortOrder.desc,
    },
  ],
  queryParamsDoc.visible = true;
  delete queryParamsDoc.limit;
  //queryParamsDoc.limit = 100;
  return queryParamsDoc;
};
