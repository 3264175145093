export enum OrderStatus {
  unknown = "unknown",
  unsubmitted = "unsubmitted",
  requested = "requested",
  accepted = "accepted",
  ontheway = "ontheway",
  arrived = "arrived",
  completed = "completed",
  cancelled = "cancelled",
  updateAddress = "updateAddress",
}
