import React, {FC} from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {Provider as JotaiProvider} from "jotai";
import { AppThemeProvider } from "./AppThemeProvider";
import { AppNavigationContainer } from "./AppNavigationContainer";
import { ReportIndexListener } from "../Services/ReportIndexListener";
const queryClient = new QueryClient();
export const AppMain: FC = () => {
  return (
      <AppThemeProvider>
        <ReportIndexListener />
        <QueryClientProvider client={queryClient}>
          <JotaiProvider>
            <AppNavigationContainer />
          </JotaiProvider>
        </QueryClientProvider>
      </AppThemeProvider>
  )
}

