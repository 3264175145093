import React from "react";
import { FBaseModelDoc } from "../../Common/Interfaces/BaseDocs/FBaseModelDoc";
import { FBAuthUser } from "../../Mapping/firebaseMapping";

export interface AuthContextInterface {
  uid: string | null;
  user: FBAuthUser | null;
  myProfile: FBaseModelDoc | null;
}
const defaultContext = {
  uid: null,
  user: null,
  myProfile: null,
};

const AuthContext = React.createContext<AuthContextInterface>({
  ...defaultContext,
});
export default AuthContext;
