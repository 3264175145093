/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {AuthDoc} from "../Common/Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import {
  FBQueryDocumentSnapshot,
  FBQuerySnapshot,
} from "../Mapping/firebaseMapping";
import {getOrCreateMyProfile} from "./getOrCreateMyProfile";
import {lookupEmail} from "./lookupEmail";
import {registerUser} from "./registerUser";
import {updateModel} from "./updateModel";
import {handleCreateDocument} from "./handleCreateDocument";
import {removeDocument} from "./removeDocument";
import {lookupBusinessByDomain} from "./lookupBusinessByDomain";
import {innerGetDocs} from "./innerGetDocs2";
import {getList} from "./getList";
import {addOrRemoveBusinessPerson} from "./addOrRemoveBusinessPerson";
import {getOrCreateUnsubmittedOrder} from "./getOrCreateUnsubmittedOrder";
import {CreateDocumentRequestDoc} from "../Common/Interfaces/ServerDocs/CreateDocumentRequestDoc";
import {ListQueryRequestDoc} from "../Common/Interfaces/ServerDocs/ListQueryRequestDoc";
import {updateLocation} from "./updateLocation";
import {unblockPhoneNumber} from "./unblockPhoneNumber";
import {updateBusinessDomain} from "./updateBusinessDomain";
import {lookupDisplayName} from "./lookupDisplayName";
import {updateDisplayName} from "./updateDisplayName";
import {sendMessage} from "./sendMessage";
import FError from "../Common/ErrorHandling/FError";
import {ResponseCode} from "../Common/ErrorHandling/ResponseCode";
import {getOrCreateFollow} from "./getOrCreateFollow";
import {getOrCreateBusinessRoom} from "./getOrCreateBusinessRoom";
import {getOrCreatePrivateRoom} from "./getOrCreatePrivateRoom";
import {lookupUsersBusinessesByRole} from "./lookupUsersBusinessesByRole";
import {lookupUserProfileViaUID} from "./lookupUserProfileViaUID";
import {lookupSearchCenter} from "./lookupSearchCenter";
import {updateLocationName} from "./updateLocationName";
import {createPhoneNumber} from "../FBOrderActions/PhoneNumber/createPhoneNumber";
import {updatePhoneNumber} from "../FBOrderActions/PhoneNumber/updatePhoneNumber";
import {updatePhoneNumberTextEnabled} from "../FBOrderActions/PhoneNumber/updatePhoneNumberTextEnabled";
import {lookupUsersBusinessByRoleForABusiness} from "./lookupUsersBusinessByRoleForABusiness";
import {getOrCreateProductByBusiness} from "./getOrCreateProductByBusiness";
import {publishProduct} from "./publishProduct";
import {getOrCreateNewBusiness} from "./getOrCreateNewBusiness";
import {publishOrUnpublishBusiness} from "./publishOrUnpublishBusiness";
import {createNewOrderFromExistingOrder} from "./createNewOrderFromExistingOrder";
import {lookupBusinessPersonAtDomain} from "./lookupBusinessPersonAtDomain";
import {getDocument} from "./getDocument";
import {getListOfPhoneNumbers} from "./getListOfPhoneNumbers";
import {changeListOrder} from "./changeListOrder";
import {rate} from "./rate";
import {getOrCreateRating} from "./getOrCreateRating";
import {listRatings} from "./listRatings";
import {getOrCreateAlbum} from "./getOrCreateAlbum";
import {getUsersBusinessesByRole} from "./getUsersBusinessesByRole";
import {updateOrderSeries} from "./updateOrderSeries";
import {createStripePaymentIntent} from "./createStripePaymentIntent";
import {createStripeBusinessAccount} from "./createStripeBusinessAccount";
import {updateStripePaymentIntent} from "./updateStripePaymentIntent";
import {updateOrderInvoice} from "./updateOrderInvoice";
import {updateInvoiceTip} from "./updateInvoiceTip";
import {updateBusinessName} from "./updateBusinessName";
import {lookupDefaultBusiness} from "./lookupDefaultBusiness";
import {updateDocument} from "./updateDocument";
import {updateDescription} from "./updateDescription";
import {getListOfProducts} from "./getListOfProducts";
import {updateProductFields} from "./updateProductFields";
import {lookupBusinessPersonAtBusiness} from "./lookupBusinessPersonAtBusiness";
import {updateProfileName} from "./updateProfileName";
import {updateProductDescription} from "./updateProductDescription";
import {updateProfileDescription} from "./updateProfileDescription";
import {getListOfSnapshots2} from "./getListOfSnapshots2";
import {FBCancelTokenSource} from "../Mapping/axiosMapping";
import {createNewProduct} from "./createNewProduct";
import {getOrCreatePrivateBusinessInfo} from "./getOrCreatePrivateBusinessInfo";
import {updatePrivateBusinessInfo} from "./updatePrivateBusinessInfo";
import {updateBusinessPaymentOptions} from "./updateBusinessPaymentOptions";
import {getMySelectedBusinessPerson} from "./getMySelectedBusinessPerson";
import {updateMySelectedBusinessPerson} from "./updateMySelectedBusinessPerson";
import {clearMySelectedBusinessPerson} from "./clearMySelectedBusinessPerson";
import {updateBusinessDescription} from "./updateBusinessDescription";
import {saveContent} from "./saveContent";
import {getContentDoc} from "./getContentDoc";
import {lookupUserPhoneNumber} from "./lookupUserPhoneNumber";
import {saveDriversLicense} from "./saveDriversLicense";
import {updateProfilePaymentOptions} from "./updateProfilePaymentOptions";
import {lookupDriversLicense} from "./lookupDriversLicense";
import {createOrUpdateMyRating} from "./createOrUpdateMyRating";
import {getMyRating} from "./getMyRating";
import {getRatingTally} from "./getRatingTally";
import {lookupProviderInfo} from "./lookupProviderInfo";
import {updateProviderInfo} from "./updateProviderInfo";
import {lookupTaxForm} from "./lookupTaxForm";
import {saveTaxForm} from "./saveTaxForm";
import {createProviderDistribution} from "./createProviderDistribution";
import {sendVenmoRequest} from "./sendVenmoRequest";
import {markAsFullfilled} from "./markAsFullfilled";
import {createIndexReport} from "./createIndexReport";
import {createAxiosReport} from "./createAxiosReport";
import {createLoginReport} from "./createLoginReport";
import { updateProfileAvatar } from "./updateProfileAvatar";
import { updateBusinessLogo } from "./updateBusinessLogo";
import { reportClientAppError } from "./reportClientAppError";
import { reportClientStripeError } from "./reportClientStripeError";
import { createOrUpdateOrderRating } from "./createOrUpdateOrderRating";
import { reportClientLinkError } from "./reportClientLinkError";

export const fbActions = {
  getListOfSnapshots2,
  getListOfSnapshots: async (
    _authDoc: AuthDoc | null,
    requestDoc: ListQueryRequestDoc,
    serverParams: ServerParamsDoc,
  ): Promise<FBQueryDocumentSnapshot[]> => {
    const {
      subDescriptor: {modelPath},
      queryDoc,
    } = requestDoc;
    return innerGetDocs(modelPath, queryDoc, serverParams)
      .then((snapshot: FBQuerySnapshot) => {
        const scrubbed: FBQueryDocumentSnapshot[] = [];
        if (!snapshot.empty) {
          snapshot.forEach((fbDocument: FBQueryDocumentSnapshot) => {
            if (fbDocument.exists) {
              scrubbed.push(fbDocument);
            }
          });
        }
        return scrubbed;
      })
      .catch(error => {
        const method = "fbActions.getListOfSnapshots";
        throw FError.fbErrortoFError(
          error,
          method,
          ResponseCode.InternalServerError,
        );
      });
  },
  createDocument: async (
    authDoc: AuthDoc | null,
    requestDoc: CreateDocumentRequestDoc,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    return handleCreateDocument(
      authDoc,
      requestDoc,
      serverParamsDoc,
      cancelTokenSource,
    );
  },
  updateDocument,
  getDocument,
  removeDocument,
  addOrRemoveBusinessPerson,
  getList,
  getOrCreateMyProfile,
  lookupEmail,
  registerUser,
  updateModel,
  lookupBusinessByDomain,
  getOrCreateUnsubmittedOrder,
  updateLocation,
  unblockPhoneNumber,
  updateBusinessDomain,
  lookupDisplayName,
  updateDisplayName,
  sendMessage,
  getOrCreateFollow,
  getOrCreateBusinessRoom,
  getOrCreatePrivateRoom,
  lookupUsersBusinessesByRole,
  lookupUserProfileViaUID,
  lookupSearchCenter,
  updateLocationName,
  createPhoneNumber,
  updatePhoneNumber,
  updatePhoneNumberTextEnabled,
  lookupUsersBusinessByRoleForABusiness,
  getOrCreateProductByBusiness,
  publishProduct,
  getOrCreateNewBusiness,
  publishOrUnpublishBusiness,
  createNewOrderFromExistingOrder,
  lookupBusinessPersonAtDomain,
  getListOfPhoneNumbers,
  changeListOrder,
  rate,
  getOrCreateRating,
  listRatings,
  getOrCreateAlbum,
  getUsersBusinessesByRole,
  updateOrderSeries,
  createStripePaymentIntent,
  createStripeBusinessAccount,
  updateStripePaymentIntent,
  updateOrderInvoice,
  updateInvoiceTip,
  updateBusinessName,
  lookupDefaultBusiness,
  updateDescription,
  getListOfProducts,
  updateProductFields,
  lookupBusinessPersonAtBusiness,
  updateProfileName,
  updateProductDescription,
  updateProfileDescription,
  createNewProduct,
  getOrCreatePrivateBusinessInfo,
  updatePrivateBusinessInfo,
  updateBusinessPaymentOptions,
  getMySelectedBusinessPerson,
  updateMySelectedBusinessPerson,
  clearMySelectedBusinessPerson,
  updateBusinessDescription,
  saveContent,
  getContentDoc,
  lookupUserPhoneNumber,
  saveDriversLicense,
  updateProfilePaymentOptions,
  lookupDriversLicense,
  createOrUpdateMyRating,
  getMyRating,
  getRatingTally,
  lookupProviderInfo,
  updateProviderInfo,
  lookupTaxForm,
  saveTaxForm,
  createProviderDistribution,
  sendVenmoRequest,
  markAsFullfilled,
  createIndexReport,
  createAxiosReport,
  createLoginReport,
  updateProfileAvatar,
  updateBusinessLogo,
  reportClientAppError,
  reportClientStripeError,
  createOrUpdateOrderRating,
  reportClientLinkError,
};
