import {FGeolocationDocObj} from "../../Interfaces/Location/FGeolocationDocObj";
import {FLatLngDoc} from "../../Interfaces/Location/FLatLngDoc";

export const mapFGeolocationDocObjToFLatLngDocs = (
  locations: FGeolocationDocObj,
): FLatLngDoc[] => {
  const geolocs: FLatLngDoc[] = [];
  Object.keys(locations).reduce((acc, key) => {
    const location = locations[key];
    if (location) {
      const {latlngLiteral, placeId, formattedAddress} = location;
      if (latlngLiteral) {
        const {lat, lng} = latlngLiteral;
        const geoloc: FLatLngDoc = {
          lat,
          lng,
          placeId: placeId || null,
          formattedAddress: formattedAddress || null,
          accuracy: null,
          locationName: key,
        };
        geolocs.push(geoloc);
      }
    }
    return acc;
  }, geolocs);
  return geolocs;
};
