import React, {FC, type PropsWithChildren} from "react";
import {CustomLoadingButton} from "../../../Custom/CustomLoadingButton";
import {CustomRow} from "../../../Custom/CustomRow";
import { ThemedView } from "../../../ThemedComponents/ThemedView";
import {useStyles} from "./useStyles";

interface LoginOrRegisterButtonProps extends PropsWithChildren {
  registerMode: boolean;
  enabled: boolean;
  loginOnServer: () => void;
  loading: boolean;
}
const loginTitle = "Log In";
const registerTitle = "Register";
export const LoginOrRegisterButton: FC<LoginOrRegisterButtonProps> = props => {
  const {registerMode, enabled, loginOnServer, loading} = props;
  const {button, buttonWidth} = useStyles();

  return (
    <CustomRow width100={true} justifyContent={"center"}>
      <ThemedView style={buttonWidth}>
        <CustomLoadingButton
          loading={loading}
          buttonStyle={button}
          title={registerMode ? registerTitle : loginTitle}
          onPress={loginOnServer}
          disabled={!enabled}
          width={160}
        />
      </ThemedView>
    </CustomRow>
  );
};
