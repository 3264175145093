import React, {FC, type PropsWithChildren} from "react";
import {ActivityIndicator} from "react-native";
import {CustomColumn} from "../../Custom/CustomColumn";
import { CustomRNEButton } from "../../Custom/CustomRNEButton";
import {CustomRow} from "../../Custom/CustomRow";
import {CustomText} from "../../Custom/CustomText";
import { useNavigateToRootPage } from "../../useNavigate/useNavigateToRootPage";
import {useStyles} from "./useStyles";

interface LoadingScreenProps extends PropsWithChildren {
  title: string;
  showHomeButton?: boolean;
}
export const LoadingScreen: FC<LoadingScreenProps> = props => {
  const {title, showHomeButton = false} = props;
  const {marginTop, homeButton, height100} = useStyles();
  const navigateToHomePage = useNavigateToRootPage();
  return (
    <CustomRow width100={true} justifyContent={"center"} style={height100}>
      <CustomColumn width100={true} justifyContent={"center"}>
        <CustomRow width100={true} justifyContent={"center"}>
          <ActivityIndicator size="large" />
        </CustomRow>
        <CustomRow width100={true} justifyContent={"center"} style={marginTop}>
          <CustomText type="h3">{title}</CustomText>
        </CustomRow>
        {showHomeButton && <CustomRow width100={true} justifyContent={"center"} style={homeButton}>
          <CustomRNEButton title="Go to Home" onPress={navigateToHomePage} />
        </CustomRow>}
      </CustomColumn>
    </CustomRow>
  );
};
