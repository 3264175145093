import React, { FC, useEffect, useState, type PropsWithChildren } from "react";
import { ThemedView, ThemedViewProps } from "../../ThemedComponents/ThemedView";
import { useStyles } from "./useStyles";

interface CustomRowInterface {
  justifyContent?: "center" | "flex-start" | "flex-end" | null;
  width100?: boolean;
  row: boolean;
  style?: ThemedViewProps | ThemedViewProps[];
}
const method = "In CustomContainerPrimative";
export const CustomContainerPrimative: FC<
  PropsWithChildren<CustomRowInterface>
> = props => {
  const styles = useStyles();
  const {
    justifyContent,
    width100,
    row,
    children,
    style: incomingStyle,
  } = props;
  const [style, setStyle] = useState<ThemedViewProps[] | undefined>([]);
  useEffect(() => {
    const array: ThemedViewProps[] = row ? [styles.row] : [styles.column];
    if (justifyContent === "center") {
      array.push(styles.justifyCenter);
    }
    if (justifyContent === "flex-end") {
      array.push(styles.justifyEnd);
    }
    if (justifyContent === "flex-start") {
      array.push(styles.justifyStart);
    }
    if (width100) {
      array.push(styles.width100);
    }
    if (Array.isArray(incomingStyle)) {
      if (incomingStyle.length > 0) {
        incomingStyle.map(item => {
          if (item !== undefined && item !== null && item) {
            array.push(item as unknown as ThemedViewProps);
          }
          return false;
        });
      }
    } else if (incomingStyle && incomingStyle !== null && incomingStyle !== undefined) {
      array.push(incomingStyle);
    }
    setStyle(array);
  }, [justifyContent, row, styles, width100, incomingStyle]);
  return <ThemedView style={style}>{children}</ThemedView>;
};
