import { RNTextStyle, RNViewStyle, styleSheetCreate } from "../../../Mapping/styleSheetCreate";

const styleObj: {
    container: RNViewStyle,
    title: RNTextStyle,
    separator: RNViewStyle,
} = {
    container: {
        flex: 1,
        alignContent: "center",
        marginTop: 10,
      },
      title: {
        fontSize: 20,
        fontWeight: 'bold',
      },
      separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
      },
    }
const styles = styleSheetCreate(styleObj);
export const useStyles = () => styles;
  