import {AlgoliaParamsDoc} from "../../../Interfaces/Query/algoliaParamsDoc";
import {FBQueryDoc} from "../../../Interfaces/Query/FBQueryDoc";
import {createAlgoliaFilter} from "./createAlgoliaFilter";
import {createAlgoliaLatLng} from "./createAlgoliaLatLng";
import {createAlgoliaRadius} from "./createAlgoliaRadius";

const defaultPage = 0;
const defaultHitsPerPage = 60;

export const createAlgoliaParamsDoc = (
  queryDoc: FBQueryDoc,
): AlgoliaParamsDoc => {
  const latlng = createAlgoliaLatLng(queryDoc);
  const {limit, page} = queryDoc;
  const algoliaParamsDoc: AlgoliaParamsDoc = {
    hitsPerPage: limit || defaultHitsPerPage,
    page: page || defaultPage,
    filters: createAlgoliaFilter(queryDoc),
  };
  if (latlng) {
    algoliaParamsDoc.aroundLatLng = latlng;
  }
  const radius = createAlgoliaRadius(queryDoc);
  if (radius) {
    algoliaParamsDoc.aroundRadius = radius;
  }
  return algoliaParamsDoc;
};
