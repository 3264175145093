import { Input } from "@rneui/themed";
import React, { FC, type PropsWithChildren, useCallback } from "react";
import { validateName } from "../../../../Common/Validations/validateName";
import { useCommonStyles } from "../../../CommonStyles/useCommonStyles";
import { CustomRow } from "../../../Custom/CustomRow";
import { ThemedView } from "../../../ThemedComponents/ThemedView";
import { NameState } from "./NameState";
import { useStyles } from "./useStyles";

interface NameInputProps extends PropsWithChildren {
  firstNameState: NameState;
  setFirstNameState: (state: NameState) => void;
  lastNameState: NameState;
  setLastNameState: (state: NameState) => void;
}
const disabled = false;
export const NameInput: FC<NameInputProps> = props => {
  const { firstNameState, lastNameState, setFirstNameState, setLastNameState } =
    props;
  const {
    error: errorStyle,
    input,
    inputContainer,
    inputLabel,
  } = useCommonStyles();
  const onFirstNameChange = useCallback(
    (newValue: string) => {
      const { scrubbed, valid } = validateName(newValue);
      setFirstNameState({
        name: scrubbed,
        nameValid: valid,
        nameMessage: valid ? undefined : "Too short",
      });
    },
    [setFirstNameState],
  );
  const onLastNameChange = useCallback(
    (newValue: string) => {
      const { scrubbed, valid } = validateName(newValue);
      setLastNameState({
        name: scrubbed,
        nameValid: valid,
        nameMessage: valid ? undefined : "Too short",
      });
    },
    [setLastNameState],
  );
  const { firstName, lastName } = useStyles();
  return (
    <CustomRow>
      <ThemedView style={firstName}>
        <Input
          errorStyle={errorStyle}
          inputStyle={input}
          keyboardType={"default"}
          labelStyle={inputLabel}
          value={firstNameState.name}
          label={"First Name"}
          disabled={disabled}
          autoCapitalize={"none"}
          autoFocus={true}
          errorMessage={firstNameState.nameMessage}
          placeholder={"Pat"}
          onChangeText={onFirstNameChange}
          inputContainerStyle={inputContainer}
        />
      </ThemedView>
      <ThemedView style={lastName}>
        <Input
          errorStyle={errorStyle}
          inputStyle={input}
          keyboardType={"default"}
          labelStyle={inputLabel}
          value={lastNameState.name}
          label={"Last Name"}
          disabled={disabled}
          autoCapitalize={"none"}
          autoFocus={false}
          errorMessage={lastNameState.nameMessage}
          placeholder={"Petunia"}
          onChangeText={onLastNameChange}
          inputContainerStyle={inputContainer}
        />
      </ThemedView>
    </CustomRow>
  );
};
