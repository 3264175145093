import {AuthDoc} from "../Common/Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import {UpdateLocationRequestDoc} from "../Common/Interfaces/ServerDocs/General/UpdateLocationRequestDoc";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import {fbClientActions} from "../FBClientActions/fbClientActions";
import {FBCancelTokenSource} from "../Mapping/axiosMapping";

export const updateLocation = async (
  authDoc: AuthDoc | null,
  requestDoc: UpdateLocationRequestDoc,
  serverParamsDoc: ServerParamsDoc,
  cancelTokenSource: FBCancelTokenSource,
): Promise<FBaseModelDoc[]> => {
  return fbClientActions.updateLocation(
    authDoc,
    requestDoc,
    serverParamsDoc,
    cancelTokenSource,
  );
};
