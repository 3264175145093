import React, { FC, type PropsWithChildren } from "react";
import { RNViewStyle } from "../../../Mapping/styleSheetCreate";
import { CustomContainerPrimative } from "../CustomContainerPrimative";

interface CustomColumnInterface {
  justifyContent?: "center" | "flex-start" | "flex-end" | null;
  width100?: boolean;
  style?: RNViewStyle | RNViewStyle[];
}
export const CustomColumn: FC<
  PropsWithChildren<CustomColumnInterface>
> = props => {
  return <CustomContainerPrimative {...props} row={false} />;
};
