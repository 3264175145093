import {
    NavigationProp,
    useLinkTo,
    useNavigation,
} from "@react-navigation/native";
import { To } from "@react-navigation/native/lib/typescript/src/useLinkTo";
import { useCallback } from "react";
import { NavigationParams } from "../AppMain/AppNavigationContainer/NavigationParams";
import { RootStackParamList } from "../AppMain/AppNavigationContainer/RootStackParamList";
import { ScreenNames } from "../AppMain/AppNavigationContainer/ScreenNames";
import { isWeb } from "../../isWeb";
import { navigateViaReactDom } from "./navigateViaReactDom";
import { useReactDom } from "../../useReactDom";
      
const screen = ScreenNames.LoginModal;
export const useNavigateToLoginModal = () => {
const navigation = useNavigation<NavigationProp<RootStackParamList>>();
const linkTo: (to: To<RootStackParamList>) => void =
    useLinkTo<RootStackParamList>();
const navigateToLoginModal = useCallback(
    () => {
        const params: NavigationParams = {};
        if (isWeb()) {
        linkTo({
            screen,
            params,
        });
    } else if (!useReactDom()) {
        navigation.navigate(screen, undefined);
        } else {
            navigateViaReactDom(navigation, screen, params);
        }
    },
    [linkTo, navigation],
);
return navigateToLoginModal;
};
      