import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import CancelError from "../Common/ErrorHandling/CancelError";
import TimeoutError from "../Common/ErrorHandling/TimeoutError";
import {AnyProperty} from "../Common/Interfaces/AnyProperty";
import {isAnyProperty} from "../Common/is/isAnyProperty";
import {isString} from "../Common/is/isString";
import {baseURL} from "../config/initialURL";
import {getIdToken} from "../firebase/authentication";
import {AxiosRequestInterface} from "./AxiosRequestInterface";
import {extractGetDocumentParams} from "./extractGetDocumentParams";
import {processAxiosError} from "./processAxiosError";

const indexMessage = "The query requires an index. You can";
const TIMEOUT_INTERVAL = 12000;

export const fAxios = async <T>(request: AxiosRequestInterface): Promise<T> => {
  try {
    const token = await getIdToken();
    const config: AxiosRequestConfig = {
      method: request.method,
      url: request.path,
      baseURL: request.baseURL || baseURL,
      headers: {Authorization: `Bearer ${token || ""}`},
      responseType: "json",
      data: request.postPayload || undefined,
      params: request.queryParams || undefined,
      timeout: TIMEOUT_INTERVAL,
      onUploadProgress: request.onUploadProgress || undefined,
      cancelToken: request.cancelTokenSource
        ? request.cancelTokenSource.token
        : undefined,
    };
    const response: AxiosResponse<T> = await axios(config);
    return response.data;
  } catch (error) {
    const {method, path, thisIsAnErrorMessage = false} = request;
    !thisIsAnErrorMessage &&
      processAxiosError(error, request as unknown as AnyProperty);
    if (axios.isCancel(error)) {
      //console.log(
      //  "Axios cancelled",
      //  request.path,
      //  `${extractGetDocumentParams(path, request)}`,
      // );
      const message = error instanceof Error ? error.message : "Cancelled";
      const cancelError = new CancelError({message});
      throw cancelError;
    } else if (axios.isAxiosError(error)) {
      const e = error as AxiosError;
      const {code} = e;
      if (isString(code) && (code === "ETIMEOUT" || code === "ECONNABORTED")) {
        console.log(
          `------- Have timeout error with code: ${code} for path: ${path} ${extractGetDocumentParams(
            path,
            request,
          )}`,
        );
        throw new TimeoutError({message: "timeout", code: "ETIMEOUT"});
      } else {
        console.log(
          `In FAxios Have AxiosError with code: [${code}] for method: ${method} at path ${path}`,
          e,
        );
        if (isAnyProperty(error)) {
          if (isAnyProperty(error.response)) {
            if (error.response.data) {
              const {data} = error.response;
              // console.log('Axios error.response Data is: ', data);
              if (isAnyProperty(data) || Array.isArray(data)) {
                const json = JSON.stringify(data);
                if (json.includes(indexMessage)) {
                  console.log("---- fAxios NEED INDEX (json)", data);
                }
                throw new Error(JSON.stringify(data));
              } else if (isString(data)) {
                if (data.includes(indexMessage)) {
                  console.log("---- fAxios NEED INDEX (data)", data);
                }
                throw new Error(data);
              } else {
                console.log("Unknown data type");
                throw error;
              }
            } else {
              console.log("No data for error.resopnse");
              throw error;
            }
          } else if (error.request) {
            //const req = error.request as AxiosResponse<
            //  FBaseModelDoc,
            //  FBaseModelDoc
            // >;
            console.log(
              "---- FAxios Request error: ",
              error.request,
              "timeout:",
              error.request._timeout,
            );
            throw error;
          } else {
            throw error;
          }
        } else {
          if (isAnyProperty(error)) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const {message, code} = error;
            console.log(
              `FAxios Unknown error message: ${message}, code: ${code}`,
            );
          }
          console.log(
            `FAxios Unknown error type for method: ${method}, path: ${path}`,
            error,
          );
          // [auth/network-request-failed] A network error has occurred, please try again.]
          throw error;
        }
      }
    } else {
      throw error;
    }
  }
};
