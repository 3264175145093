import {ModelFields} from "../../Interfaces/Models/ModelFields";

const dot = ".";
export const arrayToDotPath = (
  array: Array<ModelFields | string>,
  separator: string = dot,
): string => {
  if (array.length > 0) {
    if (array.length === 1) {
      return `${array[0]}`;
    } else {
      return array.reduce((acc: string, element: ModelFields | string) => {
        if (acc === "") {
          return `${element}`;
        }
        return `${acc}${separator}${element}`;
      }, "");
    }
  }
  return "";
};
