import React, {
  FC,
  type PropsWithChildren,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useAtom } from "jotai";
import { EmailInput } from "../EmailInput";
import { defaultEmailState } from "../EmailInput/defaultEmailState";
import { EmailState } from "../EmailInput/EmailState";
import { ErrorArea } from "../ErrorArea";
import { LoginOrRegisterButton } from "../LoginOrRegisterButton";
import { PasswordInput } from "../PasswordInput";
import { PasswordState } from "../PasswordInput/PasswordState";
import { RegisterResetEmailButtons } from "../RegisterResetEmailButtons";
import { defaultPasswordState } from "../PasswordInput/defaultPasswordState";
import { NameState } from "../NameInput/NameState";
import { defaultNameState } from "../NameInput/defaultNameState";
import { NumberState } from "../PhoneNumberInput/NumberState";
import { defaultNumberState } from "../PhoneNumberInput/defaultNumberState";
import { NameInput } from "../NameInput";
import { PhoneInput } from "../PhoneNumberInput";
import { showLogin } from "../../../JotaiStore";
import { useRegisterNewUser } from "../../../Use/useRegisterNewUser";
import { phoneNumberComponentsDoc } from "../../../../Common/Interfaces/ServerDocs/Auth/phoneNumberComponentsDoc";
import { registerUserRequestDoc } from "../../../../Common/Interfaces/ServerDocs/Auth/registerUserRequestDoc";
import { isAnyProperty } from "../../../../Common/is/isAnyProperty";
import { isString } from "../../../../Common/is/isString";

interface RegisterSectionProps extends PropsWithChildren {
  registerMode: boolean;
  setRegisterMode: (mode: boolean) => void;
}
export const RegisterSection: FC<RegisterSectionProps> = props => {
  const { registerMode, setRegisterMode } = props;
  const [phoneNumberValid, setPhoneNumberValid] = useState<boolean>(false);
  const [firstNameState, setFirstNameState] = useState<NameState>({
    ...defaultNameState,
  });
  const [lastNameState, setLastNameState] = useState<NameState>({
    ...defaultNameState,
  });
  const [emailState, setEmailState] = useState<EmailState>({
    ...defaultEmailState,
  });
  const [passwordState, setPasswordState] = useState<PasswordState>({
    ...defaultPasswordState,
  });
  const [numberState, setNumberState] = useState<NumberState>({
    ...defaultNumberState,
  });
  const {
    error: registerError,
    // response: registerResponse,
    method: registerNewUser,
    success: successfulRegistration,
    resetState: resetRegister,
    loading: registeringUser,
  } = useRegisterNewUser();
  const [, setShowLogin] = useAtom(showLogin);
  useEffect(() => {
    if (successfulRegistration) {
      setShowLogin(false);
      resetRegister();
      setEmailState({ ...defaultEmailState });
      setPasswordState({ ...defaultPasswordState });
      setFirstNameState({ ...defaultNameState });
      setLastNameState({ ...defaultNameState });
    }
  }, [resetRegister, setShowLogin, successfulRegistration]);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(
    null,
  );
  useEffect(() => {
    if (isAnyProperty(registerError)) {
      const { code, message } = registerError;
      if (isString(message)) {
        if (message.toLowerCase().includes("requestdoc is not valid")) {
          setLoginErrorMessage("Registering failed");
        } else if (
          message.toLowerCase().includes("the query requires an index")
        ) {
          setLoginErrorMessage("System Error. :-() Try Again.");
        } else {
          setLoginErrorMessage(message);
        }
      } else if (isString(code)) {
        setLoginErrorMessage(code);
      } else {
        setLoginErrorMessage("Error registering");
      }
    } else {
      setLoginErrorMessage(null);
    }
  }, [registerError]);

  const [loginOrRegisterButtonEnabled, setLoginOrRegisterButtonEnabled] =
    useState<boolean>(false);

  useEffect(() => {
    setLoginOrRegisterButtonEnabled(
      registerMode &&
        passwordState.passwordValid &&
        emailState.emailValid &&
        !emailState.emailExists &&
        phoneNumberValid &&
        firstNameState.nameValid &&
        lastNameState.nameValid,
    );
  }, [
    passwordState,
    emailState,
    registerMode,
    phoneNumberValid,
    firstNameState.nameValid,
    lastNameState.nameValid,
  ]);
  const innerSetEmailState = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (emailState: EmailState) => {
      setEmailState(emailState);
      resetRegister();
    },
    [resetRegister],
  );
  const innerSetPasswordState = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (passwordState: PasswordState) => {
      setPasswordState(passwordState);
      resetRegister();
    },
    [resetRegister],
  );

  const registerUser = useCallback(() => {
    if (emailState.emailExists) {
      return;
    }
    const { name: firstName } = firstNameState;
    const { name: lastName } = lastNameState;
    const { areaCode, prefix, suffix } = numberState;
    const random = Math.floor(Math.random() * 1000);
    const displayName = `${firstName}_${lastName}${random}`;
    const phoneNumber: phoneNumberComponentsDoc = {
      countryCode: "1",
      areaCode,
      prefix,
      suffix,
    };
    const requestDoc: registerUserRequestDoc = {
      email: emailState.email,
      firstName,
      lastName,
      displayName,
      phoneNumber,
      password: passwordState.password,
      photoURL: null,
    };
    registerNewUser(requestDoc);
  }, [
    emailState.email,
    emailState.emailExists,
    firstNameState,
    lastNameState,
    numberState,
    passwordState.password,
    registerNewUser,
  ]);
  const [, setResetText] = useState<string | null>(null);
  return (
    <>
      <EmailInput
        registerMode={registerMode}
        setEmailState={innerSetEmailState}
        emailState={emailState}
      />
      <PasswordInput
        passwordState={passwordState}
        setPasswordState={innerSetPasswordState}
      />
      <NameInput
        firstNameState={firstNameState}
        lastNameState={lastNameState}
        setFirstNameState={setFirstNameState}
        setLastNameState={setLastNameState}
      />
      <PhoneInput
        numberState={numberState}
        setNewNumber={setNumberState}
        setValid={setPhoneNumberValid}
      />
      <ErrorArea message={loginErrorMessage} />
      <LoginOrRegisterButton
        enabled={loginOrRegisterButtonEnabled}
        loginOnServer={registerUser}
        registerMode={registerMode}
        loading={registeringUser}
      />
      <RegisterResetEmailButtons
        emailState={emailState}
        setResetText={setResetText}
        loginModePressed={() => setRegisterMode(false)}
        registerModePressed={() => setRegisterMode(true)}
        registerMode={registerMode}
      />
    </>
  );
};
