import { FC } from 'react';
import { View as DefaultView } from 'react-native';
import { ThemeProps } from './ThemeProps';
export type ViewProps = ThemeProps & DefaultView['props'];
export type ThemedViewProps = ViewProps;
import { useThemeColor } from "./useThemeColor";

export const ThemedView: FC<ViewProps> = (props) => {
    const { style, lightColor, darkColor, ...otherProps } = props;
    const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'background');
    return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
  }
  