import axios, {AxiosError} from "axios";
import {AnyProperty} from "../Common/Interfaces/AnyProperty";
import {CreateAxiosReportRequestDoc} from "../Common/Interfaces/ServerDocs/Admin/CreateAxiosReportRequestDoc";
import {isString} from "../Common/is/isString";
import {reportAxiosErrorService} from "../components/ReportAxiosErrorListener/reportAxiosErrorService";
import {getCurrentUserUID} from "../firebase/authentication";

const verbose = false;
export const processAxiosError = (error: unknown, request: AnyProperty) => {
  if (!axios.isCancel(error)) {
    if (axios.isAxiosError(error)) {
      const e = error as AxiosError;
      const {code, message, name} = e;
      if (isString(code) && (code === "ETIMEOUT" || code === "ECONNABORTED")) {
        verbose && console.log("Axios Timeout error");
      } else {
        console.log("Axios Error", error);
        request.cancelTokenSource = null;
        const requestDoc: CreateAxiosReportRequestDoc = {
          requestDoc: request,
          code: isString(code) ? code : "",
          name: isString(name) ? name : "",
          message: isString(message) ? message : "",
          userUID: getCurrentUserUID(),
          object: [],
        };
        reportAxiosErrorService.next(requestDoc);
      }
    } else {
      console.log("In processAxiosError, not an axios error", error);
    }
  }
};
