import { OrderStatus } from "../../Common/Interfaces/Models/OrderStatus";
import {
  isSearchTermInterface,
  isSearchTermObj,
  searchTermInterface,
} from "./searchTermInterface";

export const innerUpdateOrderStatusTerm = (
  currentSearchTerms: searchTermInterface,
  params: { [property: string]: OrderStatus | null },
): searchTermInterface => {
  const keys = Object.keys(params);
  if (keys.length > 0) {
    const key = keys[0];
    const orderStatus = params[key];
    if (isSearchTermInterface(currentSearchTerms)) {
      const existingTerm = currentSearchTerms[key];
      // if (isFDescriptorDoc(obj)) {
      if (isSearchTermObj(existingTerm)) {
        const newTerm = { ...existingTerm, orderStatus };
        currentSearchTerms[key] = newTerm;
        return { ...currentSearchTerms };
      } else {
        const newTerm = { orderStatus };
        currentSearchTerms[key] = newTerm;
        return { ...currentSearchTerms };
      }
    } else {
      const final: searchTermInterface = {};
      const newTerm = { orderStatus };
      final[key] = newTerm;
      return final;
    }
  }
  return currentSearchTerms;
};
