interface configTerms {
  publicKey: string;
}
export const stripeConfig: {
  test: configTerms;
  production: configTerms;
} = {
  test: {
    publicKey: "pk_test_prUsNzYBVUn1CqDSYfCVRBv2",
  },
  production: {
    publicKey: "pk_live_mFbKUIwuoIBMKyQ7gqAZyqVA",
  },
};

export const stripeReturnURL = "shyne://stripe-redirect";