import { FDescriptorDoc } from "../../Common/Interfaces/BaseDocs/FDescriptorDoc";
import { OrderStatus } from "../../Common/Interfaces/Models/OrderStatus";
import { FBSortOrder } from "../../Common/Interfaces/Query/FBSortOrder";
import { isAnyProperty } from "../../Common/is/isAnyProperty";

export type JotaiDateTerm = {
  order: FBSortOrder;
  constraint?: number | null;
  value: number;
};
export interface SearchTermObj {
  customers?: FDescriptorDoc[];
  businesses?: FDescriptorDoc[];
  orders?: FDescriptorDoc[];
  providers?: FDescriptorDoc[];
  orderStatus?: OrderStatus | null;
  startDateTerm?: JotaiDateTerm | null;
}

export interface searchTermInterface {
  [property: string]: SearchTermObj;
}

export const isSearchTermInterface = (
  candidate: unknown,
): candidate is searchTermInterface => {
  return isAnyProperty(candidate);
};

export const isSearchTermObj = (
  candidate: unknown,
): candidate is SearchTermObj => {
  return isAnyProperty(candidate);
};
