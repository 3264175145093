import FError from "../Common/ErrorHandling/FError";
import {ResponseCode} from "../Common/ErrorHandling/ResponseCode";
import {AuthDoc} from "../Common/Interfaces/AuthDoc";
import {ListQueryRequestDoc} from "../Common/Interfaces/ServerDocs/ListQueryRequestDoc";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import {
  FBQuerySnapshot,

} from "../Mapping/firebaseMapping";
import {innerGetDocs2, unsubscribeFunction} from "./innerGetDocs2";
import {processFBListError} from "./processFBListError";

export const listenToAll = (
  _authDoc: AuthDoc | null,
  requestDoc: ListQueryRequestDoc,
  serverParams: ServerParamsDoc,
  next: (snapshot: FBQuerySnapshot) => void,
  errorHandler: (error: Error) => void,
  complete: () => void,
): unsubscribeFunction => {
  const {
    subDescriptor: {modelPath},
    queryDoc,
  } = requestDoc;
  const innerErrorHandler = (error: unknown) => {
    const method = "fbActions.getListOfSnapshots";
    processFBListError(error, requestDoc);
    errorHandler(
      FError.fbErrortoFError(error, method, ResponseCode.InternalServerError),
    );
  };
  return innerGetDocs2(
    modelPath,
    queryDoc,
    serverParams,
    next,
    innerErrorHandler,
    complete,
  );
};
