import { Button } from "@rneui/themed";
import React, {
  FC,
  useCallback,
  useEffect,
  type PropsWithChildren,
} from "react";
import { useAtom } from "jotai";
import { GestureResponderEvent } from "react-native";
import { CustomLoadingButton } from "../../../Custom/CustomLoadingButton";
import { CustomRow } from "../../../Custom/CustomRow";
import { FlexGrow } from "../../../Custom/FlexGrow";
import { showLogin } from "../../../JotaiStore";
import { EmailState } from "../EmailInput/EmailState";
import { useStyles } from "./useStyles";
import { isWeb } from "../../../../isWeb";
import { useSendPasswordResetEmail } from "../../../Use/useSendPasswordResetEmail";
import { logger } from "../../../logger";
import { useNavigateBack } from "../../../useNavigate/useNavigateBack";
import { isString } from "../../../../Common/is/isString";

interface RegisterResetEmailButtonsProps extends PropsWithChildren {
  loginModePressed: () => void;
  registerModePressed: () => void;
  registerMode: boolean;
  setResetText: (text: string) => void;
  emailState: EmailState;
}
const loginTitle = "Login";
const registerTitle = "Create Account";
const resetEmailTitle = "Reset Password";
export const RegisterResetEmailButtons: FC<
  RegisterResetEmailButtonsProps
> = props => {
  const {
    loginModePressed,
    registerModePressed,
    registerMode,
    emailState,
    setResetText,
  } = props;
  const { container } = useStyles();
  const [, setShowLogin] = useAtom(showLogin);
  const onLoginModePressed = useCallback(
    (event: GestureResponderEvent) => {
      event.stopPropagation();
      event.preventDefault();
      loginModePressed();
    },
    [loginModePressed],
  );
  const onRegisterModePressed = useCallback(
    (event: GestureResponderEvent) => {
      event.stopPropagation();
      event.preventDefault();
      registerModePressed();
    },
    [registerModePressed],
  );
  const {
    method: passwordResetMethod,
    success: resetSuccess,
    report,
    resetState: resetPasswordReset,
  } = useSendPasswordResetEmail();
  useEffect(() => {
    if (resetSuccess && isString(report)) {
      setResetText(report);
      resetPasswordReset();
    }
  }, [report, resetPasswordReset, resetSuccess, setResetText]);
  const resetEmail = useCallback(async () => {
    const { email, emailValid, emailExists } = emailState;
    if (emailValid && emailExists) {
      logger.log("In restEmail, sending request");
      setResetText(`Check your email at ${email}`);
      passwordResetMethod(email);
    } else {
      logger.log("In resetEamil", emailValid, emailExists, email);
    }
  }, [emailState, passwordResetMethod, setResetText]);
  const navigateBack = useNavigateBack()
  return (
    <CustomRow
      width100={true}
      justifyContent={"center"}
      style={isWeb() ? container : undefined}>
      <FlexGrow />
      {!registerMode ? (
        <Button
          title={registerTitle}
          onPress={onRegisterModePressed}
          type={"clear"}
        />
      ) : (
        <Button
          title={loginTitle}
          onPress={onLoginModePressed}
          type={"clear"}
        />
      )}
      {!registerMode && emailState.emailExists && <FlexGrow />}
      {!registerMode && emailState.emailExists && (
        <CustomLoadingButton
          title={resetEmailTitle}
          onPress={resetEmail}
          type={"clear"}
          width={160}
        />
      )}
      <FlexGrow />
      <Button
        title={"Home"}
        onPress={() => {
          setShowLogin(false);
          navigateBack();
        }}
        type={"clear"}
      />
      <FlexGrow />
    </CustomRow>
  );
};
