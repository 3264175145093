import {useCallback, useEffect, useRef, useState} from "react";
import CancelError from "../../ErrorHandling/CancelError";
import {getCancelTokenSource} from "../../../FAxios/getCancelTokenSource";
import {logout} from "../../../firebase/authentication";
import {FBCancelTokenSource} from "../../../Mapping/axiosMapping";
import {appShowMessage} from "../../../ReactSpecific/showMessage";
import {useGetMyProfile3} from "./useGetMyProfile3";

type StateType = {
  error: Error | null;
  loading: boolean;
  success: boolean;
};
const defaultState: StateType = {
  error: null,
  loading: false,
  success: false,
};

export const useLogout = () => {
  const tokenRef = useRef<FBCancelTokenSource | null>(null);
  const cancelRequest = useCallback(() => {
    const token = tokenRef.current;
    token && token.cancel();
    tokenRef.current = null;
  }, []);
  useEffect(() => {
    return () => {
      cancelRequest();
    };
  }, [cancelRequest]);
  const [{error, loading, success}, setState] = useState<StateType>({
    ...defaultState,
  });
  const resetState = useCallback(() => {
    setState({...defaultState});
  }, []);
  const {refetch} = useGetMyProfile3();
  const method = useCallback(async () => {
    cancelRequest();
    const cancelTokenSource = getCancelTokenSource();
    tokenRef.current = cancelTokenSource;
    setState(previous => ({
      ...previous,
      loading: false,
      success: false,
    }));
    try {
      await logout();
      refetch();
      const ref = tokenRef.current;
      if (ref) {
        tokenRef.current = null;
        setState(previous => ({
          ...previous,
          loading: false,
          success: true,
        }));
        const message = "Logged Out";
        appShowMessage({
          message,
          type: "info",
        });
      }
    } catch (incomingError) {
      const ref = tokenRef.current;
      if (ref) {
        tokenRef.current = null;
        if (!(incomingError instanceof CancelError)) {
          setState(previous => ({
            ...previous,
            loading: false,
            success: false,
            error: incomingError as Error,
          }));
        }
      }
    }
  }, [cancelRequest]);
  return {
    method,
    loading,
    error,
    success,
    resetState,
  };
};
