import { useAtom } from "jotai";
import { FC, useCallback, useRef, useEffect } from "react";
import { Subscription } from "rxjs";
import { dbActions } from "../../../Common/Functions/dbActions";
import { CreateIndexReportRequestDoc } from "../../../Common/Interfaces/ServerDocs/Admin/CreateIndexReportRequestDoc";
import { getCancelTokenSource } from "../../../FAxios/getCancelTokenSource";
import { serverParams } from "../../JotaiStore";
import { reportIndexService } from "../../reportIndexService";

export const ReportIndexListener: FC = () => {
  const [{ authDoc, serverParamsDoc }] = useAtom(serverParams);
  const reportIndex = useCallback(
    async (reportDoc: CreateIndexReportRequestDoc) => {
      try {
        await dbActions.createIndexReport(
          authDoc,
          reportDoc,
          serverParamsDoc,
          getCancelTokenSource(),
        );
      } catch (error) {
        console.log("Got error saving indexReport", error);
      }
    },
    [authDoc, serverParamsDoc],
  );
  const subscription = useRef<Subscription | null>(null);
  useEffect(() => {
    return () => {
      const ref = subscription.current;
      subscription.current = null;
      ref && ref.unsubscribe();
    };
  }, []);
  useEffect(() => {
    subscription.current = reportIndexService.subscribe(
      (next: CreateIndexReportRequestDoc) => {
        reportIndex(next);
      },
    );
  }, [reportIndex]);
  return null;
};
