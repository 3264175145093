import {appVersion} from "../../Common/Functions/appVersion";
import {AnyProperty} from "../../Common/Interfaces/AnyProperty";
import {AuthDoc} from "../../Common/Interfaces/AuthDoc";
import {FBaseModelDoc} from "../../Common/Interfaces/BaseDocs/FBaseModelDoc";
import {UpdateLocationNameRequestDoc} from "../../Common/Interfaces/ServerDocs/Location/UpdateLocationNameRequestDoc";
import {ServerParamsDoc} from "../../Common/Interfaces/ServerParamsDoc";
import {ServerPayloadDoc} from "../../Common/Interfaces/ServerPayloadDoc";
import {ServerMethods} from "../../Common/ServerMethods";
import {finalRequest} from "../../FBOrderActions/finalRequest";
import {FBCancelTokenSource} from "../../Mapping/axiosMapping";

const method = ServerMethods.updateLocationName;
export const updateLocationName = async (
  _authDoc: AuthDoc | null,
  requestDoc: UpdateLocationNameRequestDoc,
  serverParamsDoc: ServerParamsDoc,
  cancelTokenSource: FBCancelTokenSource,
): Promise<FBaseModelDoc[]> => {
  const payload: ServerPayloadDoc = {
    serverParamsDoc,
    appVersion: appVersion(),
    requestDoc: requestDoc as unknown as AnyProperty,
    method,
    cancelTokenSource,
  };
  return finalRequest(method, payload, serverParamsDoc);
};
