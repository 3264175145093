export enum ModelFields {
  appRoles = "appRoles",
  avatar = "avatar",
  customer = "customer",
  createdAt = "createdAt",
  descriptor = "descriptor",
  displayName = "displayName",
  displayNameLC = "displayNameLC",
  editing = "editing",
  firstName = "firstName",
  fromCache = "fromCache",
  fuid = "fuid",
  fuids = "fuids",
  hashtags = "hashtags",
  inthepast = "inthepast",
  lastName = "lastName",
  middleName = "middleName",
  modelType = "modelType",
  modelVersion = "modelVersion",
  moderators = "moderators",
  nameDoc = "nameDoc",
  objectId = "objectId",
  ObjectId = "ObjectId",
  _id = "_id",
  order = "order",
  originator = "originator",
  owner = "owner",
  parent = "parent",
  parents = "parents",
  path = "path",
  pendingWrites = "pendingWrites",
  popup = "popup",
  providers = "providers",
  special = "special",
  title = "title",
  titlelc = "titlelc",
  version = "version",
  visible = "visible",
  updatedAt = "updatedAt",
  whitelislt = "whitelist",
  geoLocs = "_geoloc",
  position = "position",
  streetNumber = "street_number",
  route = "route",
  neighborhood = "neighborhood",
  locality = "locality",
  county = "administrative_area_level_2",
  state = "administrative_area_level_1",
  country = "country",
  postalCode = "postal_code",
  formattedAddress = "formatted_address",
  latitude = "lat",
  longitude = "lng",
  placeId = "place_id",
  postalCodeSuffix = "postal_code_suffix",
  locationUUID = "locationUUID",
  coordinates = "coordinates",
  name = "name",
  vicinity = "vicinity",
  latLngLiteral = "latLngLiteral",
  locations = "locations",
  district = "district",
  schools = "schools",
  person = "person",
  employeeTypes = "employeeTypes",
  author = "author",
  placePhoneNumber = "placePhoneNumber",
  placeName = "placeName",
  placeURL = "placeURL",
  placeWebsite = "placeWebsite",
  website = "website",
  email = "email",
  privateids = "privateids",
  target = "target",
  invitee = "invitee",
  inviteStatus = "inviteStatus",
  height = "height",
  width = "width",
  publicId = "publicId",
  fileFormat = "fileFormat",
  resourceType = "resourceType",
  bytes = "bytes",
  fileURL = "fileURL",
  assigner = "assigner",
  downloadedStamp = "downloadedStamp",
  whiteList = "whiteList",
  to = "to",
  imageDocs = "imageDocs",
  customers = "customers",
  orders = "orders",
  payments = "payments",
  DOCType = "DOCType",
  locationName = "locationName",
  radiusInMiles = "radiusInMiles",
  timestamp = "timestamp",
  distance = "distance",
  canceled = "canceled",
  lastNameLC = "lastNameLC",
  phoneNumber = "phoneNumber",
  countryCode = "countryCode",
  phoneBody = "phoneBody",
  phoneNumbers = "phoneNumbers",
  areaCode = "areaCode",
  prefix = "prefix",
  suffix = "suffix",
  defaultNumber = "defaultNumber",
  roles = "roles",
  primary = "primary",
  e164 = "e164",
  formattedE164 = "formattedE164",
  blocked = "blocked",
  public = "public",
  text = "text",
  startDate = "startDate",
  endDate = "endDate",
  allDay = "allDay",
  rRule = "rRule",
  exDate = "exDate",
  frequency = "frequency",
  count = "count",
  products = "products",
  metaScheduleDoc = "metaScheduleDoc",
  businesses = "businesses",
  recurrence = "recurrence",
  interval = "interval",
  weekdays = "weekdays",
  monthdays = "monthdays",
  weekstart = "weekstart",
  iCalDoc = "icaldoc",
  recurrenceDoc = "recurrenceDoc",
  primativeRecurrenceDoc = "primativeRecurrenceDoc",
  primativeICalDoc = "primativeICalDoc",
  parentSchedule = "parentSchedule",
  owners = "owners",
  keys = "keys",
  descriptors = "descriptors",
  description = "description",
  content = "content",
  business = "business",
  price = "price",
  listOrder = "listorder",
  uniqueName = "uniqueName",
  startHour = "startHour",
  endHour = "endHour",
  submitted = "submitted",
  businessDomain = "businessDomain",
  businessDomainLC = "businessDomainLC",
  message = "message",
  stringified = "stringified",
  orderStatus = "orderStatus",
  paid = "paid",
  cancelled = "cancelled",
  start = "start",
  processor = "processor",
  data = "data",
  squareID = "id",
  squareid = "squareid",
  squareobject = "object",
  squareobjecttype = "type",
  squarestatus = "status",
  processingFee = "processing_fee",
  squarereporttype = "type",
  refundedMoney = "refunded_money",
  splitid = "splitid",
  processorId = "processorid",
  reason = "reason",
  receiptURLs = "receiptURLs",
  nonce = "nonce",
  token = "token",
  amount = "amount",
  orderId = "order_id",
  note = "note",
  tipMoney = "tip_money",
  customerId = "customer_id",
  totalMoney = "total_money",
  card = "card",
  cardBrand = "card_brand",
  last4 = "last_4",
  expMonth = "exp_month",
  expYear = "exp_year",
  fingerPrint = "fingerprint",
  cardType = "card_type",
  bin = "bin",
  entryMethod = "entry_method",
  cvvStatus = "cvv_status",
  avsStatus = "avs_status",
  statementDescription = "statement_description",
  receiptNumber = "receipt_number",
  receiptURL = "receipt_url",
  squareCreatedAt = "created_at",
  squareUpdatedAt = "updated_at",
  amountMoney = "amount_money",
  currency = "currency",
  status = "status",
  sourceType = "source_type",
  cardDetails = "card_details",
  locationId = "location_id",
  paymentReceipt = "paymentReceipt",
  orderDescriptor = "orderDescriptor",
  referenceId = "reference_id",
  paymentVendor = "paymentVendor",
  tax = "tax",
  taxRate = "taxRate",
  tip = "tip",
  appMode = "appMode",
  totalAmount = "totalAmount",
  providerFuids = "providerFuids",
  accountSid = "accountSid",
  body = "body",
  dateCreated = "dateCreated",
  dateUpdated = "dateUpdated",
  uri = "uri",
  media = "media",
  dateSent = "dateSent",
  ToState = "ToState",
  ToCity = "ToCity",
  FromZip = "FromZip",
  SmsMessageSid = "SmsMessageSid",
  FromState = "FromState",
  SmsSid = "SmsSid",
  SmsStatus = "SmsStatus",
  FromCity = "FromCity",
  ToZip = "ToZip",
  MessageSid = "MessageSid",
  incomingTextDoc = "incomingTextDoc",
  direction = "direction",
  errorCode = "errorCode",
  errorMessage = "errorMessage",
  from = "from",
  messagingServiceSid = "messagingServiceSid",
  numMedia = "numMedia",
  numSegments = "numSegments",
  sid = "sid",
  subresourceUris = "subresourceUris",
  $date = "$date",
  addedToOrder = "addedToOrder",
  discount = "discount",
  productDoc = "productDoc",
  orderDoc = "orderDoc",
  rating = "rating",
  businessName = "businessName",
  businessNameLC = "businessNameLC",
  customerName = "customerName",
  customerNameLC = "customerNameLC",
  authCode = "authCode",
  workerTypes = "workerTypes",
  businessMessage = "businessMessage",
  bullets = "bullets",
  sku = "sku",
  customerComment = "customerComment",
  privateId1 = "privateid1",
  privateId2 = "privateid2",
  mobileServices = "mobileServices",
  deactive = "deactive",
  providerName = "providerName",
  providerNameLC = "providerName",
  managerName = "managerName",
  managerNameLC = "managerName",
  recipient = "recipient",
  messageIsRead = "messageIsRead",
  url = "url",
  payload = "payload",
  stripeReportType = "stripeReportType",
  stripeReportStatus = "stripeReportStatus",
  stripeReport = "stripeReport",
  stripeId = "stripeId",
  reportVerb = "reportVerb",
  created = "created",
  lineItems = "lineItems",
  productTotal = "productTotal",
  total = "total",
  metaData = "metadata",
  invoice = "invoice",
  stripeTimestamp = "stripeTimestamp",
  id = "id",
  customerFuid = "customerFuid",
  refunded = "refunded",
  toggle = "toggle",
  searchableStartTime = "searchableStartTime",
  ownerName = "ownerName",
  ownerNameLC = "ownerNameLC",
  startTimeObject = "startTimeObject",
  endTimeObject = "endTimeObject",
  zones = "zones",
  zonesText = "zonesText",
  quillRows = "quillRows",
  quillStrings = "quillStrings",
  modelPath = "modelPath",
  authId = "authId",
  fbTimestamp = "fbTimestamp",
  room = "room",
  sender = "sender",
  following = "follwing",
  follower = "follower",
  descriptorDoc1 = "descriptorDoc1",
  descriptorDoc2 = "descriptorDoc2",
  privateRoom = "privateRoom",
  messageFolder = "messageFolder",

  invoiceFuid = "invoiceFuid",
  textEnabled = "textEnabled",
  titleLC = "titleLC",
  published = "published",
  creating = "creating",
  transactionDoc = "transactionDoc",
  price100 = "price100",
  borderStyles = "borderStyle",
  claimed = "claimed",
  album = "album",
  image = "image",
  imageType = "imageType",
  forwardGroup = "forwardGroup",
  caption = "caption",
  superParent = "superParent",
  hasChildren = "hasChildren",
  series = "series",
  childSeries = "childSeries",
  parentSeries = "parentSeries",
  orderProduct = "orderProduct",
  descriptionArea = "descriptionArea",
  summary = "summary",
  descriptionHTML = "descriptionHTML",
  descriptionText = "descriptionText",
  descriptionDelta = "descriptionDelta",
  carcolor = "carcolor",
  carmodel = "carmodel",
  agreedToTOS = "agreedToTOS",
  ein = "ein",
  bankAccount = "bankAccount",
  bankRoutingNumber = "bankRoutingNumber",
  verified = "verified",
  venmoAccount = "venmoAccount",
  paypalAccount = "paypalAccount",
  useInAppPayment = "useInAppPayment",
  homeService = "homeService",
  selectedBusinessPerson = "selectedBusinessPerson",
  identifier = "identifier",
  contentObj = "contentObj",
  provider = "provider",
  comment = "comment",
  ratingTotal = "ratingTotal",
  ratingCount = "ratingCount",
  punctuality = "punctuality",
  pleasantness = "pleasantness",
  punctualityTotal = "punctualityTotal",
  punctualityCount = "punctualityCount",
  pleasantnessTotal = "pleasantnessTotal",
  pleasantnessCount = "plesantnessCount",
  formType = "formtype",
  stripeCreated = "stripecreated",
  locked = "locked",
  serviceAmount = "serviceAmount",
  distributionTime = "distributionTime",
  distributionMethod = "distributionMethod",
  transactionId = "transactionId",
  other = "other",
  sentVenmoRequest = "sentVenmoRequest",
  requestTime = "requestTime",
  requestFullfilled = "requestFullfilled",
  transaction = "transaction",
  blockedFromOrdering = "blockedFromOrdering",
  indexURL = "indexURL",
  requestDoc = "requestDoc",
  resolved = "resolved",
  code = "code",
  userUID = "userUID",
  object = "object",
  loginEvent = "loginEvent",
  algoliaIndex = "algoliaIndex",
  categories = "categories",
  homeWash = "homeWash",
  commences = "commences",
  expires = "expires",
  functionName = "functionName",
  obj = "obj",
  localizedMessage = "localizedMessage",
  declineCode = "declinedCode",
  stripeErrorCode = "stripeErrorCode",
  errorType = "errorType",
  feeRate = "feeRate",
  feeAmount = "feeAmount",
  publicPhoto = "publicPhoto",
  imageTaken = "imageTaken",
  popupIdentifier = "popupIdentifier",
  popupIdentifierLC = "popupIdentifierLC",
  popupEvents = "popupEvents",
  popupIdentifiers = "popupIdentifiers",
  featured = "featured",
  popupOrder = "popupOrder",
  chargeReport = "chargeReport",
  tipSplit = "tipSplit",
  paymentTime = "paymentTime",
  oneTimeUse = "oneTimeUse",
  paymentEmail = "paymentEmail",
  MessageStatus = "MessageStatus",
  MessageServiceSid = "MessageServiceSid",
  sending = "sending",
  received = "received",
  pending = "pending",
  quickReplies = "quickReplies",
  video = "video",
  audio = "audio",
  system = "system",
  sent = "sent",
  user = "user",
  messageRead = "messageRead",
  iMessage = "iMessage",
  photoDocs = "photoDocs",
}
