import { Input } from "@rneui/themed";
import React, { FC, useCallback, useRef, type PropsWithChildren } from "react";
import { TextInput } from "react-native";
import { validatePassword } from "../../../../Common/Validations/validatePassword";
import { useCommonStyles } from "../../../CommonStyles/useCommonStyles";
import { PasswordState } from "./PasswordState";

const label = "Password";
const placeholder = "your secret";
interface PasswordInputProps extends PropsWithChildren {
  passwordState: PasswordState;
  setPasswordState: (state: PasswordState) => void;
  disabled?: boolean;
}
export const PasswordInput: FC<PasswordInputProps> = props => {
  const {
    disabled = false,
    passwordState: { password, passwordMessage },
    setPasswordState,
  } = props;
  const ref = useRef<TextInput | null>(null);
  const { error, input, inputContainer, inputLabel } = useCommonStyles();
  const processPassword = useCallback(
    (textValue: string, valid: boolean) => {
      const message = valid ? undefined : `${textValue} too short`;
      setPasswordState({
        password: textValue,
        passwordValid: valid,
        passwordMessage: message,
      });
    },
    [setPasswordState],
  );

  const onChangeText = useCallback(
    (newValue: string) => {
      const { scrubbed, valid } = validatePassword(newValue);
      processPassword(scrubbed, valid);
    },
    [processPassword],
  );
  return (
    <Input
      errorStyle={error}
      labelStyle={inputLabel}
      value={password}
      label={label}
      autoCapitalize={"none"}
      inputStyle={input}
      inputContainerStyle={inputContainer}
      disabled={disabled}
      errorMessage={passwordMessage === null ? undefined : passwordMessage}
      placeholder={placeholder}
      onChangeText={onChangeText}
      ref={ref}
      autoFocus={false}
      secureTextEntry={true}
    />
  );
};
