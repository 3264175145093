import {QueryFunctionContext, QueryKey} from "react-query";
import { QueryFunctionType } from "../../../../Client/RQ/QueryFunctionType";
import { showConsole } from "../../../../config/showConsole";
import { getCancelTokenSource } from "../../../../FAxios/getCancelTokenSource";
import { getCurrentUserUID } from "../../../../firebase/authentication";
import { dbActions } from "../../../Functions/dbActions";
import { AuthDoc } from "../../../Interfaces/AuthDoc";
import { ServerParamsDoc } from "../../../Interfaces/ServerParamsDoc";
import { isAnyProperty } from "../../../is/isAnyProperty";

export const queryFnForMyProfile: QueryFunctionType = async <T>(
  authDoc: AuthDoc | null,
  serverParamsDoc: ServerParamsDoc,
  context: QueryFunctionContext<QueryKey, T | null>,
): Promise<T | null> => {
  if (isAnyProperty(context)) {
    const {signal} = context;
    const source = getCancelTokenSource();
    // Cancel the request if React Query signals to abort
    signal?.addEventListener("abort", () => {
      source.cancel("Query for myProfile was cancelled by React Query");
    });
    if (getCurrentUserUID()) {
      return dbActions
        .getOrCreateMyProfile(authDoc, serverParamsDoc, source)
        .then(results => {
          return results.length > 0 ? (results[0] as unknown as T) : null;
        });
    }
    return Promise.resolve(null);
  }
  showConsole && console.log("No React-Query context for gueryFnForMyProfile");
  return Promise.resolve(null);
};
