import { RNViewStyle, styleSheetCreate } from "../../../../Mapping/styleSheetCreate";


const styleObj: {
  firstName: RNViewStyle;
  lastName: RNViewStyle;
} = {
  firstName: {width: "40%"},
  lastName: {width: "60%"},
};
const styles = styleSheetCreate(styleObj);
export const useStyles = () => styles;
