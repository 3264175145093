import React, {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  type PropsWithChildren,
} from "react";
import { FBAuthUser } from "../../Mapping/firebaseMapping";
import { initializeAuth } from "../../firebase/authentication";
import AuthContext from "./AuthContext";
import { AuthChecker } from "./AuthChecker";
import { Unsubscribe } from "../../Common/Interfaces/Unsubscribe";
import { isString } from "../../Common/is/isString";
import { useGetMyProfile3 } from "../../Common/Use/Use2/useGetMyProfile3";
  
  const FirebaseAuthentication: FC<PropsWithChildren> = props => {
    const { children } = props;
    const [{ uid, user }, setAuthInfo] = useState<{
      uid: string | null;
      user: FBAuthUser | null;
    }>({ uid: null, user: null });
    const listener = useRef<Unsubscribe | null>(null);
    const { data: myProfile, refetch } = useGetMyProfile3();
    const currentUID = useRef<string | null>(null);
    const lastLoginReport = useRef<number>(0);
    const dataHandler = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-shadow
      (user: FBAuthUser | null) => {
        const newUID = user ? user.uid : null;
        const current = currentUID.current;
        currentUID.current = newUID;
        setAuthInfo({ user, uid: newUID });
        if (isString(newUID)) {
          const latest = new Date().getTime();
          if (current === null || (isString(current) && current !== newUID)) {
            if (latest - lastLoginReport.current > 2000) {
            }
          }
          lastLoginReport.current = latest;
        } else if (isString(current)) {
        }
        // (user !== null && newUID !== current)
        // if (newUID !== current) {
          refetch();
        // }
      },
      [refetch],
    );
  
    useEffect(() => {
      listener.current && listener.current();
      listener.current = initializeAuth(dataHandler);
      return () => {
        listener.current && listener.current();
        listener.current = null;
      };
    }, [dataHandler]);
    return (
      <AuthContext.Provider
        value={{
          uid,
          user,
          myProfile,
        }}>
        <AuthChecker>{children}</AuthChecker>
      </AuthContext.Provider>
    );
  };
  
  export default FirebaseAuthentication;
  