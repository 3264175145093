// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyDqIAXmL5ziMDcqBRemnwM8DNHqHLA4xsY",
    authDomain: "yourplace-4061e.firebaseapp.com",
    databaseURL: "https://yourplace-4061e-default-rtdb.firebaseio.com",
    projectId: "yourplace-4061e",
    storageBucket: "yourplace-4061e.appspot.com",
    messagingSenderId: "856579270278",
    appId: "1:856579270278:web:61b757471b8affee759307",
    measurementId: "G-TE8JVPJ2NF",
  };
  