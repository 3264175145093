import {
    isSearchTermInterface,
    isSearchTermObj,
    JotaiDateTerm,
    searchTermInterface,
  } from "./searchTermInterface";
  
  export const innerUpdateStartDateTerm = (
    currentSearchTerms: searchTermInterface,
    params: { [property: string]: JotaiDateTerm | null },
  ): searchTermInterface => {
    const keys = Object.keys(params);
    if (keys.length > 0) {
      const key = keys[0];
      const startDateTerm = params[key] || null;
      if (isSearchTermInterface(currentSearchTerms)) {
        const existingTerm = currentSearchTerms[key];
        // if (isFDescriptorDoc(obj)) {
        if (isSearchTermObj(existingTerm)) {
          const newTerm = { ...existingTerm, startDateTerm };
          currentSearchTerms[key] = newTerm;
          return { ...currentSearchTerms };
        } else {
          const newTerm = { startDateTerm };
          currentSearchTerms[key] = newTerm;
          return { ...currentSearchTerms };
        }
      } else {
        const final: searchTermInterface = {};
        const newTerm = { startDateTerm };
        final[key] = newTerm;
        return final;
      }
    }
    return currentSearchTerms;
  };
  