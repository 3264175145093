import React, {FC} from "react";
import { View } from "react-native";
import { Text } from "@rneui/themed";
import { CustomColumn } from "../../../Client/Custom/CustomColumn"
import { CustomRow } from "../../../Client/Custom/CustomRow";
import { ButtonRow } from "./ButtonRow";
import { useStyles } from "./useStyles";

export interface buttonObj {
    amount: number; title: string;
}
const buttons1: buttonObj[] = [
    {amount: 15, title: "15 seconds"},
    {amount: 30, title: "30 seconds"},
    {amount: 45, title: "45 seconds"},
    {amount: 60, title: "1 minute"},
]
const buttons2: buttonObj[] = [
    {amount: 75, title: "1 minute 15 seconds"},
    {amount: 90, title: "1 minutes 30 seconds"},
    {amount: 105, title: "1 minute 45 seconds"},
    {amount: 120, title: "2 minutes"},
]
interface CountButtonProps {
    onPress: (amount: number, setAndStart: boolean) => void;
}
const ss = "Set & Start"
export const CountButtons: FC<CountButtonProps> = props => {
    const {onPress} = props;
    const {centergap, horizontalLine, textArea} = useStyles();
    return (
    <CustomColumn width100={true}>
        <>
            <CustomRow justifyContent="center">
                <CustomRow style={textArea} width100={true}>
                    <Text h4>{ss}</Text>
                </CustomRow>
                <View style={centergap} />
                <CustomRow style={textArea}>
                    <Text h4>Set</Text>
                </CustomRow>
            </CustomRow>
            <ButtonRow buttons={buttons1} onPress={onPress} />
            <CustomRow justifyContent="center">
                <View style={horizontalLine} />
            </CustomRow>
            <ButtonRow buttons={buttons2} onPress={onPress} />        
        </>
    </CustomColumn>)
}