import { isString } from "../../../../Common/is/isString";

export const numbersOnly = new RegExp("^[0-9]*$");
export const validatePhoneComponents = (
  areaCode: unknown,
  prefix: unknown,
  suffix: unknown,
): boolean => {
  if (isString(areaCode) && isString(prefix) && isString(suffix)) {
    if (areaCode.length === 3 && prefix.length === 3 && suffix.length === 4) {
      return (
        numbersOnly.test(areaCode) &&
        numbersOnly.test(prefix) &&
        numbersOnly.test(suffix)
      );
    }
  }
  return false;
};
