import React, { FC, type PropsWithChildren } from "react";
import { Text as ElementText } from "@rneui/themed";
import { useStyles } from "./useStyles";
import { Text } from "react-native";
import { RNTextStyle } from "../../../Mapping/styleSheetCreate";
import { useCommonStyles } from "../../CommonStyles/useCommonStyles";

interface CustomTextProps {
  type: "h1" | "h2" | "h3" | "h4" | "other" | "regular";
  style?: RNTextStyle | RNTextStyle[];
  italicized?: true;
  inverted?: boolean;
  wrapText?: boolean;
}
export const CustomText: FC<PropsWithChildren<CustomTextProps>> = props => {
  const { children, style, type, inverted = false, italicized = false, wrapText = false } = props;
  const {italicized: italicizedStyles} = useCommonStyles();
  const {
    h1Style,
    h2Style,
    h3Style,
    primaryInverted,
    primaryStandard,
    size20,
  } = useStyles();
  return (
    <>
      {type === "other" && (
        <Text style={[inverted ? primaryInverted : primaryStandard, style,
          italicized ? italicizedStyles : undefined]}>
          {children}
        </Text>
      )}
      {type !== "other" && type !== "regular" && (
        <ElementText
          style={[style, inverted ? primaryInverted : primaryStandard, wrapText ?  {flex: 1, width: 1} : undefined]}
          h1={type === "h1"}
          h1Style={h1Style}
          h2={type === "h2"}
          h2Style={h2Style}
          h3={type === "h3"}
          h3Style={h3Style}
          h4={type === "h4"}>
          {children}
        </ElementText>
      )}
      {type === "regular" && (
        <Text
          style={[inverted ? primaryInverted : primaryStandard, style, size20,  {flex: 1, width: 1}]}>
          {children}
        </Text>
      )}
    </>
  );
};
