import React, {FC, type PropsWithChildren} from "react";
import SearchCenterContext from ".";
import { useWatchSearchCenter } from "../../Use/useWatchSearchCenter";


export const SearchCenterContextProvider: FC<PropsWithChildren> = props => {
  const {children} = props;
  const {searchCenterDocs} = useWatchSearchCenter();
  return (
    <SearchCenterContext.Provider
      value={{
        searchCenterDocs
      }}>
        {children}
    </SearchCenterContext.Provider>
  );
};