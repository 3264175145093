import { RNViewStyle, styleSheetCreate } from "../../../Mapping/styleSheetCreate";


const styleObj: {
  grow: RNViewStyle;
} = {
  grow: { flexGrow: 1 },
};

const styles = styleSheetCreate(styleObj);
export const useStyles = () => styles;
  