import { DataSnapshot } from "firebase/database";
import React, {FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react"
import { Text } from "@rneui/themed";
import { CustomRNEButton } from "../../Client/Custom/CustomRNEButton";
import { isAnyProperty } from "../../Common/is/isAnyProperty";
import { isNumber } from "../../Common/is/isNumber";
import { setTimer, subscribeToTimer } from "../../components/RTTimer";
import { unsubscribeFunction } from "../../FBActions/innerGetDocs2";
import { CustomRow } from "../../Client/Custom/CustomRow";
import { CustomColumn } from "../../Client/Custom/CustomColumn";
import { CountButtons } from "./CountButtons";
import { useStyles } from "./useStyles";
import { useNavigateToLoginModal } from "../../Client/useNavigate/useNavigateToLoginPage";
import AuthContext from "../../Client/Authentication/AuthContext";
import { useLogout } from "../../Common/Use/Use2/useLogout";
import { View } from "react-native";
import { Audio } from 'expo-av';
import { Sound } from "expo-av/build/Audio";

const myUID = "z0fZX4Nfk7gwA06pSzT55Gac5Z92"
export const PVLandingPage: FC = () => {
    const {myProfile, uid} = useContext(AuthContext);
    const [sound, setSound] = useState<Sound | null>(null);
    const playSound = useCallback(async() => {
        const { sound } = await Audio.Sound.createAsync(require('../../assets/sound/mixkit-attention-bell-ding-586.wav')
        );
        setSound(sound);
        await sound.playAsync();
    }, []);
    const [paused, setPaused] = useState<boolean>(false);
    const unsubscribe = useRef<unsubscribeFunction | null>(null);
    useEffect(() => {
        return () => {
            const ref = unsubscribe.current;
            ref && ref();
        }
    }, []);
    const lastNumberRef = useRef<number>(60);
    const previousRef = useRef<number>(0);
    const [countdown, setCountdown] = useState<number>(0);
    const countdownRef = useRef<number>(0);
    const interval = useRef<NodeJS.Timer | null>(null);
    const restartTimer = useCallback(() => {
        interval.current = setInterval(() => {
            let count = countdownRef.current;
            count = Math.floor(count - 1);
            if (count < 0) { count = 0}
            setTimer(count).catch((error) => {
                console.log("GOt error Updating Countdown", error);
            });
        }, 1000);
    }, [])
    useEffect(() => {
        const ref = unsubscribe.current;
        ref && ref();
        unsubscribe.current = subscribeToTimer((snapshot: DataSnapshot) => {
            if (snapshot.exists()) {
                const obj = snapshot.val();
                if (isAnyProperty(obj)) {
                    const {seconds} = obj;
                    if (isNumber(seconds)) {
                        if (seconds <= 0) {
                            const previous = previousRef.current;
                            const ref = interval.current;
                            ref && clearInterval(ref);
                            setCountdown(0)
                            previousRef.current = 0;
                            countdownRef.current = 0;
                            if (previous === 1) {
                                playSound();
                            }
                        } else {
                            setCountdown(seconds);
                            previousRef.current = seconds;
                            countdownRef.current = seconds;
                        }
                    }
                }
            } else {
                console.log("no snapshot")
            }
        })
    }, [])
    const setAmount = useCallback(async (amount: number | null) => {
        const count = isNumber(amount) ? amount : lastNumberRef.current;
        setTimer(count).then(() => {
            lastNumberRef.current = count;
        }).catch((error) => {
            console.log("GOt error setting Timer", error);
        })
    }, [])
    const elements = useMemo(() =>  {
    const fontSize = myProfile !== null ? 200 : 290;
    if (countdown < 10) {
        return (
            <CustomRow justifyContent="center" width100={true}>
                <View style={{backgroundColor: '#FF7289', borderRadius: 20, borderWidth: 1, borderColor: "red"}}>
                <Text h1 h1Style={{ fontSize: fontSize }}>{countdown}</Text>
                </View>
            </CustomRow>);       
    }
    if (countdown < 60) {
        return (
            <CustomRow justifyContent="center" width100={true}>
                <Text h1 h1Style={{ fontSize: fontSize }}>{countdown}</Text>
            </CustomRow>);
    } else {
        let seconds = countdown % 60;
        const secondsText = seconds < 10 ? `0${seconds}` : `${seconds}`;
        const minutes = Math.floor(countdown / 60);
        return (
            <CustomRow justifyContent="center" width100={true}>
                <Text h1 h1Style={{ fontSize }}>{`${minutes} : ${secondsText}`}</Text>
            </CustomRow>);
    }}, [countdown, myProfile]);
    const onPress = useCallback((amount: number, setAndStart: boolean) => {
        const ref = interval.current;
        ref && clearInterval(ref);
        setPaused(!setAndStart);
        setAmount(amount);
        setAndStart && restartTimer();
    }, []);
    const {textArea} = useStyles();

    const resumePause = useCallback(() => {
        if (paused) {
            restartTimer();
            setPaused(false);        
        } else {
            const ref = interval.current;
            ref && clearInterval(ref); 
           setPaused(true);
        }
    }, [paused]);
    const login = useNavigateToLoginModal();
    const {method} = useLogout();
    const loginLogout = useCallback(async () => {
        if (myProfile) {
            method();
        } else {
            login();
        }
    }, [myProfile]);
    return <CustomColumn width100={true}>
        {elements}
        {uid === myUID && 
        <>
        <CountButtons onPress={onPress} />
        {countdown > 0 && <CustomRow justifyContent="center" width100={true} style={{marginTop: 20}}>
            <CustomRow style={textArea} justifyContent="center">
                <CustomRNEButton onPress={resumePause} title={!paused ? "Pause" : "Resume" } />
            </CustomRow>
        </CustomRow>}
        </>}
        <CustomRow width100={true} justifyContent="center" style={{marginTop: 150}}>
            <CustomRNEButton type={"clear"} title={myProfile ? "Logout" : "Login"} onPress={loginLogout}/>
        </CustomRow>
    </CustomColumn>
}