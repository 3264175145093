import { useAtom } from "jotai";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { DocumentChange } from "firebase/firestore";
import { FBaseModelDoc } from "../../Common/Interfaces/BaseDocs/FBaseModelDoc";
import { LocationTypes } from "../../Common/Interfaces/Location/LocationTypes";
import { isFDescriptorDoc } from "../../Common/is/isFDescriptorDoc";
import { unsubscribeFunction } from "../../FBActions/innerGetDocs2";
import { FBQueryDocumentSnapshot, FBQuerySnapshot } from "../../Mapping/firebaseMapping";
import { defaultSearchCenterQueryParamsDoc } from "../../Queries/defaultQueries/defaultSearchCenterQueryParamsDoc";
import AuthContext from "../Authentication/AuthContext";
import { serverParams } from "../JotaiStore";
import { listenToAll } from "../../FBActions/listenToAll";
import { ListQueryRequestDoc } from "../../Common/Interfaces/ServerDocs/ListQueryRequestDoc";
import FSearchCenter from "../../Common/Classes/Models/FSearchCenter";
import { queryParamsToQueryDoc } from "../../Common/Query/Conversion/queryParamsToQueryDoc";
import { logger } from "../logger";
import { fbDocSnapshotToFBaseModelDoc } from "../../RQ/fbDocSnapshotToFBaseModelDoc";
import { FBSnapshotChangeType } from "./FBSnapshotChangeType";

const locationName = LocationTypes.UserSearch;
export const useWatchSearchCenter = () => {
    const [searchCenterDocs, setSearchCenterDocs] = useState<FBaseModelDoc[]>([]);
    const [{authDoc, serverParamsDoc}] = useAtom(serverParams);
    const {myProfile} = useContext(AuthContext);
    const unsubscribeRef = useRef<unsubscribeFunction | null>(null);
    const next = useCallback((snapshot: FBQuerySnapshot) => {
        snapshot.docChanges().forEach((change: DocumentChange<DocumentChange>) => {
            if (change.type === FBSnapshotChangeType.added) {
                const documentSnapshot: FBQueryDocumentSnapshot = change.doc;
                if (documentSnapshot.exists) {
                    const doc: FBaseModelDoc = fbDocSnapshotToFBaseModelDoc(documentSnapshot)
                    if (doc) {
                        setSearchCenterDocs(previous => 
                            [doc, ...previous])
                    }
                }
            } else if (change.type === FBSnapshotChangeType.modified) {
                const documentSnapshot: FBQueryDocumentSnapshot = change.doc;
                if (documentSnapshot.exists) {
                    const doc: FBaseModelDoc = fbDocSnapshotToFBaseModelDoc(documentSnapshot)
                    if (doc) {
                        const fuid = doc.fuid || "";
                        setSearchCenterDocs(previous => {
                            return previous.map((candidate) => {
                                if (candidate.fuid === fuid) {
                                    return doc;
                                }
                                return candidate;
                            })
                        });
                    }
                }
            } else if (change.type === FBSnapshotChangeType.removed) {
                const documentSnapshot: FBQueryDocumentSnapshot = change.doc;
                if (documentSnapshot.exists) {
                    const doc: FBaseModelDoc = fbDocSnapshotToFBaseModelDoc(documentSnapshot)
                    if (doc) {
                        const fuid = doc.fuid || "";
                        setSearchCenterDocs(previous => {
                            return previous.filter((candidate) => candidate.fuid !== fuid) 
                        });
                    }
                }
            } else {
                logger.error("In useWatchSearchCenter, unchanged change type");
            }
        })
    }, []);
    const errorHandler = useCallback((incomingError: Error) => {
        logger.log("Got error from listening to SearchCenter", incomingError);
        const listeningRef = unsubscribeRef.current;
        unsubscribeRef.current = null;
        if (listeningRef) {
            listeningRef();
            setSearchCenterDocs([]);
        }
    }, []);
    const watch = useCallback(() => {
        const ref = unsubscribeRef.current;
        unsubscribeRef.current = null;
        ref && ref();
        setSearchCenterDocs([]);
        if (isFDescriptorDoc(myProfile)) {
            const queryParamsDoc = defaultSearchCenterQueryParamsDoc();
            queryParamsDoc.owner = myProfile;
            const requestDoc: ListQueryRequestDoc = FSearchCenter.getListRequestDoc(queryParamsToQueryDoc(authDoc, queryParamsDoc, serverParamsDoc));
            unsubscribeRef.current = listenToAll(
                authDoc,
                requestDoc,
                serverParamsDoc,
                next,
                errorHandler,
                () => {},
            )
        }
    }, [myProfile, authDoc, serverParamsDoc]);
    useEffect(() => {
        watch();
    }, [myProfile]);
    const restart = useCallback(() => {
        watch();
    }, []);
    useEffect(() => {
        return () => {
            const ref = unsubscribeRef.current;
            unsubscribeRef.current = null;
            ref && ref();
        }
    }, []);
    return {searchCenterDocs, restart}
}
