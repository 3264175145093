import FError from "../Common/ErrorHandling/FError";
import {ResponseCode} from "../Common/ErrorHandling/ResponseCode";
import {resolveFBPath} from "../Common/Functions/PathGeneration/resolveFBPath";
import {AuthDoc} from "../Common/Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import {UpdateDocumentRequestDoc} from "../Common/Interfaces/ServerDocs/UpdateDocumentRequestDoc";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import {firestore} from "../firebase/firestore";
import {FBCancelTokenSource} from "../Mapping/axiosMapping";
import {
  FBDocumentReference,
  FBDocumentSnapshot,
} from "../Mapping/firebaseMapping";
import {createCollectionRef} from "./createCollectionRef";
import {handleDocSnapshot} from "./handleDocSnapshot";

export const updateDocument = async (
  _authDoc: AuthDoc | null,
  requestDoc: UpdateDocumentRequestDoc,
  serverParams: ServerParamsDoc,
  _cancelTokenSource: FBCancelTokenSource,
): Promise<FBaseModelDoc[]> => {
  const {
    descriptorDoc: {modelPath, fuid},
    updateDoc: updates,
  } = requestDoc;
  const docRef: FBDocumentReference = firestore.doc(
    `${createCollectionRef(resolveFBPath(modelPath, serverParams))}/${fuid}`,
  );
  return docRef
    .update(updates)
    .then(() => {
      return docRef.get().then((snapshot: FBDocumentSnapshot) => {
        return handleDocSnapshot(snapshot);
      });
    })
    .catch(error => {
      const method = "fbActions.updateDocument";
      throw FError.fbErrortoFError(
        error,
        method,
        ResponseCode.InternalServerError,
      );
    });
};
