import { RNTextStyle, RNViewStyle, styleSheetCreate } from "../../../Mapping/styleSheetCreate";

const styleObj: {
    container: RNViewStyle,
    title: RNTextStyle,
    linkButton: RNViewStyle,
    linkText: RNTextStyle,
} = {
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
      },
      title: {
        fontSize: 20,
        fontWeight: 'bold',
      },
      linkButton: {
        marginTop: 15,
        paddingVertical: 15,
      },
      linkText: {
        fontSize: 14,
        color: '#2e78b7',
      },
}
const styles = styleSheetCreate(styleObj);
export const useStyles = () => styles;
  