import {FBQueryDoc} from "../Common/Interfaces/Query/FBQueryDoc";
import {isAnyProperty} from "../Common/is/isAnyProperty";
import {isNumber} from "../Common/is/isNumber";
import {
  FBCollectionReference,
  FBQuery,
  FBWhereFilterOp,
  FBOrderByDirection,
} from "../Mapping/firebaseMapping";
import { fbLimit, fbLimitToLast, fbOrderBy, fbStartAfter, fbStartAt, fbWhere, fbEndAt, fbEndBefore } from "../ReactSpecific/Firestore/fbQueryOperators";
import {createCollectionRef} from "./createCollectionRef";

// FirestoreErrorCode
// https://firebase.google.com/docs/firestore/query-data/order-limit-data

export const createQueryRef = (
  collectionPath: string[],
  queryDoc?: FBQueryDoc,
): FBCollectionReference | FBQuery => {
  let ref: FBCollectionReference | FBQuery =
    createCollectionRef(collectionPath);
  if (queryDoc !== undefined) {
    const {cursor, sortObjects, wheres} = queryDoc;
    if (Array.isArray(wheres) && wheres.length > 0) {
      wheres.map(whereItem => {
        const {path, operator, value} = whereItem;
        ref = fbWhere(ref,
          path,
          operator as FBWhereFilterOp,
          value,
        );
      });
    }
    if (sortObjects.length > 0) {
      sortObjects.map(obj => {
        const {path, order} = obj;
        ref = fbOrderBy(ref, path, order as FBOrderByDirection);
      });
    }

    if (isAnyProperty(cursor)) {
      const keys = Object.keys(cursor);
      if (keys.length > 0) {
        if (keys.length > 1) {
          console.error(
            "In listFBDoc, erroneous have more than one cursor",
            cursor,
          );
        }
        const key = keys[0];
        switch (key) {
          case "startAt":
            ref = fbStartAt(ref, cursor);
            break;
          case "startAfter":
            ref = fbStartAfter(ref, cursor);
            break;
          case "endBefore":
            ref = fbEndBefore(ref, cursor);
            break;
          case "endAt":
            ref = fbEndAt(ref, cursor);
            break;
          default:
            console.log("Got erroneous cursor field", key, cursor);
        }
      }
    }
    if (isNumber(queryDoc.limitToLast)) {
      ref = fbLimitToLast(ref, queryDoc.limitToLast);
    } else if (isNumber(queryDoc.limit)) {
      ref = fbLimit(ref, queryDoc.limit);
    }
    return ref;
  } else {
    return ref;
  }
};
