export enum ServerPrefix {
  admin = "/admin",
  auth = "/auth",
  business = "/business",
  location = "/location",
  none = "",
  order = "/order",
  phoneNunber = "/phoneNumber",
  product = "/product",
  profile = "/profile",
  rating = "/rating",
  album = "/album",
  stripe = "/stripe",
  stripewebhook = "/stripewebhook",
  error = "/error",
  proxy = "/proxy",
}
