import FError from "../Common/ErrorHandling/FError";
import {ResponseCode} from "../Common/ErrorHandling/ResponseCode";
import {
  ConfirmationResult,
  FBAuthUser,
  firebaseAuth,
  UserCredential,
} from "../Mapping/firebaseMapping";
import {Unsubscribe} from "../Common/Interfaces/Unsubscribe";
import {setPresenceOffline} from "../FBActions/Presence/presence2";

export const initializeAuth = (
  listener: (user: FBAuthUser | null) => void,
): Unsubscribe => {
  return firebaseAuth().onAuthStateChanged(listener);
};

export const currentUser = (): FBAuthUser | null => {
  return firebaseAuth().currentUser;
};
export const getCurrentUserUID = (): string | null => {
  const user: FBAuthUser | null = currentUser();
  return user ? user.uid : null;
};
export const getIdToken = (): Promise<string | null> => {
  const user: FBAuthUser | null = currentUser();
  if (user) {
    return user.getIdToken();
  }
  return Promise.resolve(null);
};
export const signInAnonymously = (): Promise<UserCredential> => {
  return firebaseAuth()
    .signInAnonymously()
    .catch(error => {
      console.log("Error logging in anonymouslyl", error);
      if (error.code === "auth/operation-not-allowed") {
        console.log("Enable anonymous in your firebase console.");
      }
      throw error;
    });
};
export const loginViaEmailAndPassword = (
  email: string,
  password: string,
): Promise<UserCredential> => {
  const method = "loginViaEmailAndPassword";
  return firebaseAuth()
    .signInWithEmailAndPassword(email, password)
    .catch(error => {
      console.log("FirebaseAuth Error code", error.code);
      console.log("FirebaseAuth Error Message", error.message);
      if (error.code === "auth/operation-not-allowed") {
        console.log("Enable anonymous in your firebase console.");
      }
      throw FError.fbErrortoFError(
        error,
        method,
        ResponseCode.InternalServerError,
      );
    });
};

export const passwordReset = (email: string): Promise<string> => {
  const method = "passwordReset";
  return firebaseAuth()
    .sendPasswordResetEmail(email, undefined)
    .then(() => {
      return `Password reset email sent to ${email}`;
    })
    .catch(error => {
      throw FError.fbErrortoFError(
        error,
        method,
        ResponseCode.InternalServerError,
      );
    });
};
export const signInWithPhoneNumber = (
  phoneNumber: string,
): Promise<ConfirmationResult> => {
  return firebaseAuth()
    .signInWithPhoneNumber(phoneNumber)
    .then((confirmation: ConfirmationResult) => {
      console.log("Phone confirmaiton", confirmation);
      return confirmation;
    })
    .catch(error => {
      if (error.code === "auth/operation-not-allowed") {
        console.log("Enable phone signin in your firebase console.");
      }
      throw error;
    });
};
export const logout = async (): Promise<void> => {
  const method = "logout";
  const uid = getCurrentUserUID();
  if (uid) {
    try {
      // await setPresenceOffline(uid);
      return firebaseAuth()
        .signOut()
        .catch(error => {
          throw FError.fbErrortoFError(error, method);
        });
    } catch (error) {
      console.log("Got error Logging Out");
      return Promise.resolve();
    }
  }
  return Promise.resolve();
};

export const createUserViaEmailAndPassword = (
  email: string,
  password: string,
): Promise<UserCredential> => {
  return firebaseAuth()
    .createUserWithEmailAndPassword(email, password)
    .then((userCredential: UserCredential) => {
      console.log(userCredential);
      return userCredential;
    })
    .catch(error => {
      const method = "createUserViaEmailAndPassword";
      console.log("FirebaseAuth Error code", error.code);
      console.log("FirebaseAuth Error Message", error.message);
      throw FError.fbErrortoFError(
        error,
        method,
        ResponseCode.InternalServerError,
      );
    });
};
