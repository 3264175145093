import React, { FC, type PropsWithChildren } from "react";
import { RNViewStyle } from "../../../Mapping/styleSheetCreate";
import { CustomContainerPrimative } from "../CustomContainerPrimative";

interface CustomRowInterface {
  justifyContent?: "center" | "flex-start" | "flex-end" | null;
  width100?: boolean;
  style?: RNViewStyle | RNViewStyle[];
}
export const CustomRow: FC<PropsWithChildren<CustomRowInterface>> = props => {
  return <CustomContainerPrimative {...props} row={true} />;
};
