import {FDescriptorDoc} from "../Common/Interfaces/BaseDocs/FDescriptorDoc";
import {arrayContains} from "./arrayContains";
import {isFDescriptorDoc} from "../Common/is/isFDescriptorDoc";
import {
  searchTermInterface,
  isSearchTermObj,
  isSearchTermInterface,
} from "./searchTermInterface";

export const removeSearchTerm = (
  descriptorKey: string,
  currentSearchTerms: searchTermInterface,
  params: {[property: string]: FDescriptorDoc},
): searchTermInterface => {
  const keys = Object.keys(params);
  if (keys.length > 0) {
    const key = keys[0];
    const obj = params[key];
    if (isSearchTermInterface(currentSearchTerms)) {
      const existingTerm = currentSearchTerms[key];
      if (isFDescriptorDoc(obj)) {
        if (isSearchTermObj(existingTerm)) {
          let existingArray: FDescriptorDoc[] = [];
          if (descriptorKey === "customers") {
            existingArray = existingTerm.customers || [];
          } else if (descriptorKey === "businesses") {
            existingArray = existingTerm.businesses || [];
          } else if (descriptorKey === "providers") {
            existingArray = existingTerm.providers || [];
          } else if (descriptorKey === "orders") {
            existingArray = existingTerm.orders || [];
          } else {
            console.log("Unhandled Key", descriptorKey);
          }
          let scrubbed: FDescriptorDoc[] = [];
          if (Array.isArray(existingArray)) {
            const index = arrayContains(existingArray, obj);
            if (index < 0) {
              scrubbed = existingArray;
            } else {
              existingArray.splice(index, 1);
              scrubbed = [...existingArray];
            }
          } else {
            scrubbed = [];
          }
          const newTerm = {...existingTerm, [descriptorKey]: scrubbed};
          currentSearchTerms[key] = newTerm;
          return {...currentSearchTerms};
        } else {
          return currentSearchTerms;
        }
      } else {
        return currentSearchTerms;
      }
    } else {
      return {};
    }
  }
  return currentSearchTerms || {};
};
