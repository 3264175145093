import {atom} from "jotai";
import {fakeServerParamsDoc} from "../Common/Functions/fakeServerParamsDoc";
import {AuthDoc} from "../Common/Interfaces/AuthDoc";
import {RouterRoles} from "../Common/Interfaces/Navigation/RouterRoles";
import {FBAuthUser} from "../Mapping/firebaseMapping";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import {FDescriptorDoc} from "../Common/Interfaces/BaseDocs/FDescriptorDoc";
import {FGeolocationDoc} from "../Common/Interfaces/Location/FGeolocationDoc";
import {addSearchTerm} from "./addSearchTerm";
import {
  isSearchTermInterface,
  JotaiDateTerm,
  searchTermInterface,
} from "./searchTermInterface";
import {removeSearchTerm} from "./removeSearchTerm";
import {OrderStatus} from "../Common/Interfaces/Models/OrderStatus";
import {innerUpdateOrderStatusTerm} from "./updateOrderStatusTerm";
import {innerUpdateStartDateTerm} from "./innerUpdateStartDateTerm";

export interface WorkingLocationType {
  parentDescriptor: FDescriptorDoc | null;
  locationName: string | null;
  locationDoc: FGeolocationDoc | null;
}
export const user = atom<FBAuthUser | null>(null);
export const showLoginModal = atom<boolean>(false);
export const activeRole = atom<RouterRoles>(RouterRoles.anonymous);
// export const myProfile = atom<FBaseModelDoc | null>(null);
export const userFuid = atom<string | null>(null);
export const businessSearchTerms = atom<FDescriptorDoc[]>([]);
export const customerSearchTerms = atom<FDescriptorDoc[]>([]);
// export const workingCustomerDescriptor = atom<FDescriptorDoc | null>(null);
export const customerMode = atom<boolean>(true);
// export const workingProductDescriptor = atom<FDescriptorDoc | null>(null);
export const workingLocation = atom<WorkingLocationType>({
  parentDescriptor: null,
  locationName: null,
  locationDoc: null,
});
export const serverParams = atom<{
  authDoc: AuthDoc | null;
  serverParamsDoc: ServerParamsDoc;
}>({
  authDoc: null,
  serverParamsDoc: fakeServerParamsDoc(),
});

export const searchTerms = atom<searchTermInterface>({});
export const removeSearchObj = atom(
  get => get(searchTerms),
  (get, set, key: string) => {
    const currentSearchTerms = get(searchTerms);
    if (isSearchTermInterface(currentSearchTerms)) {
      delete currentSearchTerms[key];
      set(searchTerms, {...currentSearchTerms});
    } else {
      set(searchTerms, {});
    }
  },
);
export const updateCustomerTerm = atom(
  get => get(searchTerms),
  (get, set, params: {[property: string]: FDescriptorDoc | null}) => {
    const terms = get(searchTerms);
    const descriptorKey = "customers";
    const newTerm = addSearchTerm(descriptorKey, terms, params);
    set(searchTerms, newTerm);
  },
);
export const updateOrderTerm = atom(
  get => get(searchTerms),
  (get, set, params: {[property: string]: FDescriptorDoc | null}) => {
    const terms = get(searchTerms);
    const descriptorKey = "orders";
    const newTerm = addSearchTerm(descriptorKey, terms, params);
    set(searchTerms, newTerm);
  },
);
export const removeCustomerTerm = atom(
  get => get(searchTerms),
  (get, set, params: {[property: string]: FDescriptorDoc}) => {
    const terms = get(searchTerms);
    const descriptorKey = "customers";
    const newTerm = removeSearchTerm(descriptorKey, terms, params);
    set(searchTerms, {...newTerm});
  },
);
export const removeOrderTerm = atom(
  get => get(searchTerms),
  (get, set, params: {[property: string]: FDescriptorDoc}) => {
    const terms = get(searchTerms);
    const descriptorKey = "orders";
    const newTerm = removeSearchTerm(descriptorKey, terms, params);
    set(searchTerms, {...newTerm});
  },
);
export const updateBusinessTerm = atom(
  get => get(searchTerms),
  (get, set, params: {[property: string]: FDescriptorDoc | null}) => {
    const terms = {...get(searchTerms)};
    const descriptorKey = "businesses";
    const newTerm = addSearchTerm(descriptorKey, terms, params);
    set(searchTerms, newTerm);
  },
);
export const updateBusinessCustomerProviderTerm = atom(
  get => get(searchTerms),
  (
    get,
    set,
    params: {
      [property: string]: {
        customer: FDescriptorDoc | null;
        business: FDescriptorDoc | null;
        provider: FDescriptorDoc | null;
        order: FDescriptorDoc | null;
        orderStatus: OrderStatus | null;
        startDate: JotaiDateTerm | null;
      };
    },
  ) => {
    const terms = {...get(searchTerms)};
    const keys = Object.keys(params);
    if (keys.length > 0) {
      const key = keys[0];
      const {customer, business, provider, orderStatus, startDate, order} =
        params[key];
      let tempParams = {[key]: business};
      let descriptorKey = "businesses";
      let newTerm = addSearchTerm(descriptorKey, terms, tempParams);
      tempParams = {[key]: customer || null};
      descriptorKey = "customers";
      newTerm = addSearchTerm(descriptorKey, newTerm, tempParams);
      tempParams = {[key]: provider || null};
      descriptorKey = "providers";
      newTerm = addSearchTerm(descriptorKey, newTerm, tempParams);
      tempParams = {[key]: order || null};
      descriptorKey = "orders";
      newTerm = addSearchTerm(descriptorKey, newTerm, tempParams);
      const orderStatusParams = {[key]: orderStatus || null};
      newTerm = innerUpdateOrderStatusTerm(newTerm, orderStatusParams);
      const startDateParams = {[key]: startDate};
      newTerm = innerUpdateStartDateTerm(newTerm, startDateParams);
      set(searchTerms, newTerm);
    }
  },
);
export const removeBusinessTerm = atom(
  get => get(searchTerms),
  (get, set, params: {[property: string]: FDescriptorDoc}) => {
    const terms = {...get(searchTerms)};
    const descriptorKey = "businesses";
    const newTerm = removeSearchTerm(descriptorKey, terms, params);
    set(searchTerms, newTerm);
  },
);
export const updateProviderTerm = atom(
  get => get(searchTerms),
  (get, set, params: {[property: string]: FDescriptorDoc | null}) => {
    const terms = {...get(searchTerms)};
    const descriptorKey = "providers";
    const newTerm = addSearchTerm(descriptorKey, terms, params);
    set(searchTerms, newTerm);
  },
);
export const updateOrderStatusTerm = atom(
  get => get(searchTerms),
  (get, set, params: {[property: string]: OrderStatus | null}) => {
    const terms = {...get(searchTerms)};
    const newTerm = innerUpdateOrderStatusTerm(terms, params);
    set(searchTerms, newTerm);
  },
);

export const updateStartDate = atom(
  get => get(searchTerms),
  (get, set, params: {[property: string]: JotaiDateTerm | null}) => {
    const terms = {...get(searchTerms)};
    const newTerm = innerUpdateStartDateTerm(terms, params);
    set(searchTerms, newTerm);
  },
);

export const removeProviderTerm = atom(
  get => get(searchTerms),
  (get, set, params: {[property: string]: FDescriptorDoc}) => {
    const terms = {...get(searchTerms)};
    const descriptorKey = "providers";
    const newTerm = removeSearchTerm(descriptorKey, terms, params);
    set(searchTerms, newTerm);
  },
);
/*
const hope: QueryFunction<FBaseModelDoc | null, QueryKey> = (
  stuff: QueryFunctionContext<QueryKey, any>,
): Promise<FBaseModelDoc | null> => {
  console.log(stuff);
  return Promise.resolve(null);
};
hope({
  queryKey: ["string"],
  meta: undefined,
  // signal,
  pageParam: 8,
});
*/

/*
export const updateCustomerTerm = atom(
  get => get(searchTerms),
  async (get, set, params: AnyProperty) => {
    const terms = {...get(searchTerms)};
    const newKey = "customers";
    const keys = Object.keys(params);
    if (keys.length > 0) {
      const key = keys[0];
      const obj = params[key];
      if (isAnyProperty(terms)) {
        const existingTerm = terms[key];
        console.log("updating Customer", existingTerm);
        if (isFDescriptorDoc(obj)) {
          if (isAnyProperty(existingTerm)) {
            const existingArray = existingTerm[newKey];
            let scrubbed: FDescriptorDoc[] = [];
            if (Array.isArray(existingArray)) {
              const index = arrayContains(
                existingArray as FDescriptorDoc[],
                obj,
              );
              console.log("Index", index);
              if (index < 0) {
                (existingArray as FDescriptorDoc[]).push(obj);
                scrubbed = existingArray as FDescriptorDoc[];
              } else {
                scrubbed = existingArray as FDescriptorDoc[];
              }
            } else {
              scrubbed = [obj];
            }
            const newTerm = {...existingTerm, [newKey]: scrubbed};
            terms[key] = newTerm;
            set(searchTerms, terms);
          } else {
            terms[key] = {[newKey]: [obj]};
            set(searchTerms, terms);
          }
        }
      } else {
        set(searchTerms, {[key]: {[newKey]: obj}});
      }
    }
  },
);
*/
