import FError from "../Common/ErrorHandling/FError";
import {ResponseCode} from "../Common/ErrorHandling/ResponseCode";
import {AuthDoc} from "../Common/Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import {
  CreateNewOrderFromExistingOrderRequestDoc,
  // eslint-disable-next-line max-len
} from "../Common/Interfaces/ServerDocs/Order/CreateNewOrderFromExistingOrderRequestDoc";
import {ServerErrorCodes} from "../Common/Interfaces/ServerDocs/ServerErrorCodes";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";

const method = "FBActions.createNewOrderFromExistingOrder";
export const createNewOrderFromExistingOrder = async (
  authDoc: AuthDoc | null,
  requestDoc: CreateNewOrderFromExistingOrderRequestDoc,
  serverParamsDoc: ServerParamsDoc,
): Promise<FBaseModelDoc[]> => {
  console.log(authDoc, requestDoc, serverParamsDoc);
  const message = `Do not use ${method} on server`;
  console.log(message);
  throw new FError({
    message,
    method,
    responseCode: ResponseCode.InternalServerError,
    code: ServerErrorCodes.InvalidServerCall,
  });
};
