import React, {FC} from "react";
import { View } from "react-native";
import { buttonObj } from "..";
import { CustomRow } from "../../../../Client/Custom/CustomRow";
import { CountButton } from "../../CountButton";

interface ButtonRowProps {
    buttons: buttonObj[];
    onPress: (amount: number, setAndStart: boolean) => void;
}
export const ButtonRow: FC<ButtonRowProps> = props => {
    const {buttons, onPress} = props;
    return <>{buttons.map((obj) => {
        return (<CustomRow width100={true} justifyContent="center" key={obj.title}>
            <CountButton title={obj.title} amount={obj.amount} onPress={onPress} setAndStart={true}/>
            <View style={{width: 50, height: 20}}/>
            <CountButton title={obj.title} amount={obj.amount} onPress={onPress} setAndStart={false}/>
        </CustomRow>)
    })}
    </>
}