import { Input } from "@rneui/themed";
import React, {
  FC,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Keyboard, TextInput } from "react-native";
import { useCommonStyles } from "../../../CommonStyles/useCommonStyles";
import { CustomRow } from "../../../Custom/CustomRow";
import { CustomText } from "../../../Custom/CustomText";
import { ThemedView } from "../../../ThemedComponents/ThemedView";
import { useLookupUserPhoneNumber } from "../../../Use/useLookupPhoneNumber";
import { NumberState } from "./NumberState";

import { useStyles } from "./useStyles";
import {
  numbersOnly,
  validatePhoneComponents,
} from "./validatePhoneComponents";

interface PhoneInputProps extends PropsWithChildren {
  numberState: NumberState;
  setNewNumber: (newNumber: NumberState) => void;
  setValid: (valid: boolean) => void;
}
const disabled = false;
const keyboardType = "numeric";
export const PhoneInput: FC<PhoneInputProps> = props => {
  const { setValid, setNewNumber, numberState } = props;
  const styles = useStyles();
  const {
    method: lookupPhone,
    response: phoneResponse,
    success: phoneSuccess,
  } = useLookupUserPhoneNumber();
  const determineValid = useCallback(
    (
      // eslint-disable-next-line @typescript-eslint/no-shadow
      areaCode: string,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      prefix: string,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      suffix: string,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      title: string,
    ): boolean => {
      return (
        validatePhoneComponents(areaCode, prefix, suffix) && title.length > 2
      );
    },
    [],
  );
  useEffect(() => {
    const { areaCode, prefix, suffix, title } = numberState;
    determineValid(areaCode, prefix, suffix, title) &&
      lookupPhone(`+1${areaCode}${prefix}${suffix}`);
  }, [determineValid, lookupPhone, numberState]);

  const errorMessage = useMemo(() => {
    if (
      phoneSuccess &&
      Array.isArray(phoneResponse) &&
      phoneResponse.length > 0
    ) {
      const { exists } = phoneResponse[0];
      return exists ? "Phone Number Exists" : undefined;
    }
    return undefined;
  }, [phoneResponse, phoneSuccess]);
  useEffect(() => {
    if (
      phoneSuccess &&
      Array.isArray(phoneResponse) &&
      phoneResponse.length > 0
    ) {
      const { exists } = phoneResponse[0];
      if (!exists) {
        const { areaCode, prefix, suffix, title } = numberState;
        setValid(determineValid(areaCode, prefix, suffix, title));
        return;
      }
    }
    setValid(false);
  }, [setValid, determineValid, phoneSuccess, phoneResponse, numberState]);

  const onChangeAreaCode = useCallback(
    (newValue: string) => {
      if (numbersOnly.test(newValue) && newValue.length < 4) {
        if (newValue.length < 4) {
          setNewNumber({ ...numberState, areaCode: newValue });
        }
        if (newValue.length >= 3) {
          prefixRef.current && prefixRef.current.focus();
        }
      }
    },
    [numberState, setNewNumber],
  );
  const onChangePrefix = useCallback(
    (newValue: string) => {
      if (numbersOnly.test(newValue)) {
        if (newValue.length < 4) {
          setNewNumber({ ...numberState, prefix: newValue });
        }
        if (newValue.length >= 3) {
          suffixRef.current && suffixRef.current.focus();
        }
      }
    },
    [numberState, setNewNumber],
  );
  const onChangeSuffix = useCallback(
    (newValue: string) => {
      if (numbersOnly.test(newValue)) {
        if (newValue.length < 5) {
          setNewNumber({ ...numberState, suffix: newValue });
        }
        if (newValue.length >= 4) {
          Keyboard.dismiss();
        }
      }
    },
    [numberState, setNewNumber],
  );
  const prefixRef = useRef<TextInput | null>(null);
  const suffixRef = useRef<TextInput | null>(null);
  const {
    error: errorStyle,
    inputLabel,
    inputContainer,
    input,
  } = useCommonStyles();
  return (
    <>
      <CustomRow width100={true}>
        <ThemedView style={styles.areaCode}>
          <Input
            errorStyle={errorStyle}
            inputStyle={input}
            keyboardType={keyboardType}
            labelStyle={inputLabel}
            value={numberState.areaCode}
            label={"Area Code"}
            disabled={disabled}
            autoCapitalize={"none"}
            autoFocus={true}
            errorMessage={undefined}
            placeholder={"650"}
            onChangeText={onChangeAreaCode}
            inputContainerStyle={inputContainer}
          />
        </ThemedView>
        <ThemedView style={styles.prefix}>
          <Input
            errorStyle={errorStyle}
            inputStyle={input}
            keyboardType={keyboardType}
            labelStyle={inputLabel}
            value={numberState.prefix}
            label={"Prefix"}
            disabled={disabled}
            autoCapitalize={"none"}
            autoFocus={false}
            errorMessage={undefined}
            placeholder={"387"}
            onChangeText={onChangePrefix}
            inputContainerStyle={inputContainer}
            ref={prefixRef}
          />
        </ThemedView>
        <ThemedView style={styles.prefix}>
          <Input
            errorStyle={errorStyle}
            inputStyle={input}
            keyboardType={keyboardType}
            labelStyle={inputLabel}
            value={numberState.suffix}
            label={"Suffix"}
            disabled={disabled}
            autoCapitalize={"none"}
            autoFocus={false}
            errorMessage={undefined}
            placeholder={"1234"}
            onChangeText={onChangeSuffix}
            inputContainerStyle={inputContainer}
            ref={suffixRef}
          />
        </ThemedView>
      </CustomRow>
      {errorMessage && (
        <CustomRow justifyContent={"center"} width100={true}>
          <CustomText type={"regular"} style={errorStyle}>
            {errorMessage}
          </CustomText>
        </CustomRow>
      )}
    </>
  );
};
