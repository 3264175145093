import React, { FC, type PropsWithChildren } from "react";
import { useStyles } from "./useStyles";
import { ThemedView } from "../../ThemedComponents/ThemedView";
import { RNViewStyle } from "../../../Mapping/styleSheetCreate";

interface CustomTextProps {
  style?: RNViewStyle | undefined;
}
export const FlexGrow: FC<PropsWithChildren<CustomTextProps>> = props => {
  const {style} = props;
  const { grow } = useStyles();
  return <ThemedView style={[grow, style]} />;
};
