// import {getDoc} from "firebase/firestore";
// import FError from "../Common/ErrorHandling/FError";
// import {ResponseCode} from "../Common/ErrorHandling/ResponseCode";
import {AuthDoc} from "../Common/Interfaces/AuthDoc";
import {FBaseModelDoc} from "../Common/Interfaces/BaseDocs/FBaseModelDoc";
import {GetDocumentRequestDoc} from "../Common/Interfaces/ServerDocs/GetDocumentRequestDoc";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import {FBCancelTokenSource} from "../Mapping/axiosMapping";
// import {FBDocumentSnapshot} from "../Mapping/firebaseMapping";
// import {createDocRef} from "./createDocRef";
import {getDocumentHTTP} from "./getDocumentHTTP";
// import {handleDocSnapshot} from "./handleDocSnapshot";

export const getDocument = async (
  authDoc: AuthDoc | null,
  requestDoc: GetDocumentRequestDoc,
  serverParamsDoc: ServerParamsDoc,
  cancelTokenSource: FBCancelTokenSource,
): Promise<FBaseModelDoc[]> => {
  return getDocumentHTTP(
    authDoc,
    requestDoc,
    serverParamsDoc,
    cancelTokenSource,
  );
  /*
  const {descriptorDoc: {modelPath, fuid}} = requestDoc;
    return getDoc(createDocRef(modelPath, fuid, serverParams))
        .then((snapshot: FBDocumentSnapshot) => {
            return handleDocSnapshot(snapshot)
        })
        .catch((error) => {
            const method = "fbActions.getDocument";
            throw FError
                .fbErrortoFError(error, method, ResponseCode.InternalServerError);
        });
        */
};
