import {FBQueryDoc} from "../../../Interfaces/Query/FBQueryDoc";
import {milesToMetersConversion} from "../../../../config/distanceConversion";

export const createAlgoliaRadius = (doc: FBQueryDoc): number | null => {
  const {radiusInMiles} = doc;
  if (radiusInMiles) {
    return Math.round(radiusInMiles * milesToMetersConversion);
  }
  return null;
};
