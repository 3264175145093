/* eslint-disable no-invalid-this */
import {FBCancelTokenSource} from "../../../Mapping/axiosMapping";
import {dbActions} from "../../Functions/dbActions";
import {AuthDoc} from "../../Interfaces/AuthDoc";
import {FBaseModelDoc} from "../../Interfaces/BaseDocs/FBaseModelDoc";
import {FGeolocationDoc} from "../../Interfaces/Location/FGeolocationDoc";
import {FGeolocationDocObj} from "../../Interfaces/Location/FGeolocationDocObj";
import {FLatLngDoc} from "../../Interfaces/Location/FLatLngDoc";
import {GoogleLatLngLiteral} from "../../Interfaces/Location/GoogleLatLngLiteral";
import {LocationTypes} from "../../Interfaces/Location/LocationTypes";
import {FBaseModelWithLocationInterface} from "../../Interfaces/Models/FBaseModelWithLocationInterface";
import {ModelFields} from "../../Interfaces/Models/ModelFields";
import {AddOrRemove} from "../../Interfaces/ServerDocs/AddOrRemove";
import {UpdateLocationRequestDoc} from "../../Interfaces/ServerDocs/General/UpdateLocationRequestDoc";
import {UpdateLocationNameRequestDoc} from "../../Interfaces/ServerDocs/Location/UpdateLocationNameRequestDoc";
import {SeriesAmount} from "../../Interfaces/ServerDocs/Order/SeriesAmount";
import {ServerParamsDoc} from "../../Interfaces/ServerParamsDoc";
import {isFGeoLocationDocObj} from "../../is/isFGeolocationDocObj";
import {isNumber} from "../../is/isNumber";
import FBaseModel from "./FBaseModel";
import {mapFGeolocationDocObjToFLatLngDocs} from "./mapFGeolocationDocObjToFLatLngDocs";

/* eslint-disable require-jsdoc */
class FBaseModelWithLocation
  extends FBaseModel
  implements FBaseModelWithLocationInterface
{
  get geoLocs(): FLatLngDoc[] {
    const candidate = this.getArrayValue(ModelFields.geoLocs);
    if (Array.isArray(candidate)) {
      return candidate as unknown as FLatLngDoc[];
    }
    const array: FLatLngDoc[] = [];
    this.setArrayValue(ModelFields.geoLocs, array);
    return array;
  }
  set geoLocs(array: FLatLngDoc[]) {
    this.setArrayValue(ModelFields.geoLocs, array);
  }
  addGeoLoc(geoLoc: FLatLngDoc): void {
    this.geoLocs = [...this.geoLocs, geoLoc];
  }
  getLatLng = (index = 0): GoogleLatLngLiteral | null => {
    const locs = this.geoLocs;
    if (locs.length > 0 && index < locs.length) {
      const doc: FLatLngDoc = locs[index];
      const {lat, lng} = doc;
      return {lat, lng};
    }
    return null;
  };
  get locations(): FGeolocationDocObj {
    const candidate = this.getObjectValue(ModelFields.locations);
    if (isFGeoLocationDocObj(candidate)) {
      return candidate;
    }
    const obj: FGeolocationDocObj = {};
    this.locations = obj;
    return obj;
  }
  set locations(obj: FGeolocationDocObj) {
    this.setObjectValue(ModelFields.locations, obj);
    this.geoLocs = mapFGeolocationDocObjToFLatLngDocs({...this.locations});
  }
  addLocation(
    locationName: LocationTypes | string,
    location: FGeolocationDoc,
  ): boolean {
    if (Object.keys(this.locations).length > 10) {
      console.log("Attempted to have more than 10 locations. Ignored");
      return false;
    }
    this.locations = {...this.locations, [locationName]: location};
    return true;
  }
  get distance(): number | null {
    const candidate = this.currentDoc[ModelFields.distance];
    if (isNumber(candidate)) {
      return candidate;
    }
    return null;
  }
  set distance(value: number | null) {
    this.currentDoc[ModelFields.distance] = value;
  }
  formattedAddress = (locationName: LocationTypes | string): string | null => {
    const location = this.locations[locationName];
    return location ? location.formattedAddress || null : null;
  };
  city = (locationName: LocationTypes | string): string | null => {
    const location = this.locations[locationName];
    return location ? location.city || null : null;
  };
  county = (locationName: LocationTypes | string): string | null => {
    const location = this.locations[locationName];
    return location ? location.county || null : null;
  };
  state = (locationName: LocationTypes | string): string | null => {
    const location = this.locations[locationName];
    return location ? location.state || null : null;
  };
  setLocationWithGeolocationDoc = (
    locationName: LocationTypes | string,
    doc: FGeolocationDoc,
  ): void => {
    this.addLocation(locationName, doc);
  };
  location = (locationName: LocationTypes | string): FGeolocationDoc | null => {
    return this.locations[locationName] || null;
  };
  updateLocation = async (
    authDoc: AuthDoc | null,
    locationName: LocationTypes | string,
    locationDoc: FGeolocationDoc,
    serverParamsDoc: ServerParamsDoc,
    addOrRemove: AddOrRemove,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    if (this.descriptorDoc) {
      const requestDoc: UpdateLocationRequestDoc = {
        locationName,
        locationDoc,
        targetDescriptorDoc: this.descriptorDoc,
        addOrRemove,
      };
      return dbActions.updateLocation(
        authDoc,
        requestDoc,
        serverParamsDoc,
        cancelTokenSource,
      );
    } else {
      return Promise.resolve([]);
    }
  };
  updateLocationName = async (
    authDoc: AuthDoc | null,
    oldLocationName: LocationTypes | string,
    newLocationName: LocationTypes | string,
    seriesAmount: SeriesAmount,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> => {
    if (this.descriptorDoc) {
      const requestDoc: UpdateLocationNameRequestDoc = {
        oldLocationName,
        newLocationName,
        targetDescriptorDoc: this.descriptorDoc,
        seriesAmount,
      };
      return dbActions.updateLocationName(
        authDoc,
        requestDoc,
        serverParamsDoc,
        cancelTokenSource,
      );
    } else {
      return Promise.resolve([]);
    }
  };
}

export default FBaseModelWithLocation;
