import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React, { FC, useEffect } from "react";
import { RootStackParamList } from "../RootStackParamList";
import { ScreenNames } from "../ScreenNames";
import BottomTabNavigator from "../../../Pages/BottomTabNavigator";
import NotFoundPage from "../../../Pages/NotFoundPage";
import { LoginModalPage } from "../../../Pages/LoginModalPage";
import colors from "../../../../config/UI/colors";
import { SearchCenterContextProvider } from "../../../components/SearchCenterContext/SearchCenterContextProvider";
import { PVLandingPage } from "../../../../PV/LandingPage";


const Stack = createNativeStackNavigator<RootStackParamList>();

export const RootNavigator: FC = () => {
    return (
      <SearchCenterContextProvider>
        <Stack.Navigator
          screenOptions={{
            headerTintColor: colors.blue,
            headerStyle: {
              // backgroundColor: colors.white,
            },
            headerTitleStyle: {
              color: colors.blue,
            },
          }}
        >
          <Stack.Screen name={ScreenNames.Home} component={PVLandingPage} options={{ 
            headerShown: false,
          }} />
          <Stack.Screen name={ScreenNames.NotFound} component={NotFoundPage} options={{ title: 'Oops!' }} />
          <Stack.Group screenOptions={{ presentation: 'modal' }}>
            <Stack.Screen name={ScreenNames.LoginModal} component={LoginModalPage} options={{ headerShown: false}} />
          </Stack.Group>
        </Stack.Navigator>
        </SearchCenterContextProvider>
      );
}