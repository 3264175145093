import { useAtom } from "jotai";
import { useCallback, useEffect, useRef, useState } from "react";
import { serverParams } from "../JotaiStore";
import { FBCancelTokenSource } from "../../Mapping/axiosMapping";
import CancelError from "../../Common/ErrorHandling/CancelError";
import { dbActions } from "../../Common/Functions/dbActions";
import { emailLookupResponseDoc } from "../../Common/Interfaces/ServerDocs/Auth/emailLookupResponseDoc";
import { isString } from "../../Common/is/isString";
import { getCancelTokenSource } from "../../FAxios/getCancelTokenSource";

type StateType = {
  error: Error | null;
  loading: boolean;
  response: emailLookupResponseDoc[];
  success: boolean;
};
const defaultState: StateType = {
  error: null,
  loading: false,
  response: [],
  success: false,
};

export const useEmailLookup = () => {
  const tokenRef = useRef<FBCancelTokenSource | null>(null);
  const cancelRequest = useCallback(() => {
    const token = tokenRef.current;
    token && token.cancel();
    tokenRef.current = null;
  }, []);
  useEffect(() => {
    return () => {
      cancelRequest();
    };
  }, [cancelRequest]);
  const [{ authDoc, serverParamsDoc }] = useAtom(serverParams);
  const [{ error, loading, response, success }, setState] = useState<StateType>(
    {
      ...defaultState,
    },
  );
  const method = useCallback(
    async (emailCandidate: string) => {
      if (isString(emailCandidate) && emailCandidate.length > 4) {
        cancelRequest();
        const cancelTokenSource = getCancelTokenSource();
        tokenRef.current = cancelTokenSource;
        setState(previous => ({
          ...previous,
          loading: false,
          success: false,
        }));
        try {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const response = await dbActions.lookupEmail(
            authDoc,
            { email: emailCandidate },
            serverParamsDoc,
            cancelTokenSource,
          );
          const ref = tokenRef.current;
          tokenRef.current = null;
          if (ref) {
            setState(previous => ({
              ...previous,
              loading: false,
              success: true,
              response,
            }));
          }
        } catch (incomingError) {
          const ref = tokenRef.current;
          tokenRef.current = null;
          if (ref) {
            if (!(incomingError instanceof CancelError)) {
              setState(previous => ({
                ...previous,
                loading: false,
                success: false,
                error: incomingError as Error,
              }));
            }
          }
        }
      } else {
        setState(previous => ({
          ...previous,
          loading: false,
          success: true,
          response: [{ email: "", validEmail: false, exists: false }],
          error: null,
        }));
      }
    },
    [authDoc, cancelRequest, serverParamsDoc],
  );
  return {
    method,
    loading,
    error,
    success,
    response,
  };
};
