import { StatusBar } from 'expo-status-bar';
import React, { FC } from "react";
import { Platform } from "react-native";
import { useStyles } from "./useStyles";
import { ThemedView } from "../../ThemedComponents/ThemedView";
import { LoginService } from '../../Services/LoginService';
import { LoginHeaderArea } from './LoginHeaderArea';

export const LoginModalPage: FC = () => {
    const {container } = useStyles();
    return (
      <ThemedView style={container}>
        <LoginHeaderArea title=""/>
        <LoginService />
        {/* Use a light status bar on iOS to account for the black space above the modal */}
        <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
      </ThemedView>
    );
  }