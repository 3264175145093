import React, { FC, useContext, type PropsWithChildren } from "react";
import { ColorSchemeName, useWindowDimensions } from 'react-native';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import LinkingConfiguration from "./LinkingConfiguration";
import { RootNavigator } from "./RootNavigator";
import { ThemeContext } from "@rneui/themed";
import FirebaseAuthentication from "../../Authentication/FirebaseAuthentication";
import { stripeConfig } from "../../../config/stripeConfig";
import { AppStripeProvider } from "../../../ReactSpecific/AppStripeProvider";
import { ThemedView } from "../../ThemedComponents/ThemedView";
import { maxWidth } from "../../../config/UI/maxWidth";
import { CustomRow } from "../../Custom/CustomRow";

export const AppNavigationContainer: FC<PropsWithChildren> = props =>{
    const { colorScheme } = useContext(ThemeContext) as unknown as {colorScheme: ColorSchemeName}
    const dimensions = useWindowDimensions();
    return (
        <CustomRow width100={true} justifyContent="center" style={{height: dimensions.height}}>
        <ThemedView style={{height: dimensions.height, width: dimensions.width > maxWidth ? maxWidth : dimensions.width}}>
        <NavigationContainer
            linking={LinkingConfiguration}
            theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
        >
            <FirebaseAuthentication>
                <AppStripeProvider
                  publishableKey={stripeConfig.production.publicKey}
                  // urlScheme="your-url-scheme" // required for 3D Secure and bank redirects
                  merchantIdentifier="merchant.app.shyne" // required for Apple Pay
                >
                    <RootNavigator />
                </AppStripeProvider>
            </FirebaseAuthentication>
        </NavigationContainer>
        </ThemedView>
        </CustomRow>
    );
}