import {AlgoliaIndexBase} from "../../../config/AlgoliaIndexBase";
import {FBaseModelDoc} from "../BaseDocs/FBaseModelDoc";
import {FDescriptorDoc} from "../BaseDocs/FDescriptorDoc";
import {ImageTypes} from "../ImageTypes";
import {LocationTypes} from "../Location/LocationTypes";
import {FSearchCenterInterface} from "../Models/FSearchCenterInterface";
import {ModelFields} from "../Models/ModelFields";
import {ModelTypes} from "../Models/ModelTypes";
import {OrderStatus} from "../Models/OrderStatus";
import {FBCursor} from "./FBCursor";
import {FBSortObject} from "./FBSortObject";
import {FBSortOrder} from "./FBSortOrder";
import {FBWhere} from "./FBWhere";

export enum QueryDB {
  firebase = "firebase",
  algolia = "algolia",
}

export interface AlgoliaSortObject extends FBWhere {
  order: FBSortOrder;
  constraint?: number | null;
}
export enum AssignmentMode {
  assigned = "assigned",
  unassigned = "unassigned",
}
export interface QueryParamsDoc {
  queryDB: QueryDB;
  modelType: ModelTypes;
  business: FDescriptorDoc | null;
  parent?: FDescriptorDoc | null;
  superParent?: FDescriptorDoc | null;
  customer: FDescriptorDoc | null;
  creating?: boolean;
  order?: FDescriptorDoc | null;
  owner: FDescriptorDoc | null;
  recipient?: FDescriptorDoc | null;
  queryText: string | string[];
  cancelled?: boolean | null;
  submitted?: boolean | null;
  visible?: boolean | null;
  editing?: boolean | null;
  limit: number;
  cursor: FBCursor | null;
  limitToLast?: number | undefined;
  page: number;
  sortObjects: FBSortObject[];
  algoliaIndexBase: AlgoliaIndexBase | null;
  searchCenterDoc: FBaseModelDoc | null;
  searchCenter: FSearchCenterInterface | null;
  locationName: LocationTypes | null;
  sender?: FDescriptorDoc | null;
  series?: FDescriptorDoc | null;
  room?: FDescriptorDoc | null;
  target?: FDescriptorDoc | null;
  follower?: FDescriptorDoc | null;
  following?: FDescriptorDoc | null;
  orderStatus?: OrderStatus | null;
  areaCode?: string | null;
  prefix?: string | null;
  suffix?: string | null;
  algoliaSortObject?: AlgoliaSortObject;
  providers?: FDescriptorDoc[];
  assignmentMode?: AssignmentMode | null;
  timestamp?: number | null;
  searchableStartTime?: number | null;
  published?: boolean;
  album?: boolean;
  imageType?: ImageTypes;
  provider?: FDescriptorDoc | null;
  distributionTime?: number | null;
  createdAt?: number | null;
  paid?: boolean | null;
  requestTime?: number | null;
  invoice?: FDescriptorDoc | null;
  primary?: boolean;
}

export const defaultQueryParamsDoc = (): QueryParamsDoc => {
  return {
    queryDB: QueryDB.firebase,
    business: null,
    customer: null,
    modelType: ModelTypes.BaseModel,
    owner: null,
    queryText: "",
    submitted: undefined,
    visible: undefined,
    limit: 100,
    cursor: null,
    limitToLast: undefined,
    page: 0,
    sortObjects: [
      {
        path: [ModelFields.createdAt],
        order: FBSortOrder.asc,
      },
    ],
    algoliaIndexBase: null,
    searchCenterDoc: null,
    searchCenter: null,
    locationName: null,
  };
};

const doc: QueryParamsDoc = {
  queryDB: QueryDB.algolia,
  modelType: ModelTypes.BaseModel,
  business: null,
  parent: undefined,
  superParent: null,
  customer: null,
  creating: false,
  order: null,
  owner: null,
  recipient: undefined,
  queryText: "",
  cancelled: null,
  submitted: null,
  visible: null,
  editing: null,
  limit: 0,
  cursor: null,
  limitToLast: undefined,
  page: 0,
  sortObjects: [],
  algoliaIndexBase: null,
  searchCenterDoc: null,
  searchCenter: null,
  locationName: null,
  sender: null,
  series: null,
  room: null,
  target: null,
  follower: null,
  following: null,
  orderStatus: null,
  areaCode: null,
  prefix: null,
  suffix: null,
  algoliaSortObject: undefined,
  providers: undefined,
  assignmentMode: null,
  timestamp: null,
  searchableStartTime: null,
  published: undefined,
  album: undefined,
  imageType: undefined,
};

export const queryParamsKeys: (keyof QueryParamsDoc)[] = Object.keys(
  doc,
).sort() as unknown as (keyof QueryParamsDoc)[];
