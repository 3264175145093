import colors from "../../config/UI/colors";
import { RNTextStyle, RNViewStyle, styleSheetCreate } from "../../Mapping/styleSheetCreate";
import { textColors } from "../Custom/CustomText/useStyles";


const styleObj: {
  error: RNTextStyle;
  input: RNTextStyle;
  italicized: RNTextStyle;
  inputContainer: RNViewStyle;
  inputLabel: RNViewStyle;
  column: RNViewStyle;
  row: RNViewStyle;
  justifyCenter: RNViewStyle;
  width100: RNViewStyle;
  flexGrow: RNViewStyle;
  regularFont: RNTextStyle;
  smallFont: RNTextStyle;
  largeFont: RNTextStyle;
  errorColor: RNTextStyle;
  spaceAround: RNViewStyle;
  underline: RNTextStyle;
} = {
  italicized: { fontStyle: "italic" },
  flexGrow: { flexGrow: 1 },
  width100: { width: "100%" },
  error: { color: "red" },
  input: { width: 50, color: textColors.standard.primary, paddingLeft: 10 },
  inputContainer: {
    marginLeft: 7,
    marginRight: 7,
    borderWidth: 1,
    borderRadius: 10,
    borderStyle: "solid",
    marginBottom: 0,
    paddingBottom: 0,
  },
  inputLabel: {
    marginLeft: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  regularFont: {
    fontSize: 20,
    color: colors.baseColor,
  },
  largeFont: {
    fontSize: 20,
  },
  smallFont: {
    fontSize: 16,
    color: colors.baseColor,
  },
  errorColor: { color: "red" },
  spaceAround: {justifyContent: "space-around"},
  underline: { textDecorationLine: "underline" },
};

const styles = styleSheetCreate(styleObj);
export const useCommonStyles = () => styles;
