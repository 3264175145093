export enum RouterRoles {
  anonymous = "anonymous",
  generic = "customer",
  provider = "businessprovider",
  manager = "businessmanager",
  owner = "businessowner",
  admin = "admin",
}

export const RouterRolesArray = [
  RouterRoles.anonymous,
  RouterRoles.generic,
  RouterRoles.provider,
  RouterRoles.manager,
  RouterRoles.owner,
  RouterRoles.admin,
];
