import { DataSnapshot, onValue, ref, set } from "firebase/database";
import { unsubscribeFunction } from "../../FBActions/innerGetDocs2";
import { realtimedb, RTDBReference } from "../../Mapping/firebaseMapping";

const rtTimerPath = `/production/timer`;
export const RTTimerRef = (): RTDBReference => {
  return ref(realtimedb(), rtTimerPath);
};
export const subscribeToTimer = (callback: (snapshot: DataSnapshot) => void): unsubscribeFunction => {
  
  return onValue(RTTimerRef(), callback)
}

export const setTimer = (seconds: number): Promise<void> => {
  return set(RTTimerRef(), {
    seconds,
  })
}