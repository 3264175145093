import {ServerParamsDoc} from "../Interfaces/ServerParamsDoc";
import {ServerMethods} from "../ServerMethods";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getBaseURL = (
  _serverMethod: ServerMethods,
  _serverParams: ServerParamsDoc,
): string | null => {
  return "https://us-central1-yourplace-4061e.cloudfunctions.net/developmentServer";
};
