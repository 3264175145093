import {isString} from "../is/isString";

export const validateName = (
  text: unknown,
): {scrubbed: string; valid: boolean} => {
  if (isString(text)) {
    const scrubbed = [" ", "@", "."].reduce((acc: string, char) => {
      return acc.replace(char, "");
    }, text.trim());
    return {scrubbed, valid: scrubbed.length > 2};
  }
  return {scrubbed: "", valid: false};
};
