import {ServerParamsDoc} from "../../Interfaces/ServerParamsDoc";
import {oddNumberPathArray} from "./oddNumberPathArray";

export const resolveFBPath = (
  modelPath: string[],
  serverParams: ServerParamsDoc,
): string[] => {
  const {dbMode} = serverParams.operatingModeDoc;
  return oddNumberPathArray([dbMode], modelPath);
};
