const colors = {
  offWhite: "#f0f0f0",
  text: "#343434",
  border: "#E2E2D2",
  blue: "#4f6d7a",
  textLight: "#797979",
  primaryColor: "#3f51b5",
  error: "red",
  headerColor: "#86CEEB",
  headerColor2: "#007AFF",
  redColor: "red",
  headerTintColor: "rgba(1, 130, 200, 1)",
  baseColor: "rgba(1, 130, 200, 1)",
  blurple: '#635BFF',
  blurple_dark: '#5851DF',
  white: '#FFFFFF',
  light_gray: '#F6F9FC',
  dark_gray: '#425466',
  slate: '#0A2540',
};
export default colors;
