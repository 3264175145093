import React, {FC, type PropsWithChildren} from "react";
//import {StripeProvider} from "@stripe/stripe-react-native";
//export const AppStripeProvider = StripeProvider;


interface AppStripeProviderProps extends PropsWithChildren{
    publishableKey?: string
    urlScheme?: string // "your-url-scheme" required for 3D Secure and bank redirects
    merchantIdentifier?: string // "merchant.com.{{YOUR_APP_NAME}}" // required for Apple Pay
}
export const AppStripeProvider: FC<AppStripeProviderProps> = props => {
    const {children} = props;
    return <>{children}</>
}
