import { RNTextStyle, RNViewStyle, styleSheetCreate } from "../../../Mapping/styleSheetCreate";


const styleObj: {
  container: RNViewStyle;
  separator: RNViewStyle;
  title: RNTextStyle;
} = {
  container: {
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  title: {
    marginLeft: 10,
    marginTop: 7,
    marginBottom: 2,
  },
};

const styles = styleSheetCreate(styleObj);
export const useStyles = () => styles;
