import { Card } from "@rneui/themed";
import React, { FC, type PropsWithChildren, useState } from "react";
import { CustomText } from "../../Custom/CustomText";
import { useStyles } from "./useStyles";
import { LoginSection } from "./LoginSection";
import { RegisterSection } from "./RegisterSection";
import { ThemedView } from "../../ThemedComponents/ThemedView";
import { KeyboardAvoidingView } from "react-native";

const createAccountTitle = "Create Account";
const loginTitle = "Login";
export const LoginService: FC<PropsWithChildren> = () => {
  const {container, title } = useStyles();
  const [registerMode, setRegisterMode] = useState<boolean>(false);
  return (
    <KeyboardAvoidingView>
      <ThemedView style={container}>
        <CustomText type="h4" style={title}>
          {registerMode ? createAccountTitle : loginTitle}
        </CustomText>
        <Card.Divider />
        {registerMode ? (
          <RegisterSection
            registerMode={registerMode}
            setRegisterMode={setRegisterMode}
          />
        ) : (
          <LoginSection
            registerMode={registerMode}
            setRegisterMode={setRegisterMode}
          />
        )}
      </ThemedView>
    </KeyboardAvoidingView>
  );
};
