export enum ModelTypes {
  BaseModel = "BaseModel",
  Group = "groups",
  Image = "images",
  PrivateProfile = "privateprofile",
  PublicProfile = "publicprofiles",
  Order = "orders",
  Payment = "payments",
  Product = "products",
  AnchorLocation = "anchorlocations",
  Transaction = "transactions",
  Message = "messages",
  GeolocationDoc = "geolocationDoc ",
  District = "districts",
  School = "schools",
  BoardMember = "boardmember",
  Employee = "employees",
  Follow = "follows",
  Invite = "invites",
  JoinRequest = "joinrequests",
  BlockedNumber = "blockedNumbers",
  Customer = "customers",
  District2 = "district2s",
  SearchCenter = "SearchCenter",
  PhoneNumber = "phonenumbers",
  ServerLog = "serverlogs",
  MetaSchedule = "metaSchedules",
  Business = "businesses",
  SelectedModels = "selectedModels",
  Owner = "owners",
  OrderTransaction = "orderTransactions",
  ProcessorPaymentReport = "processorPaymentReport",
  ProcessorId = "processorids",
  TwilioResponse = "twilioResponses",
  IncomingText = "incomingTexts",
  AppRoom = "appRooms",
  AdminMessage = "adminMessages",
  Settings = "settings",
  OrderProduct = "orderproducts",
  Rating = "ratings",
  Provider = "providers",
  Manager = "managers",
  Admin = "admins",
  Worker = "workers",
  SelectedBusiness = "selectedBusinesses",
  BusinessRoom = "businessRooms",
  PrivateRoom = "privateRooms",
  MessageTransaction = "messageTransactions",
  StripeReport = "StripeReports",
  StripePayoutReport = "StripePayoutReports",
  StripeChargeReport = "StripeChargeReports",
  StripePaymentIntentReport = "StripePaymentIntentReports",
  StripeTransferReport = "StripeTransferReports",
  StripeExternalAccount = "StripeExternalAccounts",
  StripeExternalAccountReport = "StripeExternalAccountReports",
  Invoice = "Invoices",
  OrderReport = "OrderReports",
  Album = "albums",
  GroupJoin = "GroupJoin",
  OrderProductJoin = "OrderProductJoin",
  Series = "Series",
  PrivateBusinessInfo = "PrivateBusinessInfo",
  SelectedBusinessPerson = "SelectedBusinessPerson",
  Content = "Content",
  DriversLicense = "DriversLicense",
  RatingTally = "RatingTally",
  ProviderInfo = "ProviderInfo",
  TaxForm = "TaxForm",
  ProviderDistribution = "ProviderDistribution",
  VenmoRequest = "VenmoRequest",
  IndexReport = "IndexReport",
  AxiosReport = "AxiosReport",
  LoginReport = "LoginReport",
  UserStatus = "UserStatus",
  ClientAppErrorReport = "ClientAppErrorReport",
  ClientStripeErrorReport = "ClientStripeErrorReport",
}
