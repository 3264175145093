import { useNavigation } from "@react-navigation/native";
import React, { FC, useLayoutEffect, type PropsWithChildren } from "react";
import { useReactDom } from "../../../../useReactDom";

interface HeaderAreaProps extends PropsWithChildren {
  title: string;
}
export const LoginHeaderArea: FC<HeaderAreaProps> = props => {
  const { title } = props;
  const navigation = useNavigation();
  useLayoutEffect(() => {
    if (!useReactDom()) {
      navigation &&
        navigation.setOptions &&
        navigation.setOptions({
            title: "Login"
        });
    }
  }, [navigation, title]);
  return <></>;
};
