import React from "react";
import { FBaseModelDoc } from "../../../Common/Interfaces/BaseDocs/FBaseModelDoc";

export interface SearchCenterContextInterface {
    searchCenterDocs: FBaseModelDoc[];
}
const defaultContext = {
    searchCenterDocs: []
};

const SearchCenterContext = React.createContext<SearchCenterContextInterface>({
  ...defaultContext,
});
export default SearchCenterContext;
