/* eslint-disable no-invalid-this */
/* eslint-disable require-jsdoc */
import { FBCancelTokenSource } from "../../../Mapping/axiosMapping";
import {dbActions} from "../../Functions/dbActions";
import {AuthDoc} from "../../Interfaces/AuthDoc";
import {FBaseModelDoc} from "../../Interfaces/BaseDocs/FBaseModelDoc";
import {FDescriptorDoc} from "../../Interfaces/BaseDocs/FDescriptorDoc";
import {GoogleLatLngLiteral} from "../../Interfaces/Location/GoogleLatLngLiteral";
import {LocationTypes} from "../../Interfaces/Location/LocationTypes";
import {FSearchCenterInterface} from "../../Interfaces/Models/FSearchCenterInterface";
import {ModelFields} from "../../Interfaces/Models/ModelFields";
import {ModelTypes} from "../../Interfaces/Models/ModelTypes";
import {ServerParamsDoc} from "../../Interfaces/ServerParamsDoc";
import FBaseModelWithLocation from "./FBaseModelWithLocation";

const modelType = ModelTypes.SearchCenter;

class FSearchCenter
  extends FBaseModelWithLocation
  implements FSearchCenterInterface
{
  static collectionName: ModelTypes = modelType;
  constructor(doc?: FBaseModelDoc) {
    super(doc);
    if (doc === undefined || doc === null) {
      this.modelType = modelType;
      this.modelPath = [modelType];
    }
  }
  get locationName(): string | null {
    return this.getStringValue(ModelFields.locationName) || null;
  }
  set locationName(value: string | null) {
    this.setStringValue(ModelFields.locationName, value);
  }
  get searchRadiusInMiles(): number | null {
    return this.getNumberValue(ModelFields.radiusInMiles) || null;
  }
  set searchRadiusInMiles(miles: number | null) {
    miles && this.setNumberValue(ModelFields.radiusInMiles, miles);
  }
  latLngLiteral = (): GoogleLatLngLiteral | null => {
    const name = this.locationName;
    if (name) {
      const candidate = this.location(name);
      if (candidate) {
        return candidate.latlngLiteral;
      }
    }
    return null;
  };
  get latitude(): number | null {
    const latlng = this.latLngLiteral();
    return latlng ? latlng.lat : null;
  }
  get longitude(): number | null {
    const latlng = this.latLngLiteral();
    return latlng ? latlng.lng : null;
  }
  static async lookupSearchCenter(
    authDoc: AuthDoc | null,
    locationName: LocationTypes,
    owner: FDescriptorDoc | null,
    serverParamsDoc: ServerParamsDoc,
    cancelTokenSource: FBCancelTokenSource,
  ): Promise<FBaseModelDoc[]> {
    return dbActions.lookupSearchCenter(
      authDoc,
      {locationName, owner},
      serverParamsDoc,
      cancelTokenSource,
    );
  }
}
export default FSearchCenter;
