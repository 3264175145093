import {arrayToDotPath} from "../../../Functions/PathGeneration/arrayToDotPath";
import {FBQueryDoc} from "../../../Interfaces/Query/FBQueryDoc";
import {FBWhere} from "../../../Interfaces/Query/FBWhere";
import {FBWhereOperators} from "../../../Interfaces/Query/FBWhereOperators";
import {isNumber} from "../../../is/isNumber";
import {isString} from "../../../is/isString";

const tos = typeof " ";
const tob = typeof true;
export const createAlgoliaFilter = (doc: FBQueryDoc): string => {
  const {wheres} = doc;
  const result = wheres.reduce((acc: string, whereterm: FBWhere) => {
    const {prefice, path, value, operator} = whereterm;
    const stringPath = arrayToDotPath(path);
    if (stringPath !== "") {
      let component = "";
      if (operator === FBWhereOperators.between) {
        if (Array.isArray(value) && value.length === 2) {
          component = `${stringPath}:${value[0]} TO ${value[1]}`;
        } else {
          console.log(
            // eslint-disable-next-line max-len
            "%c--- In createAlgoliaFilter, have between operation but value is no an array or array doenst have two elements",
            whereterm,
          );
        }
      } else if (Array.isArray(value)) {
        let arrayString = "";
        value.forEach((element: string | string[] | number) => {
          if (Array.isArray(element)) {
            return;
          } else if (typeof element === tos) {
            const c = `${stringPath}:${element}`;
            if (arrayString === "") {
              arrayString = c;
              return arrayString;
            } else {
              arrayString = `${arrayString} OR ${c}`;
              return arrayString;
            }
          } else {
            // eslint-disable-next-line max-len
            console.log(
              "In createAlgoliaFilter, element not a string",
              element,
            );
            return arrayString;
          }
        });
        component = `(${arrayString})`;
      } else {
        let term: string | boolean | number = "";
        if (isString(value)) {
          term = value;
        } else if (typeof value === tob) {
          term = value ? true : false;
        } else if (isNumber(value)) {
          term = value;
        } else {
          console.log("In createAlgoliaFilter2 Unhandled term", whereterm);
        }
        /*
        } else if (value instanceof Date) {
          term = Math.floor(value.getTime() / 1000);
        } */
        if (operator === FBWhereOperators.eq) {
          component = `${stringPath}:${term}`;
        } else if (operator === FBWhereOperators.gte) {
          component = `${stringPath} >= ${term}`;
        } else if (operator === FBWhereOperators.gt) {
          component = `${stringPath} > ${term}`;
        } else if (operator === FBWhereOperators.lt) {
          component = `${stringPath} <= ${term}`;
        } else if (operator === FBWhereOperators.lte) {
          component = `${stringPath} < ${term}`;
        } else if (operator === FBWhereOperators.ne) {
          component = `NOT ${stringPath}:${term}`;
          // component = `${stringPath} != ${term}`;
        } else if (operator === FBWhereOperators.arrayContains) {
          component = `${stringPath}: ${value}`;
        } else if (operator === FBWhereOperators.arrayContainsAny) {
          console.log(
            "Unhanded arrayContainsAny Operator",
            operator,
            whereterm,
          );
        } else {
          console.log(
            "%c---- in createAlgoliaFilter2, undhanded operator",
            operator,
            "whereterm is:",
            whereterm,
          );
        }
        if (prefice !== undefined) {
          component = `${prefice} ${component}`;
        }
      }
      if (acc === "") {
        return component;
      }
      return `${acc} AND ${component}`;
    }
    return acc;
  }, "");
  return result;
};
