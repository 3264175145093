/* eslint-disable no-invalid-this */
/* eslint-disable require-jsdoc */
import {FDescriptorDoc} from "../../Interfaces/BaseDocs/FDescriptorDoc";
import {ModelFields} from "../../Interfaces/Models/ModelFields";
import {OrderStatus} from "../../Interfaces/Models/OrderStatus";
import {FBWhere} from "../../Interfaces/Query/FBWhere";
import {FBWhereOperators} from "../../Interfaces/Query/FBWhereOperators";
import {
  AlgoliaSortObject,
  AssignmentMode,
} from "../../Interfaces/Query/QueryParamsDoc";
import {isFDescriptorDoc} from "../../is/isFDescriptorDoc";
import {isNumber} from "../../is/isNumber";
import {isString} from "../../is/isString";

class WhereClass {
  wheres: FBWhere[] = [];
  constructor(wheres?: FBWhere[]) {
    if (Array.isArray(wheres)) {
      this.wheres = wheres;
    }
  }
  mapDescriptorToWheres = (
    path: ModelFields[],
    descriptor: FDescriptorDoc | null | undefined,
  ): WhereClass => {
    if (isFDescriptorDoc(descriptor)) {
      this.wheres.push({
        path,
        operator: FBWhereOperators.eq,
        value: descriptor.fuid,
      });
    }
    return this;
  };
  handleAssignmentMode = (
    assignmentMode?: AssignmentMode | null,
  ): WhereClass => {
    if (assignmentMode === AssignmentMode.unassigned) {
      this.addToWheres(
        [ModelFields.orderStatus],
        FBWhereOperators.eq,
        OrderStatus.requested,
      );
    } else if (assignmentMode === AssignmentMode.assigned) {
      this.addToWheres(
        [ModelFields.orderStatus],
        FBWhereOperators.ne,
        OrderStatus.requested,
      );
    }
    return this;
  };
  handleAlgoliaSortObject = (
    algoliaSortObject?: AlgoliaSortObject,
  ): WhereClass => {
    if (algoliaSortObject) {
      const {path, operator, value, constraint} = algoliaSortObject;
      if (isString(value) || isNumber(value)) {
        const primary = this.addToWheres(path, operator, value);
        if (isNumber(constraint)) {
          const constraintOperator =
            operator !== FBWhereOperators.gt &&
            operator !== FBWhereOperators.gte
              ? FBWhereOperators.gte
              : FBWhereOperators.lte;
          // console.log('Setting constraint to:', path, constraintOperator, new Date(constraint))
          return this.addToWheres(path, constraintOperator, constraint);
        } else {
          return primary;
        }
      }
    }
    return this;
  };
  addToWheres = (
    path: ModelFields[],
    operator: FBWhereOperators,
    value: number | boolean | string | undefined | null | OrderStatus,
  ): WhereClass => {
    if (value !== undefined && value !== null) {
      this.wheres.push({path, operator, value});
    }
    return this;
  };
  addArrayToWheres = (
    path: ModelFields[],
    array: FDescriptorDoc[] | null | undefined,
  ): WhereClass => {
    if (Array.isArray(array)) {
      if (array.length > 0) {
        if (array.length === 1) {
          this.wheres.push({
            path,
            operator: FBWhereOperators.arrayContains,
            value: array[0].fuid,
          });
        } else {
          const fuids = array.map(descriptor => descriptor.fuid);
          this.wheres.push({
            path,
            operator: FBWhereOperators.arrayContainsAny,
            value: fuids,
          });
        }
      }
    }
    return this;
  };
}
export default WhereClass;
