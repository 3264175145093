import { RNViewStyle, styleSheetCreate } from "../../../Mapping/styleSheetCreate";


const styleObj: {
  marginTop: RNViewStyle;
  homeButton: RNViewStyle;
  height100: RNViewStyle;
} = {
  marginTop: {marginTop: 10},
  homeButton: {marginTop: 10, marginBottom: 50},
  height100: {height: "100%"},
};

const styles = styleSheetCreate(styleObj);
export const useStyles = () => styles;
  