import {defaultFieldForOddPath} from "../../../config/defaultFieldForOddPath";
import {newPathMode} from "../../../newPathMode";

export const oddNumberPathArray = (
  array1: string[],
  array2: string[],
): string[] => {
  if (Array.isArray(array1) && array1.length > 0) {
    if (array1[0] !== "production") {
      console.log(
        "In oddNumberPathArray, got something other than production ",
        array1,
      );
    }
  }
  const combined = [...array1, ...array2];
  if (combined.length % 2 === 0) {
    return newPathMode
      ? [...array1, defaultFieldForOddPath, ...array2]
      : [...combined, defaultFieldForOddPath];
  }
  return combined;
};
