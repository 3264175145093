import { useAtom } from "jotai";
import { useCallback, useEffect, useRef, useState } from "react";
import CancelError from "../../Common/ErrorHandling/CancelError";
import { dbActions } from "../../Common/Functions/dbActions";
import { registerUserRequestDoc } from "../../Common/Interfaces/ServerDocs/Auth/registerUserRequestDoc";
import { RegisterUserResponseDocWithError } from "../../Common/Interfaces/ServerDocs/Auth/RegisterUserResponseDocWithError";
import { getCancelTokenSource } from "../../FAxios/getCancelTokenSource";
import { FBCancelTokenSource } from "../../Mapping/axiosMapping";
import { serverParams } from "../JotaiStore";

type StateType = {
  error: Error | null;
  loading: boolean;
  response: RegisterUserResponseDocWithError[];
  success: boolean;
};
const defaultState: StateType = {
  error: null,
  loading: false,
  response: [],
  success: false,
};

export const useRegisterNewUser = () => {
  const tokenRef = useRef<FBCancelTokenSource | null>(null);
  const cancelRequest = useCallback(() => {
    const token = tokenRef.current;
    token && token.cancel();
    tokenRef.current = null;
  }, []);
  useEffect(() => {
    return () => {
      cancelRequest();
    };
  }, [cancelRequest]);
  const [{ authDoc, serverParamsDoc }] = useAtom(serverParams);
  const [{ error, loading, response, success }, setState] = useState<StateType>(
    {
      ...defaultState,
    },
  );
  const resetState = useCallback(() => {
    setState({ ...defaultState });
  }, []);
  const method = useCallback(
    async (requestDoc: registerUserRequestDoc) => {
      cancelRequest();
      const cancelTokenSource = getCancelTokenSource();
      tokenRef.current = cancelTokenSource;
      setState(previous => ({
        ...previous,
        loading: false,
        success: false,
      }));
      try {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const response = await dbActions.registerUser(
          authDoc,
          requestDoc,
          serverParamsDoc,
          cancelTokenSource,
        );
        const ref = tokenRef.current;
        tokenRef.current = null;
        if (ref) {
          setState(previous => ({
            ...previous,
            loading: false,
            success: true,
            response,
          }));
        }
      } catch (incomingError) {
        const ref = tokenRef.current;
        tokenRef.current = null;
        if (ref) {
          if (!(incomingError instanceof CancelError)) {
            setState(previous => ({
              ...previous,
              loading: false,
              success: false,
              error: incomingError as Error,
            }));
          }
        }
      }
    },
    [authDoc, cancelRequest, serverParamsDoc],
  );
  return {
    method,
    loading,
    error,
    success,
    response,
    resetState,
  };
};
