import {isString} from "../is/isString";

export const validateEmail = (
  candidate: unknown,
): {scrubbed: string; valid: boolean} => {
  if (isString(candidate)) {
    const scrubbed = [" ", "/", "#", "!", "^", "%"].reduce(
      (acc: string, char) => {
        return acc.replace(char, "");
      },
      candidate.trim(),
    );
    const valid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(scrubbed);
    return {scrubbed, valid};
  }
  return {scrubbed: "", valid: false};
};
