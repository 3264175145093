import { RNViewStyle, styleSheetCreate } from "../../../Mapping/styleSheetCreate";

const styleObj: {
  column: RNViewStyle;
  row: RNViewStyle;
  justifyCenter: RNViewStyle;
  justifyEnd: RNViewStyle;
  justifyStart: RNViewStyle;
  width100: RNViewStyle;
} = {
  column: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  justifyEnd: {
    justifyContent: "flex-end",
  },
  justifyStart: {
    justifyContent: "flex-start",
  },
  width100: {
    width: "100%",
  },
};
const styles = styleSheetCreate(styleObj);
export const useStyles = () => styles;
  