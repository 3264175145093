import { getDocs, onSnapshot } from "firebase/firestore";
import {resolveFBPath} from "../Common/Functions/PathGeneration/resolveFBPath";
import {FBQueryDoc} from "../Common/Interfaces/Query/FBQueryDoc";
import {ServerParamsDoc} from "../Common/Interfaces/ServerParamsDoc";
import {FBQuerySnapshot} from "../Mapping/firebaseMapping";
import {createQueryRef} from "./createQueryRef";

export const innerGetDocs = (
  modelPath: string[],
  queryDoc: FBQueryDoc,
  serverParams: ServerParamsDoc,
): Promise<FBQuerySnapshot> => {
  return getDocs(createQueryRef(resolveFBPath(modelPath, serverParams), queryDoc));
};

export type unsubscribeFunction = () => void;
export const innerGetDocs2 = (
  modelPath: string[],
  queryDoc: FBQueryDoc,
  serverParams: ServerParamsDoc,
  next: (snapshot: FBQuerySnapshot) => void,
  errorHandler: (error: Error) => void,
  complete: () => void,
): unsubscribeFunction => {
  // export type FBQuerySnapshot = FirebaseFirestoreTypes.QuerySnapshot;
  // FirebaseFirestoreTypes.QuerySnapshot<FirebaseFirestoreTypes.DocumentData>
  return onSnapshot(createQueryRef(
    resolveFBPath(modelPath, serverParams),
    queryDoc,
  ), next, errorHandler, complete);
};
