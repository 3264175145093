import { RNViewStyle, styleSheetCreate } from "../../../../Mapping/styleSheetCreate";

const styleObj: {
  areaCode: RNViewStyle;
  prefix: RNViewStyle;
  suffix: RNViewStyle;
} = {
  areaCode: {width: "34%"},
  prefix: {width: "30%"},
  suffix: {width: "40%"},
};
const styles = styleSheetCreate(styleObj);
export const useStyles = () => styles;
