import { endAt, endBefore, getDocs, limit, limitToLast, onSnapshot, orderBy, query, startAfter, startAt, where } from "firebase/firestore";
import { arrayToDotPath } from "../../Common/Functions/PathGeneration/arrayToDotPath";
import { resolveFBPath } from "../../Common/Functions/PathGeneration/resolveFBPath";
import { FBCursor } from "../../Common/Interfaces/Query/FBCursor";
import { FBQueryDoc } from "../../Common/Interfaces/Query/FBQueryDoc";
import { ServerParamsDoc } from "../../Common/Interfaces/ServerParamsDoc";
import { isNumber } from "../../Common/is/isNumber";
import { createQueryRef } from "../../FBActions/createQueryRef";
import { unsubscribeFunction } from "../../FBActions/innerGetDocs2";
import { FBCollectionReference, FBQuery, FBWhereFilterOp, FBOrderByDirection, FBQuerySnapshot } from "../../Mapping/firebaseMapping";

export const fbWhere = (
  ref: FBCollectionReference | FBQuery,
  path: string[],
  operator: FBWhereFilterOp,
  value: string | number | boolean | string[] | number[] | null,
): FBQuery => {
    return query(ref, where(arrayToDotPath(path), operator as FBWhereFilterOp, value))
  // return ref.where(arrayToDotPath(path), operator as FBWhereFilterOp, value);
};
// FirebaseFirestoreTypes.Query<FirebaseFirestoreTypes.DocumentData>;
//         const {path, order} = obj;

export const fbOrderBy = (
  ref: FBCollectionReference | FBQuery,
  path: string[],
  order: FBOrderByDirection,
): FBQuery => {
    return query(ref, orderBy(arrayToDotPath(path), order))
  // return ref.orderBy(arrayToDotPath(path), operator);
};

export const fbStartAt = (
  ref: FBCollectionReference | FBQuery,
  cursor: FBCursor | null | undefined,
): FBQuery => {
  if (cursor !== undefined && cursor !== null && cursor.startAt) {
    return query(ref, startAt(cursor.startAt))
    // return ref.startAt(cursor.startAt);
  }
  return ref;
};

export const fbStartAfter = (
  ref: FBCollectionReference | FBQuery,
  cursor: FBCursor | null | undefined,
): FBQuery => {
  if (cursor !== undefined && cursor !== null && cursor.startAfter) {
    return query(ref, startAfter(cursor.startAfter))
    // return ref.startAfter(cursor.startAfter);
  }
  return ref;
};

export const fbEndAt = (
  ref: FBCollectionReference | FBQuery,
  cursor: FBCursor | null | undefined,
): FBQuery => {
  if (cursor !== undefined && cursor !== null && cursor.endAt) {
    return query(ref, endAt(cursor.endAt))
    // return ref.endAt(cursor.endAt);
  }
  return ref;
};

export const fbEndBefore = (
  ref: FBCollectionReference | FBQuery,
  cursor: FBCursor | null | undefined,
): FBQuery => {
  if (cursor !== undefined && cursor !== null && cursor.endBefore) {
    return query(ref, endBefore(cursor.endBefore))
    // return ref.endBefore(cursor.endBefore);
  }
  return ref;
};

export const fbLimitToLast = (
  ref: FBCollectionReference | FBQuery,
  limit: number | undefined | null,
): FBQuery => {
  if (isNumber(limit)) {
    return query(ref, limitToLast(limit))
    // return ref.limitToLast(limit);
  }
  return ref;
};

export const fbLimit = (
  ref: FBCollectionReference | FBQuery,
  limitAmount: number | undefined | null,
): FBQuery => {
  if (isNumber(limitAmount)) {
    return query(ref, limit(limitAmount))
    // return ref.limit(limit);
  }
  return ref;
};

export const fbGetDocs = async (
  modelPath: string[],
  queryDoc: FBQueryDoc,
  serverParams: ServerParamsDoc,
): Promise<FBQuerySnapshot> => {
  return getDocs(createQueryRef(resolveFBPath(modelPath, serverParams), queryDoc));
};

export const fbOnSnapshot = (
  modelPath: string[],
  queryDoc: FBQueryDoc,
  serverParamsDoc: ServerParamsDoc,
  next: (snapshot: FBQuerySnapshot) => void,
  errorHandler: (error: Error) => void,
  complete: () => void,
): unsubscribeFunction => {
  return onSnapshot(createQueryRef(
    resolveFBPath(modelPath, serverParamsDoc),
    queryDoc,
  ), next, errorHandler, complete);
};
