import colors from "../../../config/UI/colors";
import { RNTextStyle, styleSheetCreate } from "../../../Mapping/styleSheetCreate";


const primary = colors.baseColor;
const secondary = "white";
const error = "red";
export const textColors = {
  standard: {
    primary,
    secondary,
    tertiary: "green",
    error,
  },
  inverted: {
    primary: secondary,
    secondary: primary,
    tertiary: "green",
    error,
  },
};
const styleObj: {
  h1Style: RNTextStyle;
  h2Style: RNTextStyle;
  h3Style: RNTextStyle;
  primaryStandard: RNTextStyle;
  primaryInverted: RNTextStyle;
  size16: RNTextStyle;
  size18: RNTextStyle;
  size20: RNTextStyle;
  size22: RNTextStyle;
} = {
  h1Style: {
    // fontFamily: "Nunito-Regular",
    fontWeight: "500",
  },
  h2Style: {
    // fontFamily: "Nunito-Regular",
    fontWeight: "400",
  },
  h3Style: {
    // fontFamily: "Nunito-Regular",
    fontWeight: "400",
  },
  primaryStandard: { color: textColors.standard.primary },
  primaryInverted: { color: textColors.inverted.primary },
  size16: { fontSize: 16 },
  size18: { fontSize: 18 },
  size20: { fontSize: 20 },
  size22: { fontSize: 22 },
};
const styles = styleSheetCreate(styleObj);
export const useStyles = () => styles;