import { GeoPoint, OrderByDirection,
  Timestamp, WhereFilterOp,
  getFirestore, QueryDocumentSnapshot, DocumentData, DocumentChange, DocumentSnapshot, CollectionReference, Query, QuerySnapshot, DocumentReference } from 'firebase/firestore'
import {getDatabase, OnDisconnect} from 'firebase/database';
import {User, getAuth,
  signInWithPhoneNumber  as siwpn,
  sendPasswordResetEmail as spre,
  signInWithEmailAndPassword as siweandp,
  signInAnonymously as sia,
  ConfirmationResult,
  signOut as so,
  createUserWithEmailAndPassword as cuweandp,
  ApplicationVerifier,
  ActionCodeSettings
} from 'firebase/auth';
import {DatabaseReference, DataSnapshot} from "firebase/database";
export type RTDataSnapshot = DataSnapshot;
export const realtimedb = getDatabase;
export type { UserCredential } from 'firebase/auth';
export type { ConfirmationResult } from 'firebase/auth';
export class FBTimestamp extends Timestamp {
  fake?: string
}

export type RTDBDisconnect = OnDisconnect;
export type RTDBReference = DatabaseReference;
export const appFirestore = getFirestore;
export type FBAuthUser = User;
export type FBQueryDocumentSnapshot = QueryDocumentSnapshot<DocumentData>
export type FBDocumentChange = DocumentChange
export type FBDocumentData = DocumentData;
export type FBDocumentSnapshot = DocumentSnapshot<DocumentData>
export type FBCollectionReference = CollectionReference<DocumentData>
export type FBQuery = Query<DocumentData>
export type FBWhereFilterOp = WhereFilterOp
export type FBOrderByDirection = OrderByDirection
export type FBQuerySnapshot = QuerySnapshot<DocumentData>
export type FBDocumentReference = DocumentReference<DocumentData>
export class FSGeoPoint extends GeoPoint {
  fake?: string
}
// https://firebase.google.com/docs/reference/js/auth.auth
export const firebaseAuth = () => ({
  onAuthStateChanged: (listener: (user: FBAuthUser | null) => void) => {
      return getAuth().onAuthStateChanged(listener);
  },
  currentUser: getAuth().currentUser,
  signInAnonymously: () => {
      return sia(getAuth());
  },
  signInWithEmailAndPassword: 
    async (email: string, password: string) => {
      return siweandp(getAuth(), email, password)
  },
  sendPasswordResetEmail:
      async(email: string, actionCodeSettings?: ActionCodeSettings | undefined): Promise<void> => {
          return spre(getAuth(), email, actionCodeSettings);
      },
  
  signInWithPhoneNumber:
      async(phoneNumber: string, verifier: ApplicationVerifier): Promise<ConfirmationResult> => {
      // 'recaptcha-container' is the ID of an element in the DOM.
       // const applicationVerifier = new RecaptchaVerifier('recaptcha-container');
      // https://firebase.google.com/docs/reference/js/auth.md#signinwithphonenumber       
          return siwpn(getAuth(), phoneNumber, verifier);
      },
  signOut:
     async(): Promise<void> => {
      return so(getAuth());
     },
  createUserWithEmailAndPassword:
    async(email: string, password: string) => {
      return cuweandp(getAuth(), email, password);
    },
});
