export enum FBWhereOperators {
    lt = "<",
    lte = "<=",
    gt = ">",
    gte = ">=",
    eq = "==",
    in = "in",
    notIn = "not-in",
    ne = "!=",
    arrayContains = "array-contains",
    arrayContainsAny = "array-contains-any",
    between = "between",
}
// <', '<=', '==', '>=', '>', 'array-contains', 'in', and 'array-contains-any'.
