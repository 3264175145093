import { FC, type PropsWithChildren } from "react";
import { ThemeProvider, createTheme, ThemeContext } from "@rneui/themed";
import { SafeAreaProvider as SafeAreaContextProvider } from 'react-native-safe-area-context';
import useCachedResources from "../../hooks/useCachedResources";

const theme = createTheme({
    lightColors: {},
    darkColors: {},
  });
  
export const AppThemeProvider: FC<PropsWithChildren> = props => {
    const isLoadingComplete = useCachedResources();
    const { children } = props;
    if (!isLoadingComplete ) {
        return null;
    }
    return (
        <ThemeProvider theme={theme}>
            <SafeAreaContextProvider>
                {children}
            </SafeAreaContextProvider>
        </ThemeProvider>
    );
}
